import React from 'react';

export const FocusedTabContext = React.createContext<{
  value: string | null;
  updateValue: (key: string | null) => void;
}>({
  value: null,
  updateValue: () => {
    return;
  },
});

export const OpenStateContext = React.createContext(false);
