import { ActionCreator } from 'redux';

export const SET_LOADING_OVERLAY = 'SET_LOADING_OVERLAY';

interface ISetLoadingOverlay {
  type: typeof SET_LOADING_OVERLAY;
  payload: { show: boolean };
}

export const setLoadingOverlay: ActionCreator<ISetLoadingOverlay> = (show: boolean) => ({
  type: SET_LOADING_OVERLAY,
  payload: { show },
});

export type ILoadingOverlayActions = ISetLoadingOverlay;
