import React, { useRef, useState } from 'react';
import { uuid as uuidv4 } from 'utils/uuid';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import styled from 'styled-components';
import classNames from 'classnames';
import { TextLink } from '../TextLink/TextLink';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

const Wrapper = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export type InputLink = { text: string; to: string; href?: never } | { text: string; to?: never; href: string };

interface IOwnProps {
  value?: string;
  onInputChange?: (value: string) => void;
  unpaddedPrefix?: React.ReactNode;
  prefix?: React.ReactNode;
  unpaddedSuffix?: React.ReactNode;
  suffix?: React.ReactNode;
  readOnly?: boolean;
  disabled?: boolean;
  textAlign?: 'left' | 'right';
  inputRef?: React.RefObject<HTMLInputElement>;
  label?: string;
  type?: string;
  helperText?: string;
  valid?: boolean;
  showValidityIcon?: boolean;
  errorMessage?: string;
  forceErrorShown?: boolean;
  matchPattern?: string;
  maxLength?: number;
  link?: InputLink;
  isLoading?: boolean;
  inputClassName?: string;
}

export type InputProps = Omit<React.HTMLAttributes<HTMLInputElement>, 'prefix'> & IOwnProps;

export const Input: React.FC<InputProps> = ({
  unpaddedPrefix = null,
  prefix = null,
  unpaddedSuffix = null,
  suffix = null,
  value = '',
  onInputChange,
  className,
  textAlign = 'left',
  readOnly,
  disabled,
  inputRef,
  label,
  helperText,
  valid,
  showValidityIcon = true,
  isLoading = false,
  errorMessage,
  forceErrorShown,
  onBlur,
  matchPattern = '.*',
  link,
  inputClassName,
  ...otherProps
}) => {
  const id = useRef(uuidv4());

  const [isDirty, setIsDirty] = useState(false);

  const showError = (forceErrorShown || isDirty) && !valid && valid !== undefined && !isLoading;
  const showLoading = !showError && isLoading && !valid && valid !== undefined;

  return (
    <Wrapper className="group flex flex-col space-y-2 w-full">
      {label && (
        <label
          className={classNames('label-large', {
            'text-gray-40': disabled,
            'text-gray-80': !disabled,
          })}
          htmlFor={id.current}
        >
          {label}
        </label>
      )}
      <div
        className={`flex items-center py-3 px-4 w-full rounded-lg focus:outline-none body-small md:body-regular border ring-0 ${`${
          disabled || readOnly
            ? 'bg-gray-10 border-gray-30'
            : `${
                showError ? 'border-error' : 'border-gray-30 hover:border-gray-80'
              } bg-white group-focus-within:border-primary-60 group-focus-within:ring-1 ring-primary-60`
        }`} ${className}`}
      >
        {unpaddedPrefix && (
          <div className="flex -ml-4 mr-4 -my-3">
            <div
              className={classNames('flex flex-grow-0 items-center label-large', {
                'text-gray-40': disabled,
                'text-gray-70': !disabled,
              })}
            >
              {unpaddedPrefix}
            </div>
          </div>
        )}
        {prefix && (
          <div className="flex mr-4">
            <div
              className={classNames('flex flex-grow-0 items-center label-large whitespace-nowrap', {
                'text-gray-40': disabled,
                'text-gray-70': !disabled,
              })}
            >
              {prefix}
            </div>
            <div className="flex-shrink-0 bg-gray-30 w-px ml-4 -my-3" />
          </div>
        )}
        <input
          {...otherProps}
          className={classNames(
            `placeholder-gray-60 disabled:placeholder-gray-40 w-full text-gray-80 disabled:text-gray-40 bg-transparent focus:outline-none overflow-ellipsis`,
            {
              'text-left': textAlign === 'left',
              'text-right': textAlign !== 'left',
              placeholder: value === '',
              'sub-heading': value !== '',
              [inputClassName ?? '']: inputClassName,
            },
          )}
          readOnly={readOnly}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            const { value } = e.target;
            // nosemgrep: eslint.detect-non-literal-regexp
            if (!value.match(RegExp(`^${matchPattern}$`))) {
              return;
            }

            onInputChange?.(value);
          }}
          disabled={disabled}
          ref={inputRef}
          id={id.current}
          onBlur={(e) => {
            setIsDirty(true);
            onBlur && onBlur(e);
          }}
        />
        {unpaddedSuffix && (
          <div className="flex ml-4 -mr-4 -my-3">
            <div
              className={classNames('flex flex-grow-0 items-center label-large whitespace-nowrap', {
                'text-gray-40': disabled,
                'text-gray-70': !disabled,
              })}
            >
              {unpaddedSuffix}
            </div>
          </div>
        )}
        {suffix && (
          <div className="flex ml-4">
            <div className="flex-shrink-0 bg-gray-30 w-px mr-4 -my-3" />
            <div
              className={classNames('flex flex-grow-0 items-center label-large whitespace-nowrap', {
                'text-gray-40': disabled,
                'text-gray-70': !disabled,
              })}
            >
              {suffix}
            </div>
          </div>
        )}
        {showValidityIcon && valid && <CheckCircleIcon className="text-success flex-shrink-0 w-5 h-5" />}
        {showValidityIcon && showError && <ExclamationCircleIcon className="text-error flex-shrink-0 w-5 h-5" />}
        {showValidityIcon && showLoading && (
          <div className="flex-shrink-0 w-5 h-5">
            {' '}
            <LoadingSpinner className="text-error flex-shrink-0 w-5 h-5" size="small" />{' '}
          </div>
        )}
      </div>
      {showError && errorMessage ? (
        <div className="text-error captions">{errorMessage}</div>
      ) : (
        helperText && (
          <div
            className={classNames('captions', {
              'text-gray-40': disabled,
              'text-gray-60': !disabled,
            })}
          >
            {helperText}
          </div>
        )
      )}
      {link && (
        <TextLink className="w-auto self-start pt-1" to={link.to} href={link.href}>
          {link.text}
        </TextLink>
      )}
    </Wrapper>
  );
};

Input.displayName = 'Input';
