/* istanbul ignore file */

import { AxiosResponse } from 'axios';
import { axiosInstance as ax } from './axiosInstance';

interface CompanyResponse {
  uuid: string;
  uen: string;
  name: string;
}

export async function fetchCompanyByUEN(uen: string): Promise<CompanyResponse> {
  const response: AxiosResponse<CompanyResponse> = await ax.get('/company', {
    params: { uen },
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(`Unexpected response code: ${response.status}`);
}
