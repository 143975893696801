import React, { useRef, useState } from 'react';
import { uuid as uuidv4 } from 'utils/uuid';

interface IOwnProps {
  value?: string;
  onInputChange?: (value: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
  textAreaRef?: React.RefObject<HTMLTextAreaElement>;
  label?: string;
  helperText?: string;
  showCharCount?: boolean;
  charLimit?: number;
  valid?: boolean;
  errorMessage?: string;
  forceErrorShown?: boolean;
  rows?: number;
}

export type TextAreaProps = React.HTMLAttributes<HTMLTextAreaElement> & IOwnProps;

export const TextArea: React.FC<TextAreaProps> = ({
  value = '',
  onBlur,
  onInputChange,
  className,
  readOnly,
  disabled,
  textAreaRef,
  label,
  helperText,
  showCharCount,
  charLimit,
  valid,
  errorMessage,
  forceErrorShown,
  rows = 3,
  ...otherProps
}) => {
  const id = useRef(uuidv4());

  const [isDirty, setIsDirty] = useState(false);

  const showError = (forceErrorShown || isDirty) && !valid && valid !== undefined;

  return (
    <div className="flex flex-col space-y-2 w-full">
      <div className="w-full flex justify-between">
        {label && (
          <label className={`${disabled ? 'text-gray-40' : 'text-gray-80'} label-large`} htmlFor={id.current}>
            {label}
          </label>
        )}
        {showCharCount && (
          <div className={`${disabled ? 'text-gray-40' : 'text-gray-60'} captions`}>
            {value.length}
            {charLimit && `/${charLimit}`}
          </div>
        )}
      </div>
      <div
        className={`py-3 px-4 w-full rounded focus:outline-none body-small md:body-regular border ring-0 ${`${
          disabled || readOnly
            ? 'bg-gray-10 border-gray-30'
            : `${
                showError ? 'border-error' : 'border-gray-30 hover:border-gray-80'
              } bg-white focus-within:border-primary-60 focus-within:ring-1 ring-primary-60`
        }`} ${className}`}
      >
        <textarea
          {...otherProps}
          className={`resize-none placeholder-gray-60 disabled:placeholder-gray-40 w-full text-gray-80 disabled:text-gray-40 bg-transparent focus:outline-none ${
            value === '' ? 'placeholder' : 'sub-heading'
          }`}
          readOnly={readOnly}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onInputChange
              ? onInputChange(e.target.value)
              : (e: React.SyntheticEvent) => {
                  e.preventDefault();
                }
          }
          disabled={disabled}
          ref={textAreaRef}
          id={id.current}
          rows={rows}
          onBlur={(e) => {
            setIsDirty(true);
            onBlur && onBlur(e);
          }}
        />
      </div>
      {showError && errorMessage ? (
        <div className="text-error captions">{errorMessage}</div>
      ) : (
        helperText && <div className={`${disabled ? 'text-gray-40' : 'text-gray-60'} captions`}>{helperText}</div>
      )}
    </div>
  );
};

TextArea.displayName = 'TextArea';
