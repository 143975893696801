import React from 'react';
import classNames from 'classnames';

type Layout = 'horizontal' | 'vertical';

interface IItem<T> {
  key: T;
  title: string;
  description?: string;
  image?: React.ReactNode;
  disabled?: boolean;
  valid?: boolean;
}

interface ICardTile<T> {
  item: IItem<T>;
  selectedItem: T | null;
  setSelectedItem: (key: T) => void;
  layout?: Layout;
}

export const CardTile = <T,>({
  item,
  selectedItem,
  setSelectedItem,
  layout = 'horizontal',
  ...otherProps
}: ICardTile<T>) => {
  const { key, title, description, disabled, valid, image } = item;
  const selected = selectedItem === key;
  const isErrored = !valid && valid !== undefined;
  const handleChange = () => {
    setSelectedItem(key);
  };

  return (
    <label
      className={classNames('focus:outline-none flex items-start p-4 shadow-sm rounded-lg border bg-white', {
        'border-primary-60': selected && !isErrored,
        'border-error': selected && isErrored,
        'border-transparent': !selected,
        'hover:border-gray-80 focus-within:border-primary-60 focus-within:ring-2 ring-primary-60 cursor-pointer':
          !disabled,
        'cursor-not-allowed': disabled,
        'space-x-3 justify-between': layout === 'horizontal',
        relative: layout === 'vertical',
        'text-gray-30': disabled,
      })}
    >
      <div
        className={classNames('flex', {
          'flex-row space-x-4 items-center': layout === 'horizontal',
          'flex-col space-y-4': layout === 'vertical',
        })}
      >
        {image && <div className="flex-shrink-0">{image}</div>}
        <div className="flex-grow space-y-1">
          <div
            className={classNames({
              'label-large text-gray-80': description === undefined && !disabled,
              'sub-heading text-gray-90': description !== undefined && !disabled,
            })}
          >
            {title}
          </div>
          {description && (
            <div
              className={classNames('label-large', {
                'text-gray-60': !disabled,
              })}
            >
              {description}
            </div>
          )}
        </div>
      </div>
      <div className={classNames({ 'absolute right-4 top-4': layout === 'vertical' })}>
        <input
          onChange={handleChange}
          type={'checkbox'}
          checked={selected}
          disabled={disabled}
          className={classNames(
            `appearance-none cursor-pointer focus:outline-none relative button-regular text-white leading-0 border w-5 h-5 rounded checked:after:absolute checked:after:transform checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:top-1/2 checked:after:left-1/2 content-checked-after`,
            {
              'bg-primary-60 border-primary-60 hover:bg-primary-50 hover:border-primary-50': selected,
            },
          )}
          tw-content-checked-after={'✓'}
          {...otherProps}
        />
      </div>
    </label>
  );
};
