import React from 'react';
import { ITableCell } from './interfaces';

export const TableCell: React.FC<ITableCell & React.HTMLAttributes<HTMLTableDataCellElement>> = ({
  children,
  className,
  textAlign = 'right',
  ...otherProps
}) => {
  const textAlignClass = textAlign === 'left' ? 'sm:last:text-left' : 'sm:last:text-right';
  return (
    <td
      className={`pt-0.5 pb-3 px-5 bg-transparent align-top sm:group-hover:bg-[#1114170A] sm:p-5 ${textAlignClass} ${className}`}
      {...otherProps}
    >
      {children}
    </td>
  );
};
