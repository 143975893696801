import classNames from 'classnames';
import React from 'react';
import { XOutline } from '../../icons';

export interface ISheet {
  mounted: boolean;
  unmountSheet: () => void;
  footer?: JSX.Element;
}

export const Sheet: React.FC<ISheet> = ({ mounted, unmountSheet, children, footer }) => (
  <div
    className={classNames(
      {
        'bg-gray-100/40': mounted,
        'bg-transparent pointer-events-none': !mounted,
      },
      'transition duration-500 ease-in-out fixed top-0 left-0 w-screen h-screen flex justify-end z-50',
    )}
  >
    <div
      className={classNames(
        {
          'translate-y-0 sm:translate-x-0': mounted,
          'translate-y-full sm:translate-y-0 sm:translate-x-full': !mounted,
        },
        'transform transition duration-500 ease-in-out h-full w-[512px] bg-white flex flex-col mt-24 sm:mt-0',
      )}
    >
      <div
        className={classNames(`p-12 flex flex-col overflow-y-auto`, {
          'pb-2': footer,
        })}
      >
        <button
          type="button"
          onClick={unmountSheet}
          className="ml-auto outline-none focus:text-gray-90 hover:text-gray-90 text-gray-70"
          data-testid="close-sheet"
        >
          <XOutline className="flex-shrink-0 w-6 h-6" />
        </button>
        <div className="overflow-y-auto h-full overscroll-none">{children}</div>
      </div>
      {footer}
    </div>
  </div>
);
