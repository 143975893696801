/* istanbul ignore file */

import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { PageRouter } from './PageRouter/PageRouter';
import { AuthProvider } from './AuthProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { InternalLinkContext } from 'ui';
import { queryClient } from './apis/client';

export const App: React.FC = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <InternalLinkContext.Provider value={{ Link }}>
          <PageRouter />
        </InternalLinkContext.Provider>
      </AuthProvider>
    </QueryClientProvider>
  </Provider>
);
