import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import React from 'react';

interface IDatum {
  heading: string;
  description?: React.ReactNode;
  completed: boolean;
}

export interface IFeed {
  data: IDatum[];
}

export const Feed: React.FC<IFeed> = ({ data }) => {
  return (
    <div className="flex flex-col space-y-0.5">
      {data.map(({ heading, description, completed }, i) => (
        <div
          className={classNames(
            {
              'text-gray-40': !completed,
            },
            'flex space-x-3',
          )}
          key={i}
        >
          <div className="flex flex-col space-y-0.5 items-center">
            <CheckCircleIcon
              className={classNames(
                {
                  'text-primary-60': completed,
                },
                'w-5 h-5 flex-shrink-0',
              )}
            />
            {i !== data.length - 1 && <div className="w-px h-full bg-gray-30/60" />}
          </div>
          <div className="flex flex-col space-y-1 pb-4">
            <div
              className={classNames(
                {
                  'text-gray-90': completed,
                },
                'sub-heading',
              )}
            >
              {heading}
            </div>
            {description && (
              <div
                className={classNames(
                  {
                    'text-gray-70': completed,
                  },
                  'captions',
                )}
              >
                {description}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
