import React, { useState } from 'react';
import { ChevronUpIcon } from '@heroicons/react/outline';
import { Tag } from '../Tag/Tag';

type IItemVariant = 'default' | 'bulleted' | 'full-width';

export interface IItemListDefault {
  header: React.ReactNode;
  items: {
    label?: string;
    itemVariant?: IItemVariant;
    data: React.ReactNode;
    updated?: boolean;
  }[];
  updated?: boolean;
  openByDefault?: boolean;
}

export const ItemListDefault: React.FC<IItemListDefault> = ({ header, items, updated, openByDefault = true }) => {
  const [isOpen, setIsOpen] = useState<boolean>(openByDefault);

  return (
    <div data-cy="item-list_sub-group-table" className="grid grid-cols-8 divide-gray-20/60 gap-x-6">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer py-4 px-5 bg-gray-100/[.02] flex justify-between items-center col-span-8"
      >
        {header && (
          <div className="text-gray-90 sub-heading text-left">
            {header}
            {updated && (
              <div className="mt-2">
                <Tag text="Updated" />
              </div>
            )}
          </div>
        )}
        <div className={`transition-all transform ${isOpen ? 'rotate-0' : '-rotate-180'}`}>
          <ChevronUpIcon className="flex-shrink-0 w-5 h-5" />
        </div>
      </button>
      <hr className="col-span-8" />

      {isOpen &&
        items.map(({ label, data, itemVariant, updated }, i) => {
          if (itemVariant === 'full-width') {
            return (
              <React.Fragment key={i}>
                <div className={`pl-5 py-4 text-gray-90 sub-heading text-left col-span-full`}>{label}</div>
                {i !== items.length - 1 && <hr className="col-span-8" />}
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={i}>
              <div className={`pl-5 py-4 col-span-3 label-large text-gray-60`}>{label}</div>
              <div className="body-small col-span-5 text-gray-90 py-4 pr-5 break-words">
                {itemVariant === 'bulleted' ? (
                  <ul className="list-disc list-inside">
                    {React.Children.map(data, (dataChild, index) => {
                      return <li key={index}>{dataChild}</li>;
                    })}
                  </ul>
                ) : (
                  data
                )}
                {updated && (
                  <div className="mt-2">
                    <Tag text="Updated" />
                  </div>
                )}
              </div>
              {i !== items.length - 1 && <hr className="col-span-8" />}
            </React.Fragment>
          );
        })}
    </div>
  );
};
