import React from 'react';
import { ITableCell } from './interfaces';

export const TableFooter: React.FC<ITableCell & React.HTMLAttributes<HTMLTableCellElement>> = ({
  children,
  ...otherProps
}) => {
  return (
    <td className="p-0" {...otherProps}>
      {children}
    </td>
  );
};
