export function isExternalLink(props: ExternalLinkProps | InternalLinkProps): props is ExternalLinkProps {
  return (props as ExternalLinkProps).external;
}

export interface ExternalLinkProps {
  external: true;
  href: string;
}

export interface InternalLinkProps {
  external?: false;
  path: string;
}

export type LinkProps = ExternalLinkProps | InternalLinkProps;
