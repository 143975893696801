import React, { useMemo } from 'react';
import moment from 'moment';
import { IDateRange } from './types';
import { CalendarDay } from './CalendarDay';

interface ICalendar {
  month: number;
  year: number;
  selection: IDateRange;
  limit: IDateRange;
  validDates?: Date[];
  setDate: (date: Date) => void;
  hideOverflowDates?: {
    previous?: boolean;
    next?: boolean;
  };
}

const weekdayNames = ['Mo', 'Tu', 'We', 'Th', 'Fri', 'Sa', 'Su'];

export const Calendar: React.FC<ICalendar> = ({
  month,
  year,
  selection,
  limit,
  validDates,
  setDate,
  hideOverflowDates = {},
}) => {
  const daysToRender = useMemo(
    () => getDaysToRender({ month, year, selection, limit, validDates, hideOverflowDates }),
    [month, year, selection, validDates],
  );

  return (
    <div className="grid grid-cols-7 px-3 md:px-5 py-2 bg-white w-max rounded-lg">
      {weekdayNames.map((dayName) => (
        <div key={dayName} className="flex items-center justify-center w-8 py-2.5 px-1 text-gray-80 sub-heading">
          {dayName}
        </div>
      ))}
      {daysToRender.map((weekToRender, i) => (
        <React.Fragment key={i}>
          {weekToRender.map((day, j) => {
            if (day === null) {
              return <div key={j} />;
            }

            const { date, selectionStart, selectionEnd, inSelectionRange, withinCurrentMonth, disabled } = day;

            return (
              <CalendarDay
                key={j}
                disabled={disabled}
                date={date}
                setDate={setDate}
                selectionStart={selectionStart}
                selectionEnd={selectionEnd}
                inSelectionRange={inSelectionRange}
                withinCurrentMonth={withinCurrentMonth}
              />
            );
          })}
        </React.Fragment>
      ))}
    </div>
  );
};

interface ICalendarDay {
  date: Date;
  selectionStart: boolean | null;
  selectionEnd: boolean | null;
  inSelectionRange: boolean | null;
  withinCurrentMonth: boolean;
  disabled: boolean;
}

interface IRendererProps {
  month: number;
  year: number;
  selection: IDateRange;
  limit: IDateRange;
  validDates?: Date[];
  hideOverflowDates?: {
    previous?: boolean;
    next?: boolean;
  };
}

export const getDaysToRender = ({
  month,
  year,
  selection,
  limit,
  validDates,
  hideOverflowDates,
}: IRendererProps): (ICalendarDay | null)[][] => {
  const currentMonth = moment(`${year}-${(month + 1).toString().padStart(2, '0')}`, 'YYYY-MM');
  const numDays = currentMonth.daysInMonth();

  const firstDayOfMonth = currentMonth.startOf('month').weekday() || 7;

  const numWeeksToSpan = Math.ceil((numDays + firstDayOfMonth - 1) / 7);

  const daysToRender = new Array(numWeeksToSpan).fill(null).map((_, i) =>
    new Array(7).fill(null).map((_, j) => {
      const date = new Date(year, month, i * 7 + j - firstDayOfMonth + 2);

      if (hideOverflowDates?.previous && moment(date).isBefore(currentMonth.startOf('month'))) {
        return null;
      }

      if (hideOverflowDates?.next && moment(date).isAfter(currentMonth.endOf('month'))) {
        return null;
      }

      return {
        date,
        withinCurrentMonth: moment(date).startOf('month').isSame(currentMonth.startOf('month')),
        selectionStart: Boolean(selection.startDate && moment(date).isSame(moment(selection.startDate))),
        selectionEnd: Boolean(selection.endDate && moment(date).isSame(moment(selection.endDate))),
        inSelectionRange: Boolean(
          selection.startDate &&
            selection.endDate &&
            moment(date).isAfter(moment(selection.startDate)) &&
            moment(date).isBefore(moment(selection.endDate)),
        ),
        disabled: Boolean(
          (limit.startDate && moment(date).isBefore(moment(limit.startDate))) ||
            (limit.endDate && moment(date).isAfter(moment(limit.endDate))) ||
            (validDates &&
              !validDates.some(
                (validDate) =>
                  moment(date).dayOfYear() === moment(validDate).dayOfYear() &&
                  moment(date).year() === moment(validDate).year(),
              )),
        ),
      };
    }),
  );

  return daysToRender;
};
