import React from 'react';

export interface IRouteContext {
  path: string;
  isSelected: (params: { path: string; exact: boolean }) => boolean;
  redirect: (to: string) => void;
}

export const RouteContext = React.createContext<IRouteContext>({
  path: '',
  isSelected: () => false,
  redirect: () => {
    return;
  },
});
