import { ActionCreator } from 'redux';
import { BROADCAST_MESSAGE_CATEGORY, IBroadcastMessage } from 'utils/constants';

export const ADD_BROADCAST_MESSAGES = 'ADD_BROADCAST_MESSAGES';

interface IAddBroadcastMessages {
  type: typeof ADD_BROADCAST_MESSAGES;
  payload: { broadcastMessages: IBroadcastMessage[] };
}

export const addBroadcastMessages: ActionCreator<IAddBroadcastMessages> = (
  broadcastMessages: (Omit<IBroadcastMessage, 'category'> & { category?: BROADCAST_MESSAGE_CATEGORY })[],
) => ({
  type: ADD_BROADCAST_MESSAGES,
  payload: {
    broadcastMessages: broadcastMessages.map((broadcastMessage) => ({
      ...broadcastMessage,
      category: broadcastMessage.category ?? BROADCAST_MESSAGE_CATEGORY.DEFAULT,
    })),
  },
});

export const SET_BROADCAST_MESSAGE_DISMISSED = 'SET_BROADCAST_MESSAGE_DISMISSED';

interface ISetBroadcastMessageDismissed {
  type: typeof SET_BROADCAST_MESSAGE_DISMISSED;
  payload: { id: string };
}

export const setBroadcastMessageDismissed: ActionCreator<ISetBroadcastMessageDismissed> = (id: string) => ({
  type: SET_BROADCAST_MESSAGE_DISMISSED,
  payload: { id },
});

export type IBroadcastMessagesActions = IAddBroadcastMessages | ISetBroadcastMessageDismissed;
