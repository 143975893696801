import { ActionCreator } from 'redux';
import { IAgencyCorrespondence } from 'utils/constants';

export const SET_RESPONSES = 'SET_RESPONSES';

interface ISetResponses {
  type: typeof SET_RESPONSES;
  payload: { submissionId: string; responses: IAgencyCorrespondence[] };
}

export const setResponses: ActionCreator<ISetResponses> = (
  submissionId: string,
  responses: IAgencyCorrespondence[],
) => ({
  type: SET_RESPONSES,
  payload: { submissionId, responses },
});

export type IAgencyResponseActions = ISetResponses;
