import React from 'react';
import { ExpandableMenu } from './ExpandableMenu';
import { IOverlayMenu, OverlayMenu } from './OverlayMenu';
import { InnerDropdownMenuProps } from './shared';

interface ExpandableVariant {
  variant: 'expandable';
}

interface OverlayVariant {
  variant?: 'overlay';
}

export type DropdownMenuProps = (ExpandableVariant | (OverlayVariant & IOverlayMenu)) & InnerDropdownMenuProps;

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ variant = 'overlay', ...otherProps }) => {
  switch (variant) {
    case 'expandable':
      return <ExpandableMenu {...otherProps} />;
    default:
    case 'overlay':
      return <OverlayMenu {...otherProps} />;
  }
};
