import React from 'react';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/outline';

import type { AccordionHeaderProps } from './interfaces';

const AccordionHeader: React.FC<AccordionHeaderProps> = (props) => {
  const { label, isExpanded, onClick } = props;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') onClick();
  };

  return (
    <div
      data-testid="accordion-header"
      tabIndex={0}
      className={classNames(
        // Flexbox and Layout
        'flex',
        'items-center',
        'justify-between',
        'w-full',

        // Padding
        'pl-5',
        'pr-4',
        'py-5',

        // Interactivity and Accessibility
        'cursor-pointer',
        'select-none',
        // 'focus:outline-none',
        // 'focus:border-2',
        // 'focus:border-primary-60',
        'focus-visible:outline-none',
        'focus-visible:border-2',
        'focus-visible:border-primary-60',

        // Visual Feedback
        'hover:bg-[#1114170A]',
      )}
      onClick={onClick}
      onKeyDown={handleKeyDown}
    >
      <div className="text-gray-90 heading-6-semibold grow break-words mr-2">{label}</div>

      <ChevronDownIcon
        data-testid="accordion-header-icon"
        className={classNames('w-5 h-5 shrink-0 text-gray-70', {
          'rotate-180': isExpanded,
          'rotate-0': !isExpanded,
        })}
      />
    </div>
  );
};

AccordionHeader.displayName = 'AccordionHeader';

export default AccordionHeader;
