import React, { useCallback, useRef, useState } from 'react';
import { Input } from './Input';
import { DropdownOption } from './DropdownOption';
import { MenuContainer } from './MenuContainer';
import { IOption } from './shared';
import { InnerDropdownMenuProps } from '../DropdownMenu';
import { useWithFilter } from './useWithFilter';
import { NoMatch } from './NoMatch';
import { InputLink } from '../Input/Input';

export interface IDropdownInput {
  placeholderText?: string;
  options: IOption[];
  selectedOption?: string;
  setSelectedOption: (value: string) => void;
  label?: string;
  helperText?: string;
  valid?: boolean;
  errorMessage?: string;
  forceErrorShown?: boolean;
  readOnly?: boolean;
  noMatchText?: string;
  link?: InputLink;
}

export const DropdownInput: React.FC<IDropdownInput & InnerDropdownMenuProps> = ({
  placeholderText,
  options,
  selectedOption,
  setSelectedOption,
  label,
  helperText,
  valid,
  errorMessage,
  forceErrorShown,
  disabled,
  readOnly,
  noMatchText = 'No Matches Found',
  ...dropdownProps
}) => {
  const {
    filter,
    setFilter,
    keyToFocus,
    filteredOptions,
    handleClick,
    handleSelect,
    increaseFocusedOption,
    decreaseFocusedOption,
  } = useWithFilter(options, setSelectedOption);
  const [isDirty, setIsDirty] = useState(false);
  const showError = (forceErrorShown || isDirty) && !valid && valid !== undefined;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOptionClick = useCallback(
    (key: string) => {
      handleClick(key);
      setIsDirty(true);
      inputRef.current?.select();
    },
    [handleClick, setIsDirty],
  );

  return (
    <div
      className={`relative text-gray-80 body-small md:body-regular ${!readOnly && 'hover:cursor-pointer'}`}
      data-testid={'dropdown-input'}
    >
      <Input
        {...dropdownProps}
        closeOnChangeKey={selectedOption}
        inputRef={inputRef}
        placeholderText={placeholderText}
        value={options.find(({ key }) => key === selectedOption)?.displayName || ''}
        filter={filter}
        setFilter={setFilter}
        increaseFocusedOption={increaseFocusedOption}
        decreaseFocusedOption={decreaseFocusedOption}
        handleSelect={handleSelect}
        label={label}
        helperText={helperText}
        showError={showError}
        errorMessage={errorMessage}
        disabled={disabled}
        readOnly={readOnly}
        dropdownMenu={
          <MenuContainer>
            {filteredOptions.length > 0 ? (
              filteredOptions.map(({ key, displayName, disabled }) => (
                <DropdownOption
                  selected={key === selectedOption}
                  key={key}
                  optionKey={key}
                  handleClick={handleOptionClick}
                  disabled={disabled}
                  focused={keyToFocus === key}
                >
                  {displayName}
                </DropdownOption>
              ))
            ) : (
              <NoMatch>{noMatchText}</NoMatch>
            )}
          </MenuContainer>
        }
      />
    </div>
  );
};
