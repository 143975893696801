import React from 'react';
import { BaseButton, BaseButtonProps } from './BaseButton';
import { LoadingSpinner, ILoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

interface IButton {
  label: string | React.ReactNode;
  size?: 'icon' | 'medium' | 'large';
  isLoading?: boolean;
  textWrap?: boolean;
}

export type ButtonProps = BaseButtonProps & IButton;

const paddings = {
  icon: 'p-3',
  medium: 'px-4 py-2',
  large: 'px-6 py-3',
};

const spinnerStyles: Record<string, ILoadingSpinner['variant']> = {
  primary: 'button-white',
  secondary: 'button-secondary',
  tertiary: 'button-tertiary',
  danger: 'button-white',
};

export const Button: React.FC<ButtonProps> = ({
  label,
  variant = 'primary',
  size = 'large',
  isLoading = false,
  textWrap = true,
  ...otherProps
}) => (
  <BaseButton
    className={`${paddings[size]} ${textWrap ? 'whitespace-normal' : 'whitespace-nowrap'}`}
    isLoading={isLoading}
    variant={variant}
    {...otherProps}
  >
    <div className="relative">
      <div aria-hidden={isLoading} className={isLoading ? 'invisible' : 'visible'}>
        {label}
      </div>
      {isLoading && (
        <div className="w-4 h-4 absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <LoadingSpinner variant={spinnerStyles[variant]} size="small" />
        </div>
      )}
    </div>
  </BaseButton>
);
