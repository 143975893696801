import React, { useEffect, useRef, useState } from 'react';
import { CheckCircle, ExclamationCircle, UploadOutline } from '../../icons';
import { useDropzone } from 'react-dropzone';
import { IMultiSelectDropdownInput, MultiSelectDropdownInput } from '../DropdownInput/MultiSelectDropdownInput';
import { TextLink } from '../TextLink/TextLink';
import { filesize } from 'filesize';
import classNames from 'classnames';
import { formatDateWithShortMonth } from 'utils/date';
import { DropdownInput } from '../DropdownInput/DropdownInput';
import { useFeatureFlags } from '../../../feature-flags/useFeatureFlags';
import { ATTACHMENT_CHANGE_STATUS } from '../../../utils/constants';
import { ERROR_CODE, isCxErrorCode } from 'pages/ErrorBoundary';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { GenericModalWithErrorCode } from 'components/Modals/GenericModalWithErrorCode';

export interface IFile {
  /** File display name */
  name: string;
  /** Unique identifier in S3 */
  key: string;
  /** Size of file in bytes */
  size: number;
  /** Type of document */
  tags: string[];
  /** Timestamp of upload */
  uploadedAt: Date;
  /** Progress of upload in fraction form */
  uploadProgress: number;
  /** Error message for file before successful upload */
  preUploadErrorMessage?: string | React.ReactNode;
  /** Error message for file after successful upload */
  postUploadErrorMessage?: string | React.ReactNode;
  /** Error message for file tags */
  tagErrorMessage?: string;
  /** Element to display when file is pending */
  filePendingDisplayElement?: React.ReactNode;
  /** Additional Element to display */
  additionalDisplayElement?: React.ReactNode;
  /** Whether file can be deleted or replaced */
  allowedFileActions?: IAllowedFileActions;
  /** Additional */
  additionalFileActionElement?: React.ReactNode;
  /** Change status - to check if it is new file uploaded or from prev subm */
  changeStatus?: string;
}

interface IFileActions {
  delete?: { label: string; onClick: (key: string) => void; disabled?: boolean };
  replace?: { label: string; onClick: (replacedFileKey: string, newFile: File) => void; disabled?: boolean };
  download?: { label: string; onClick: (fileName: string, fileKey: string) => Promise<void> };
  selectTags?: Omit<IMultiSelectDropdownInput, 'options' | 'selectedOptions' | 'setSelectedOptions' | 'disabled'> & {
    setSelectedOptions: (fileIdentifier: string, values: string[]) => void;
  };
}

interface IAllowedFileActions {
  delete?: boolean;
  replace?: boolean;
  download?: boolean;
}

interface IUploadFileType {
  key: string;
  name: string;
  tagLabel: string;
  optional?: boolean;
}

export interface IFileUploader {
  inputId?: string;
  header: string;
  description: string;
  additionalElement?: React.ReactNode;
  uploadFileTypes: IUploadFileType[];
  fileActions: IFileActions;
  dropZonePrompt: React.ReactNode;
  onDrop: (files: File[]) => void;
  uploadedFiles: IFile[];
  uploadSectionError?: string;
  forceErrorShown?: boolean;
  helperText?: string;
  readOnly?: boolean;
  isDocumentTagMultiSelector: boolean;
  handleMenuClose?: () => void;
  handleMenuOpen?: () => void;
  handleToggleDeleteAttachmentModal?: (fileIdentifier: string) => void | undefined;
  canDelete?: boolean | undefined;
}

export const FileUploader: React.FC<IFileUploader> = ({
  inputId,
  header,
  description,
  additionalElement,
  dropZonePrompt,
  onDrop,
  uploadedFiles,
  fileActions,
  uploadFileTypes,
  uploadSectionError,
  forceErrorShown,
  helperText,
  readOnly,
  isDocumentTagMultiSelector = true,
  handleMenuClose,
  handleMenuOpen,
  handleToggleDeleteAttachmentModal,
  canDelete,
}) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const initialFiles = useRef(uploadedFiles);

  const openDeleteAttachmentModal = (fileIdentifier: string) => {
    if (handleToggleDeleteAttachmentModal) handleToggleDeleteAttachmentModal(fileIdentifier);
  };

  return (
    <div className="w-full h-full flex flex-col space-y-6 divide-gray-30/60">
      <div className="flex flex-col space-y-1">
        <div className="heading-5-semibold">{header}</div>
        <div className="text-gray-70">{description}</div>
        {additionalElement}
      </div>

      <div className="flex flex-col space-y-3">
        {uploadFileTypes.map(({ key, name }) => (
          <div className="flex space-x-2" key={key}>
            <CheckCircle
              className={`${
                uploadedFiles.find(({ tags }) => tags.includes(key)) ? 'text-success' : 'text-gray-30'
              } flex-shrink-0 w-5 h-5`}
            />
            <div className="label-large text-gray-100">{name}</div>
          </div>
        ))}
      </div>

      <div className="flex flex-col space-y-2">
        <div
          className={classNames(
            {
              'border-solid border-error': uploadSectionError,
              'border-dashed border-gray-30': !uploadSectionError,
              'text-gray-40': readOnly,
              'text-gray-70 focus-visible:px-[15px] hover:border-solid hover:border-gray-80 focus-visible:border-solid focus-visible:border-primary-60 focus-visible:border-2 hover:cursor-pointer':
                !readOnly,
            },
            'flex justify-center items-center w-full h-32 p-4 rounded-lg border bg-gray-10',
          )}
          {...(readOnly ? {} : getRootProps())}
        >
          <input role="file-upload" {...getInputProps()} id={inputId} readOnly={readOnly} />{' '}
          <div className="flex space-x-2 flex-col items-center">
            {' '}
            <UploadOutline className="flex-shrink-0 w-5 h-5 mb-2" /> {dropZonePrompt}
          </div>
        </div>
        {forceErrorShown && uploadSectionError ? (
          <div className="captions text-error">{uploadSectionError}</div>
        ) : (
          helperText && <div className="captions text-gray-70">{helperText}</div>
        )}
      </div>

      {uploadedFiles.length > 0 && <hr />}

      {uploadedFiles.map(
        (
          {
            key,
            name,
            size,
            tags,
            uploadedAt,
            uploadProgress,
            preUploadErrorMessage,
            postUploadErrorMessage,
            tagErrorMessage,
            filePendingDisplayElement,
            additionalDisplayElement,
            allowedFileActions,
            additionalFileActionElement,
            changeStatus,
          },
          i,
        ) => (
          <>
            <FileInfo
              key={key}
              fileIdentifier={key}
              name={name}
              size={size}
              tags={tags}
              uploadedAt={uploadedAt}
              fileActions={fileActions}
              uploadFileTypes={uploadFileTypes}
              uploadProgress={uploadProgress}
              preUploadErrorMessage={preUploadErrorMessage}
              postUploadErrorMessage={postUploadErrorMessage}
              tagErrorMessage={tagErrorMessage}
              filePendingDisplayElement={filePendingDisplayElement}
              additionalDisplayElement={additionalDisplayElement}
              forceErrorShown={forceErrorShown}
              isNewlyAdded={
                !initialFiles.current.find(
                  (file) => file.key === key && file.uploadedAt.getTime() === uploadedAt.getTime(),
                )
              }
              allowedFileActions={allowedFileActions}
              isDocumentTagMultiSelector={isDocumentTagMultiSelector}
              additionalFileActionElement={additionalFileActionElement}
              handleMenuClose={handleMenuClose}
              handleMenuOpen={handleMenuOpen}
              handleDeleteAttachmentModalOpen={openDeleteAttachmentModal}
              canDelete={canDelete}
              changeStatus={changeStatus}
            />
            {i < uploadedFiles.length - 1 && <hr />}
          </>
        ),
      )}
    </div>
  );
};

interface IFileInfo {
  fileIdentifier: string;
  name: string;
  size: number;
  tags: string[];
  uploadedAt: Date;
  fileActions: IFileActions;
  uploadFileTypes: IUploadFileType[];
  uploadProgress: number;
  preUploadErrorMessage?: string | React.ReactNode;
  postUploadErrorMessage?: string | React.ReactNode;
  tagErrorMessage?: string;
  filePendingDisplayElement?: React.ReactNode;
  additionalDisplayElement?: React.ReactNode;
  forceErrorShown?: boolean;
  isNewlyAdded: boolean;
  allowedFileActions?: IAllowedFileActions;
  isDocumentTagMultiSelector: boolean;
  additionalFileActionElement?: React.ReactNode;
  handleMenuClose?: () => void;
  handleMenuOpen?: () => void;
  handleDeleteAttachmentModalOpen?: (fileIdentifier: string) => void | undefined;
  canDelete?: boolean | undefined;
  changeStatus?: string;
}

export const FileInfo: React.FC<IFileInfo> = ({
  fileIdentifier,
  name,
  size,
  tags,
  uploadedAt,
  fileActions,
  uploadFileTypes,
  uploadProgress,
  preUploadErrorMessage,
  postUploadErrorMessage,
  tagErrorMessage,
  filePendingDisplayElement,
  additionalDisplayElement,
  forceErrorShown,
  isNewlyAdded,
  allowedFileActions,
  isDocumentTagMultiSelector,
  additionalFileActionElement,
  handleMenuClose,
  handleMenuOpen,
  handleDeleteAttachmentModalOpen,
  canDelete,
  changeStatus,
}) => {
  const { t } = useTranslation();
  const { enableDeletionOfNonBimFiles } = useFeatureFlags();
  const [isError, setIsError] = useState(false);
  const [errorCode, setErrorCode] = useState<keyof typeof ERROR_CODE | undefined>();

  const ref = useRef<HTMLDivElement>(null);
  const inputFile = useRef<HTMLInputElement>(null);

  useEffect(() => {
    isNewlyAdded &&
      ref.current?.scrollIntoView({
        behavior: 'smooth',
      });
  }, [isNewlyAdded, ref]);

  return (
    <>
      <GenericModalWithErrorCode
        isOpen={isError}
        modalHeader={t('genericModalWithErrorCode:default.header')}
        modalDescription={t('genericModalWithErrorCode:default.description')}
        toggleOpenOrClose={setIsError}
        errorCode={errorCode ? errorCode : ERROR_CODE.UNKNOWN_ERROR}
      />
      <div ref={ref} className="flex flex-col space-y-6" data-cy={`${name}`}>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-1">
            <div className="flex space-x-2 items-center">
              <div className="heading-6-semibold">{name}</div>
              {preUploadErrorMessage || postUploadErrorMessage ? (
                <ExclamationCircle className="text-error flex-shrink-0 w-5 h-5" />
              ) : (
                uploadProgress === 1 &&
                !filePendingDisplayElement && <CheckCircle className="text-success flex-shrink-0 w-5 h-5" />
              )}
            </div>
            {preUploadErrorMessage ? (
              <div className="text-error captions">{preUploadErrorMessage}</div>
            ) : (
              <div className="gray-60 label-regular">
                {filesize(size)} • {formatDateWithShortMonth(uploadedAt)}
              </div>
            )}
          </div>

          {uploadProgress < 1 && !preUploadErrorMessage && (
            <div className="flex flex-col space-y-1">
              <div className="relative bg-gray-20 h-1 w-32 rounded-full">
                <div
                  style={{
                    width: `${uploadProgress * 128}px`,
                  }}
                  className="absolute top-0 left-0 bg-primary-60 h-1 w-20 rounded-full transition ease-linear duration-200"
                />
              </div>
              <div className="text-gray-60 captions">{Math.floor(uploadProgress * 100)}%</div>
            </div>
          )}

          {filePendingDisplayElement}

          {additionalDisplayElement}
        </div>

        {isDocumentTagMultiSelector ? (
          <MultiSelectDropdownInput
            label={fileActions.selectTags?.label}
            options={uploadFileTypes.map(({ key, name, tagLabel }) => ({ key, displayName: name, tagLabel }))}
            selectedOptions={tags}
            setSelectedOptions={(newValues: string[]) => {
              fileActions.selectTags?.setSelectedOptions(fileIdentifier, newValues);
            }}
            disabled={uploadProgress < 1 || filePendingDisplayElement !== undefined}
            errorMessage={tagErrorMessage}
            forceErrorShown={forceErrorShown}
            valid={!tagErrorMessage}
            onMenuClose={handleMenuClose}
            onMenuOpen={handleMenuOpen}
          />
        ) : (
          <DropdownInput
            label={fileActions.selectTags?.label}
            options={uploadFileTypes.map(({ key, tagLabel }) => ({ key, displayName: tagLabel }))}
            selectedOption={tags.length > 0 ? tags[0] : undefined}
            setSelectedOption={(value) => fileActions.selectTags?.setSelectedOptions(fileIdentifier, [value])}
            disabled={uploadProgress < 1 || filePendingDisplayElement !== undefined}
            errorMessage={tagErrorMessage}
            forceErrorShown={forceErrorShown}
            valid={!tagErrorMessage}
          />
        )}

        {allowedFileActions && (
          <div className="flex space-x-2 items-center">
            {additionalFileActionElement}
            {allowedFileActions.replace && fileActions.replace && (
              <div className="w-max">
                <input
                  type="file"
                  ref={inputFile}
                  role="file-upload"
                  className="hidden"
                  onChange={(e) => e.target.files && fileActions.replace?.onClick(fileIdentifier, e.target.files[0])}
                />
                <TextLink
                  className="hover:text-primary-50 active:text-primary-70 text-primary-60"
                  onClick={() => inputFile.current?.click()}
                  variant="unstyled"
                  disabled={Boolean(fileActions.replace.disabled)}
                >
                  {fileActions.replace.label}
                </TextLink>
              </div>
            )}
            {allowedFileActions.download && fileActions.download && (
              <div className="w-max border-l border-gray-30 pl-2 first:border-none first:pl-0">
                <TextLink
                  className="hover:text-primary-50 active:text-primary-70 text-primary-60"
                  variant="unstyled"
                  onClick={async () => {
                    try {
                      await fileActions.download?.onClick(name, fileIdentifier);
                    } catch (error) {
                      if (
                        isAxiosError(error) &&
                        error.response?.data.errorCode &&
                        isCxErrorCode(error.response?.data.errorCode)
                      ) {
                        setIsError(true);
                        setErrorCode(error.response?.data.errorCode);
                        return;
                      } else {
                        setIsError(true);
                        setErrorCode(ERROR_CODE.UNKNOWN_ERROR);
                      }
                    }
                  }}
                >
                  {fileActions.download.label}
                </TextLink>
              </div>
            )}
            {allowedFileActions.delete && fileActions.delete && (
              <div className="w-max border-l border-gray-30 pl-2 first:border-none first:pl-0">
                <TextLink
                  variant="danger"
                  onClick={
                    enableDeletionOfNonBimFiles && canDelete
                      ? () => {
                          if (handleDeleteAttachmentModalOpen && changeStatus !== ATTACHMENT_CHANGE_STATUS.NEW) {
                            // only open delete attachment modal if file is not newly uploaded
                            handleDeleteAttachmentModalOpen(fileIdentifier);
                          } else {
                            fileActions.delete?.onClick(fileIdentifier);
                          }
                        }
                      : () => fileActions.delete?.onClick(fileIdentifier)
                  }
                  disabled={Boolean(fileActions.delete.disabled)}
                >
                  {fileActions.delete.label}
                </TextLink>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
