export interface IStepsIndicatorProps {
  className?: string;
  steps: string[];
  currentStep: number;
  selectedCallback?: (orderNumber: number) => void;
}

export enum PROGRESS {
  TODO = 'disabled',
  ACTIVE = 'active',
  COMPLETED = 'completed',
}

export const progress = (currentStepIndex: number, index: number): PROGRESS => {
  if (index < currentStepIndex) return PROGRESS.COMPLETED;
  if (index > currentStepIndex) return PROGRESS.TODO;
  return PROGRESS.ACTIVE;
};
