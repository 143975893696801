import React, { useContext } from 'react';
import { DropdownMenu, ToggleArrow, DropdownMenuOpenState } from '../../../../DropdownMenu';
import { DropdownMenuTabProps } from './shared';

const ControlNode: React.FC<DropdownMenuTabProps & React.HTMLAttributes<HTMLButtonElement>> = ({ label, onClick }) => {
  const isOpen = useContext(DropdownMenuOpenState);

  return (
    <button
      className={`w-full focus:outline-none focus:ring-2 ring-primary-60 ring-inset button-regular whitespace-nowrap py-4.5 px-5 ${
        isOpen ? 'border-primary-60' : 'border-transparent'
      } border-l-2`}
      onClick={onClick}
    >
      <div className="flex items-center justify-between space-x-2">
        <span className={`${isOpen ? 'text-primary-60' : 'text-gray-100'}`}>{label}</span>
        <div className={`${isOpen ? 'text-primary-60' : 'text-gray-70'}`}>
          <ToggleArrow />
        </div>
      </div>
    </button>
  );
};

export const ExpandableTab: React.FC<DropdownMenuTabProps & React.HTMLAttributes<HTMLButtonElement>> = ({
  label,
  children,
  ...otherProps
}) => {
  return (
    <DropdownMenu
      variant="expandable"
      renderControlNode={({ toggleMenu }) => <ControlNode label={label} onClick={toggleMenu} />}
      {...otherProps}
    >
      <div className="flex flex-col bg-gray-10">{children}</div>
    </DropdownMenu>
  );
};
