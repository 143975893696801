import React from 'react';

type Size = 'small' | 'medium' | 'large';

export interface IAvatarProps {
  name: string;
  size?: Size;
}

export const getInitials = (name: string): string => {
  const words = name.split(' ');

  return words.length === 2
    ? words.map((word) => word.charAt(0).toUpperCase()).join('')
    : words[0].charAt(0).toUpperCase();
};

const sizeStyles = {
  small: 'sub-heading w-8 h-8',
  medium: 'heading-6-semibold w-10 h-10',
  large: 'heading-5-semibold w-12 h-12',
};

export const Avatar: React.FC<IAvatarProps> = ({ name, size = 'small' }) => (
  <div
    className={`flex justify-center items-center rounded-full bg-gray-60 text-white border border-white ${sizeStyles[size]}`}
  >
    {getInitials(name)}
  </div>
);
