import { ActionCreator } from 'redux';

export const SET_PAYABLE_AGENCY_ABBREVIATION = 'SET_PAYABLE_AGENCY_ABBREVIATION';

interface ISetPayableAgencyAbbreviation {
  type: typeof SET_PAYABLE_AGENCY_ABBREVIATION;
  payload: { payableAgencyAbbreviation: string };
}

export const setPayableAgencyAbbreviation: ActionCreator<ISetPayableAgencyAbbreviation> = (
  payableAgencyAbbreviation: string,
) => ({
  type: SET_PAYABLE_AGENCY_ABBREVIATION,
  payload: { payableAgencyAbbreviation },
});

export type IStripeActions = ISetPayableAgencyAbbreviation;
