import classNames from 'classnames';
import React from 'react';
import { Size } from './interfaces';

export interface ITabButtonHorizontal {
  size: Size;
  label: string;
  isActive?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const styles: Record<
  string,
  {
    textSize: string;
    padding: string;
  }
> = {
  small: {
    textSize: 'sub-heading',
    padding: 'py-3.5',
  },
  medium: {
    textSize: 'heading-6-semibold',
    padding: 'py-4',
  },
  large: {
    textSize: 'heading-5-semibold',
    padding: 'py-3.5',
  },
};

export const TabButtonHorizontal = React.forwardRef<HTMLButtonElement, ITabButtonHorizontal>(
  ({ size, label, isActive = false, disabled = false, onClick }, ref) => {
    const { textSize, padding } = styles[size];

    return (
      <button
        ref={ref}
        className={classNames(
          { 'border-primary-60 text-gray-80': isActive },
          { 'border-transparent text-gray-70 enabled:hover:border-gray-30': !isActive },
          'focus:outline-none focus-visible:border-primary-50 scroll-ml-5 border-b-2 text-gray-80 disabled:cursor-not-allowed disabled:text-gray-30 disabled:border-transparent',
          textSize,
          padding,
        )}
        onClick={onClick}
        disabled={disabled}
        type="button"
      >
        <div className="py-0.5 w-max">{label}</div>
      </button>
    );
  },
);
TabButtonHorizontal.displayName = 'TabButtonHorizontal';
