import { ActionCreator } from 'redux';
import { IAccount, IAssistant } from 'utils/constants';

export const SET_ASSISTANT = 'SET_ASSISTANT';
export const SET_ASSISTANTS = 'SET_ASSISTANTS';
export const SET_ASSISTANT_FOR = 'SET_ASSISTANT_FOR';

interface ISetAssistantFor {
  type: typeof SET_ASSISTANT_FOR;
  payload: {
    assistantFor: IAccount[];
    assistantForProjects: {
      projectId: string;
      assisting: IAccount;
    }[];
  };
}

export const setAssistantFor: ActionCreator<ISetAssistantFor> = (
  assistantFor: IAccount[],
  assistantForProjects: {
    projectId: string;
    assisting: IAccount;
  }[],
) => ({
  type: SET_ASSISTANT_FOR,
  payload: { assistantFor, assistantForProjects },
});

interface ISetAssistants {
  type: typeof SET_ASSISTANTS;
  payload: { assistants: IAssistant[] };
}

export const setAssistants: ActionCreator<ISetAssistants> = (assistants: IAssistant[]) => ({
  type: SET_ASSISTANTS,
  payload: { assistants },
});

interface ISetAssistant {
  type: typeof SET_ASSISTANT;
  payload: { assistant: IAssistant; projects?: string[] };
}

export const setAssistant: ActionCreator<ISetAssistant> = (assistant: IAssistant, projects?: string[]) => ({
  type: SET_ASSISTANT,
  payload: { assistant, projects },
});

export type IAssistantActions = ISetAssistantFor | ISetAssistants | ISetAssistant;
