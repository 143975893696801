import React, { useState } from 'react';
import classNames from 'classnames';
import { TabButtonVertical } from './TabButtonVertical';
import { ChevronDownOutline } from '../../icons';
import { IVerticalTabWithSubsections } from './interfaces';

export const VerticalTabWithSubsections: React.FC<IVerticalTabWithSubsections> = ({
  label,
  description,
  isChecked,
  activeTab,
  disabled,
  onClick,
  subsections,
}) => {
  const [isOpen, setIsOpen] = useState(Boolean(subsections.find(({ tabKey }) => tabKey === activeTab)));

  return (
    <div className="flex flex-col">
      <TabButtonVertical
        label={label}
        description={description}
        isChecked={isChecked}
        disabled={disabled}
        isSubsectionActive={Boolean(subsections.find(({ tabKey }) => tabKey === activeTab))}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        icon={
          <ChevronDownOutline
            className={classNames(
              {
                'rotate-0': !isOpen,
                'rotate-180': isOpen,
              },
              'flex-shrink-0 w-5 h-5 text-gray-60 transition transform',
            )}
          />
        }
      />

      {isOpen &&
        subsections.map(({ tabKey, label, description, disabled }) => (
          <TabButtonVertical
            key={tabKey}
            label={label}
            description={description}
            isActive={activeTab === tabKey}
            disabled={disabled}
            onClick={() => onClick(tabKey)}
            withinSubsection
          />
        ))}
    </div>
  );
};
