import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

export interface IDropdownOption {
  selected: boolean;
  optionKey: string;
  handleClick: (value: string) => void;
  disabled?: boolean;
  focused: boolean;
  readOnly?: boolean;
}

export const DropdownOption: React.FC<IDropdownOption> = ({
  selected,
  optionKey: key,
  handleClick,
  disabled,
  children,
  focused,
  readOnly,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focused) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [focused]);

  return (
    <div
      role="button"
      ref={ref}
      className={classNames(
        'text-left  py-3 px-4  first:rounded-t last:rounded-b body-small ring-inset ring-offset-2 ring-primary-60',
        {
          'bg-white text-gray-70 cursor-not-allowed': readOnly,
          'text-primary-60 bg-primary-10 ring-offset-primary-10': selected,
          'text-gray-70': !selected && !focused,
          'hover:text-gray-100 hover:bg-gray-20 hover:ring-offset-gray-20': !disabled,
        },
        {
          'bg-white text-gray-40 cursor-not-allowed ring-offset-0 ring-0': disabled,
        },
        {
          'outline-none text-primary-60 bg-gray-10': focused,
        },
      )}
      key={key}
      onClick={() => !disabled && !readOnly && handleClick(key)}
    >
      {children}
    </div>
  );
};
