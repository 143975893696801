import React from 'react';

export interface ICardList {
  title: React.ReactNode;
  notification?: React.ReactNode;
  dataItems?: {
    label: React.ReactNode;
    data: React.ReactNode;
  }[];
  secondaryLink?: React.ReactNode;
  primaryAction?: React.ReactNode;
}

export const CardList: React.FC<ICardList> = ({
  title,
  notification,
  dataItems,
  secondaryLink,
  primaryAction,
  ...otherProps
}) => (
  <div className="h-full w-full bg-white rounded-lg divide-gray-30/60" {...otherProps}>
    <div className="p-6 flex flex-col gap-y-6">
      <div className="heading-6-semibold text-gray-90">{title}</div>
      <div className="flex flex-col gap-y-5">
        {notification}
        <div className="grid grid-cols-3 gap-3">
          {dataItems?.map(({ label, data }, i) => (
            <React.Fragment key={i}>
              <div className="text-gray-60 label-large">{label}</div>
              <div className="col-span-2 text-gray-80 body-small">{data}</div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
    {(primaryAction || secondaryLink) && (
      <>
        <hr />
        <div className="px-6 py-4 flex justify-between items-center gap-x-6">
          <div>{secondaryLink && secondaryLink}</div>
          <div>{primaryAction}</div>
        </div>
      </>
    )}
  </div>
);
