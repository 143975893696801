import React from 'react';

export const DropdownMenuContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...otherProps
}) => (
  <div className={`flex flex-col mt-2 py-2 rounded-lg bg-white shadow-lg ${className}`} {...otherProps}>
    {children}
  </div>
);
