import { QueryClient, MutationCache } from '@tanstack/react-query';
import { shouldRetry } from './fetch';
import { addToast } from 'ui';

import type { QueryKey } from '@tanstack/react-query';
import type { IAddToast } from 'ui/components/Notification';

declare module '@tanstack/react-query' {
  interface MutationMeta {
    invalidates?: QueryKey[];
    okToast?: IAddToast;
    errorToast?: IAddToast;
  }
}

/* istanbul ignore next */
const defaultRetry = (count: number, err: unknown) => count < 3 && shouldRetry(err);

const mutationCache = new MutationCache({
  onSuccess: (_data, _vars, _ctx, mutation) => {
    if (mutation.meta?.okToast) {
      addToast({ variant: 'success', ...mutation.meta.okToast });
    }
    if (mutation.meta?.invalidates) {
      mutation.meta.invalidates.map((k) => queryClient.invalidateQueries({ queryKey: k }));
    }
  },
  onError: (_err, _vars, _ctx, mutation) => {
    if (mutation.meta?.errorToast) {
      addToast({ variant: 'error', ...mutation.meta.errorToast });
    }
  },
});

/* istanbul ignore next */
export const queryClient = new QueryClient({
  mutationCache,
  defaultOptions: {
    queries: {
      staleTime: 5 * 1000, // 5s
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: defaultRetry,
    },
    mutations: {
      //
    },
  },
});
