import React from 'react';
import { HamburgerButton } from './HamburgerButton';
import { InnerContent } from './InnerContent';
import { DropdownMenu, InnerDropdownMenuProps } from '../DropdownMenu';

export const HamburgerMenu: React.FC<Partial<InnerDropdownMenuProps>> = ({ closeOnChangeKey, children }) => (
  <DropdownMenu
    closeOnChangeKey={closeOnChangeKey}
    renderControlNode={({ toggleMenu }) => <HamburgerButton toggleMenu={toggleMenu} />}
    position="bottom"
  >
    <InnerContent>{children}</InnerContent>
  </DropdownMenu>
);
