import { IBroadcastMessagesActions, ADD_BROADCAST_MESSAGES, SET_BROADCAST_MESSAGE_DISMISSED } from './actions';
import { Reducer } from 'redux';
import { IBroadcastMessage } from 'utils/constants';

export interface IReducer {
  broadcastMessages: Record<string, IBroadcastMessage>;
}

export const initialState: IReducer = {
  broadcastMessages: {},
};

export const reducer: Reducer<IReducer, IBroadcastMessagesActions> = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BROADCAST_MESSAGES: {
      const { broadcastMessages } = action.payload;
      return {
        ...state,
        broadcastMessages: {
          ...Object.fromEntries(broadcastMessages.map((c) => [c.uuid, c])),
          ...state.broadcastMessages,
        },
      };
    }

    case SET_BROADCAST_MESSAGE_DISMISSED: {
      const { id } = action.payload;

      return {
        ...state,
        broadcastMessages: {
          ...state.broadcastMessages,
          [id]: {
            ...state.broadcastMessages[id],
            isDismissed: true,
          },
        },
      };
    }

    default:
      return state;
  }
};
