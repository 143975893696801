import React, { Suspense } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { LoadingPage } from './LoadingPage';
import { lazyWithRetry } from '../utils/lazyWithRetry';
import { useFeatureFlags } from 'feature-flags/useFeatureFlags';

const AuthRoutes = lazyWithRetry(() => import('./AuthRouter/AuthRouter'));
const GuestRoutes = lazyWithRetry(() => import('./GuestRouter/GuestRouter'));
const PublicRoutes = lazyWithRetry(() => import('./PublicRouter/PublicRouter'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route path="/guest/*" element={<GuestRoutes />} />
      <Route path="/*" element={<PublicRoutes />} />
    </>,
  ),
);

export const PageRouter: React.FC = () => {
  return (
    <div className="flex flex-col w-full font-body">
      <Suspense fallback={<LoadingPage />}>
        {useFeatureFlags().hasLoaded && <RouterProvider router={router} />}
      </Suspense>
    </div>
  );
};
