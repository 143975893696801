import React from 'react';
import { ItemListStacked } from './ItemListStacked';
import { ItemListDefault } from './ItemListDefault';
import { Tag } from '../Tag/Tag';

type IVariant = 'default' | 'stacked';
type IItemVariant = 'default' | 'bulleted';

export interface IGroupDescriptionList {
  header?: React.ReactNode;
  subheader?: React.ReactNode;
  description?: React.ReactNode;
  items: {
    header: React.ReactNode;
    items: {
      label?: string;
      itemVariant?: IItemVariant;
      data: React.ReactNode;
      updated?: boolean;
    }[];
    updated?: boolean;
  }[];
  actionNode?: React.ReactNode;
  variant?: IVariant;
  updated?: boolean;
  openByDefault?: boolean;
}

export const GroupDescriptionList: React.FC<IGroupDescriptionList> = ({
  header,
  subheader,
  description,
  items,
  actionNode,
  variant = 'default',
  updated,
  openByDefault = true,
}) => (
  <div className="w-full rounded-lg bg-white shadow-sm divide-gray-20/60">
    {(header || subheader || actionNode || description) && (
      <>
        <div className="flex flex-col py-4 px-5">
          <div className="flex justify-between items-center">
            {header && <div className="text-gray-90 heading-6-semibold">{header}</div>}
            {subheader && <div className="text-gray-90 sub-heading">{subheader}</div>}
            {actionNode && <div className="ml-3">{actionNode}</div>}
          </div>
          {description && <div className="text-gray-70 body-small">{description}</div>}
          {updated && (
            <div className="mt-2">
              <Tag text="Updated" />
            </div>
          )}
        </div>
        <hr />
      </>
    )}

    {variant === 'default' &&
      items.map(({ header, items, updated }, i) => (
        <ItemListDefault header={header} items={items} updated={updated} key={i} openByDefault={openByDefault} />
      ))}

    {variant === 'stacked' &&
      items.map(({ header, items, updated }, i) => (
        <ItemListStacked header={header} items={items} updated={updated} key={i} openByDefault={openByDefault} />
      ))}
  </div>
);
