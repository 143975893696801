import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

interface IMultiSelectTag {
  showX: boolean;
  removeItem: (e: React.SyntheticEvent<HTMLDivElement>) => void;
}

export const MultiSelectTag: React.FC<IMultiSelectTag> = ({ children, showX, removeItem }) => (
  <div className="flex w-max max-w-full focus:outline-none" onClick={showX ? removeItem : undefined}>
    <div
      className={classNames(
        { 'pr-1': showX },
        { 'pr-3': !showX },
        'w-full justify-between flex flex-shrink-0 items-center space-x-1 pl-3 py-1 bg-gray-10 hover:bg-gray-20 rounded-full',
      )}
    >
      <div className="text-gray-90 label-regular truncate">{children}</div>
      {showX && <XIcon className="text-gray-70 flex-shrink-0 w-4 h-4" />}
    </div>
  </div>
);
