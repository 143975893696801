import { ILoadingOverlayActions, SET_LOADING_OVERLAY } from './actions';
import { Reducer } from 'redux';

export interface IReducer {
  show: boolean;
}

export const initialState: IReducer = {
  show: false,
};

export const reducer: Reducer<IReducer, ILoadingOverlayActions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_OVERLAY: {
      return {
        ...state,
        show: action.payload.show,
      };
    }

    default:
      return state;
  }
};
