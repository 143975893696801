import React from 'react';
import { ITagMatcher } from './ITagMatcher';

type BoldMatchType = { contents: string };

export const BoldAsteriskTagMatcher: ITagMatcher<BoldMatchType> = {
  matcher: /([^\*]|^)([\*]{2}(?!\*)(.*?[^\*])[\*]{2})(?!\*)/g,
  getMatchData: (results) => ({
    transformationParams: { contents: results[3] },
    startIndex: results.index + results[1].length,
    length: results[2].length,
    // This is the length of ** at the start of the match
    matchOffset: 2,
  }),
  transformer: ({ key, params, parseContent }) => <strong key={key}>{parseContent(params.contents)}</strong>,
};
