import classNames from 'classnames';
import React from 'react';
import { ITabsProps } from './interfaces';
import { TabButtonHorizontal } from './TabButtonHorizontal';
import { TabButtonVertical } from './TabButtonVertical';
import { VerticalTabWithSubsections } from './VerticalTabWithSubsections';

const spacing: Record<string, string> = {
  small: 'space-x-8',
  medium: 'space-x-8',
  large: 'space-x-12',
};

export const Tabs: React.FC<ITabsProps> = ({
  size = 'medium',
  orientation = 'horizontal',
  items,
  activeTab,
  setActiveTab,
}) => {
  if (!items.length) {
    return null;
  }

  const tabButtonRefs = items.map(() => React.createRef<HTMLButtonElement>());
  const scrollIntoView = (tabIndex: number) => {
    tabButtonRefs[tabIndex].current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  };

  if (orientation === 'horizontal') {
    return (
      <div
        className={classNames(
          'box-content w-full flex shadow-[0px_-1px_inset] shadow-gray-30 overflow-scroll no-scrollbar mb-8 -mx-5 px-5 md:mx-0 md:px-0',
          spacing[size],
        )}
      >
        {items.map(({ tabKey, label, disabled = false }, index) => (
          <TabButtonHorizontal
            key={tabKey}
            ref={tabButtonRefs[index]}
            size={size}
            label={label}
            isActive={activeTab === tabKey}
            onClick={() => {
              setActiveTab(tabKey);
              scrollIntoView(index);
            }}
            disabled={disabled}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {items.map(({ tabKey, label, description, isChecked, disabled = false, subsections }) =>
        subsections ? (
          <VerticalTabWithSubsections
            key={tabKey}
            label={label}
            description={description}
            isChecked={isChecked}
            activeTab={activeTab}
            disabled={disabled}
            onClick={setActiveTab}
            subsections={subsections}
          />
        ) : (
          <TabButtonVertical
            key={tabKey}
            label={label}
            description={description}
            isChecked={isChecked}
            isActive={activeTab === tabKey}
            onClick={() => setActiveTab(tabKey)}
            disabled={disabled}
          />
        ),
      )}
    </div>
  );
};
