import React, { useRef } from 'react';
import { uuid as uuidv4 } from 'utils/uuid';
import { DropdownMenu, InnerDropdownMenuProps } from '../DropdownMenu';
import classNames from 'classnames';
import { InputLink } from '../Input/Input';
import { TextLink } from '../TextLink/TextLink';
import { ChevronDownIcon } from '@heroicons/react/outline';

export interface IInput {
  inputRef: React.RefObject<HTMLInputElement>;
  value?: string;
  tags?: React.ReactNode;
  disabled?: boolean;
  label?: string;
  placeholderText?: string;
  helperText?: string;
  showError?: boolean;
  errorMessage?: string | React.ReactNode;
  dropdownMenu: React.ReactNode;
  readOnly?: boolean;
  filter: string;
  setFilter: (filter: string) => void;
  onFilter?: (filter: string) => void;
  increaseFocusedOption: () => void;
  decreaseFocusedOption: () => void;
  handleSelect: () => void;
  link?: InputLink;
  icon?: React.ReactNode;
}

export const Input: React.FC<InnerDropdownMenuProps & IInput> = ({
  inputRef,
  value = '',
  tags,
  disabled,
  label,
  placeholderText,
  helperText,
  showError,
  errorMessage,
  dropdownMenu,
  readOnly,
  filter,
  setFilter,
  onFilter,
  increaseFocusedOption,
  decreaseFocusedOption,
  handleSelect,
  link,
  icon = <ChevronDownIcon />,
  ...dropdownProps
}) => {
  const id = useRef(uuidv4());

  return (
    <div className="flex flex-col space-y-2 w-full">
      {label && (
        <label className={`${disabled ? 'text-gray-40' : 'text-gray-80'} label-large`} htmlFor={id.current}>
          {label}
        </label>
      )}
      <div
        className={classNames(
          {
            'border-error': showError,
            'border-gray-30': !showError,
            'bg-gray-10': readOnly || disabled,
            'bg-white focus-within:border-primary-60 focus-within:ring-1 hover:border-gray-80': !(readOnly || disabled),
          },
          'relative w-full rounded-lg border ring-primary-60 ring-0',
        )}
      >
        <DropdownMenu
          {...dropdownProps}
          variant="overlay"
          disabled={readOnly || disabled}
          onMenuClose={() => {
            dropdownProps.onMenuClose && dropdownProps.onMenuClose();
            setFilter('');
          }}
          onMenuOpen={() => {
            dropdownProps.onMenuOpen && dropdownProps.onMenuOpen();
          }}
          renderControlNode={({ toggleMenu, isOpen }) => (
            <div
              className={classNames(
                'flex items-center justify-between pl-4 pr-3 space-x-4 w-full body-small md:body-regular',
                {
                  'py-2.5': tags,
                  'py-3': !tags,
                },
              )}
              tabIndex={-1}
              onClick={() => {
                if (readOnly || disabled) return;
                inputRef.current?.select();
                toggleMenu();
              }}
            >
              <div className="flex flex-wrap items-center gap-1 min-w-0 w-full">
                {tags}
                <input
                  ref={inputRef}
                  className={classNames('flex-grow h-5 bg-transparent focus:outline-none overflow-ellipsis', {
                    'placeholder-gray-40 text-gray-40': disabled,
                    'text-gray-80': readOnly,
                    'placeholder placeholder-gray-60': value === '' && filter === '',
                    'sub-heading placeholder-gray-80': !(value === '' && filter === ''),
                  })}
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                    onFilter && onFilter(e.target.value);
                  }}
                  disabled={readOnly || disabled}
                  id={id.current}
                  placeholder={value || placeholderText}
                  onKeyDown={(e) => {
                    switch ((e as unknown as { code: string }).code) {
                      case 'ArrowUp': {
                        decreaseFocusedOption();
                        break;
                      }

                      case 'ArrowDown': {
                        increaseFocusedOption();
                        break;
                      }

                      case 'Space': {
                        if (filter.trim() === '') {
                          e.preventDefault();
                          if (isOpen) {
                            handleSelect();
                          }
                          toggleMenu();
                        }
                        break;
                      }

                      case 'Enter': {
                        e.preventDefault();
                        if (isOpen) {
                          handleSelect();
                        }
                        toggleMenu();
                        break;
                      }

                      case 'Tab': {
                        if (!isOpen) break;
                        e.preventDefault();
                        handleSelect();
                        toggleMenu();
                        break;
                      }

                      default:
                        !isOpen && toggleMenu();
                    }
                  }}
                />
              </div>
              <div className="flex-shrink-0 w-5 h-5 text-gray-50">{icon}</div>
            </div>
          )}
        >
          {dropdownMenu}
        </DropdownMenu>
      </div>
      {showError ? (
        <div className="text-error captions">{errorMessage}</div>
      ) : (
        helperText && <div className={`${disabled ? 'text-gray-40' : 'text-gray-60'} captions`}>{helperText}</div>
      )}
      {link && (
        <TextLink className="w-auto self-start pt-1" to={link.to} href={link.href}>
          {link.text}
        </TextLink>
      )}
    </div>
  );
};

Input.displayName = 'Input';
