import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Header } from './Header';
import { DatePickerInput } from './DatePickerInput';
import { Calendar } from './Calendar';
import { IDateRange } from './types';
import { DatePickerGroup } from './DatePickerGroup';
import moment from 'moment';
import useTopBottomReposition from '../hooks/useTopBottomReposition';
import classNames from 'classnames';
import { formatDateInNumeric } from 'utils/date';

export interface IRangeDatePicker {
  dateRange: IDateRange;
  setDateRange: (dateRange: IDateRange) => void;
  limit?: IDateRange;
  placeholderTexts?: {
    start: string;
    end: string;
  };
  labelTexts?: {
    start?: string;
    end?: string;
  };
  errorMessages?: {
    start?: string;
    end?: string;
  };
  helperTexts?: {
    start?: string;
    end?: string;
  };
  valid?: {
    start?: boolean;
    end?: boolean;
  };
  numMonthsToShow?: number;
  container: React.ComponentClass | React.FC;
  calendarContainer: React.ComponentClass | React.FC;
}

export const RangeDatePicker: React.FC<IRangeDatePicker> = ({
  dateRange,
  setDateRange,
  limit = { startDate: null, endDate: null },
  placeholderTexts,
  labelTexts = {},
  helperTexts = {},
  errorMessages = {},
  valid = {},
  numMonthsToShow = 1,
  container: Container,
  calendarContainer: CalendarContainer,
  ...otherProps
}) => {
  const { startDate, endDate } = dateRange;

  const [isOpen, setIsOpen] = useState(false);

  const now = useRef(new Date());
  const dateOrDefault = startDate || now.current;

  const [renderedStartDate, setRenderedStartDate] = useState(moment(dateOrDefault).startOf('month').toDate());

  useEffect(() => {
    setRenderedStartDate(dateOrDefault);
  }, [isOpen]);

  useEffect(() => {
    if (moment(startDate).isAfter(moment(endDate))) {
      setDateRange({ startDate, endDate: startDate });
    }
  }, [startDate]);

  useEffect(() => {
    if (moment(startDate).isAfter(moment(endDate))) {
      setDateRange({ startDate: endDate, endDate });
    }
  }, [endDate]);

  const decreaseMonth = useCallback(
    () => setRenderedStartDate(moment(renderedStartDate).subtract(1, 'M').toDate()),
    [renderedStartDate, setRenderedStartDate],
  );

  const increaseMonth = useCallback(
    () => setRenderedStartDate(moment(renderedStartDate).add(1, 'M').toDate()),
    [renderedStartDate, setRenderedStartDate],
  );

  const [calendarRef, positionStyles] = useTopBottomReposition<HTMLDivElement>({ isOpen });

  const updateDateRange = (date: Date | null) => {
    if (moment(startDate).isSame(moment(endDate))) {
      if (moment(startDate).isBefore(date)) {
        setDateRange({ startDate, endDate: date });
      } else {
        setDateRange({ startDate: date, endDate: startDate });
      }
      return;
    }

    setDateRange({ startDate: date, endDate: date });
  };

  return (
    <div {...otherProps} className="w-full relative group" ref={calendarRef}>
      <DatePickerGroup closeDatePicker={() => setIsOpen(false)}>
        <Container>
          <DatePickerInput
            value={startDate ? formatDateInNumeric(startDate) : ''}
            placeholderText={placeholderTexts?.start}
            openCalendar={() => setIsOpen(true)}
            toggleCalendar={() => setIsOpen(!isOpen)}
            setDate={(date: Date | null) => setDateRange({ startDate: date, endDate })}
            limit={limit}
            label={labelTexts.start}
            valid={valid.start}
            errorMessage={isOpen ? undefined : errorMessages.start}
            helperText={isOpen ? undefined : helperTexts.start}
          />
          <DatePickerInput
            value={endDate ? formatDateInNumeric(endDate) : ''}
            placeholderText={placeholderTexts?.end}
            openCalendar={() => setIsOpen(true)}
            toggleCalendar={() => setIsOpen(!isOpen)}
            setDate={(date: Date | null) => setDateRange({ startDate, endDate: date })}
            limit={limit}
            label={labelTexts.end}
            valid={valid.end}
            errorMessage={isOpen ? undefined : errorMessages.end}
            helperText={isOpen ? undefined : helperTexts.end}
          />
        </Container>

        {isOpen && (
          <div className={classNames('absolute left-0 flex z-50', positionStyles)}>
            <div className="bg-white rounded-lg border border-primary-60 ring-1 ring-primary-60 shadow-md mt-2">
              <CalendarContainer>
                {new Array(numMonthsToShow).fill(null).map((_, i) => (
                  <div key={i} className="flex flex-col items-center">
                    <Header
                      date={moment(renderedStartDate).add(i, 'M').toDate()}
                      decreaseMonth={i === 0 ? decreaseMonth : undefined}
                      increaseMonth={i === numMonthsToShow - 1 ? increaseMonth : undefined}
                    />
                    <Calendar
                      month={moment(renderedStartDate).add(i, 'M').toDate().getMonth()}
                      year={moment(renderedStartDate).add(i, 'M').toDate().getFullYear()}
                      selection={dateRange}
                      limit={limit}
                      setDate={updateDateRange}
                      hideOverflowDates={{ previous: i !== 0, next: i !== numMonthsToShow - 1 }}
                    />
                  </div>
                ))}
              </CalendarContainer>
            </div>
          </div>
        )}
      </DatePickerGroup>
    </div>
  );
};
