import React, { useState } from 'react';
import { TabsWithContentProps } from './interfaces';
import { Tabs } from './Tabs';

export const TabsWithContent: React.FC<TabsWithContentProps> = ({ size = 'medium', items, showSelectedTab }) => {
  if (!items.length) {
    return null;
  }

  const firstEnabledTab = items.find(({ disabled }) => disabled !== true)?.tabKey || '';
  const [initialActiveTab, setInitialActiveTab] = useState<string>(showSelectedTab || firstEnabledTab);

  const tabItems = items.map(({ tabKey, label, disabled }) => ({ tabKey, label, disabled }));

  return (
    <div className="box-content w-full">
      <Tabs size={size} items={tabItems} activeTab={initialActiveTab} setActiveTab={setInitialActiveTab} />
      {items.find(({ tabKey }) => tabKey === initialActiveTab)?.content}
    </div>
  );
};
