import React, { useState, useLayoutEffect, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { InnerDropdownMenuProps, DropdownMenuOpenState } from './shared';
import classNames from 'classnames';
import useTopBottomReposition from '../hooks/useTopBottomReposition';

export const RealignOverlayMenu = React.createContext(() => {
  return;
});

const MaskingDiv = styled.div`
  min-width: fit-content;
  clip-path: inset(0 -100vw -100vh -100vw);
`;

export interface IOverlayMenu {
  align?: 'left' | 'right';
  position?: 'top' | 'bottom';
}

const alignmentMapping = {
  left: 'left-0',
  right: 'right-0',
};

export const OverlayMenu: React.FC<IOverlayMenu & InnerDropdownMenuProps> = ({
  controlNode,
  renderControlNode,
  closeOnChangeKey,
  disabled,
  align,
  children,
  className,
  onMenuClose,
  onMenuOpen,
  position,
  ...otherProps
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const timeout = useRef<number>();

  const handleBlur = useCallback(
    (e: React.FocusEvent) => {
      const currentTarget = e.currentTarget;

      window.clearTimeout(timeout.current);
      timeout.current = window.setTimeout(() => {
        if (!currentTarget.contains(document.activeElement)) {
          setIsOpen(false);
        }
      }, 0);
    },
    [setIsOpen, timeout.current],
  );

  const [alignmentStyling, setAlignmentStyling] = useState('right-0');

  const updateAlignmentStyling = () => {
    if (!isOpen) {
      return;
    }

    if (align) {
      setAlignmentStyling(alignmentMapping[align]);
      return;
    }

    const x = wrapperRef.current?.getBoundingClientRect().left || 0;
    const width = menuRef.current?.getBoundingClientRect().width || 0;

    setAlignmentStyling(x + width > window.innerWidth ? 'right-0' : 'left-0');
  };

  useLayoutEffect(() => {
    updateAlignmentStyling();
  }, [
    isOpen,
    wrapperRef.current?.getBoundingClientRect().left,
    menuRef.current?.getBoundingClientRect().width,
    window.innerWidth,
  ]);

  useEffect(() => {
    setIsOpen(false);
  }, [closeOnChangeKey]);

  useEffect(() => {
    if (!isOpen) {
      onMenuClose?.();
    } else {
      onMenuOpen?.();
    }
  }, [isOpen]);

  useEffect(
    () => () => {
      window.clearTimeout(timeout.current);
    },
    [timeout.current],
  );

  const disablePointerEventsOnOpen = isOpen ? '' : 'pointer-events-none';

  const topPosition = 'bottom-full';
  const topPositionAnimation = isOpen ? '-translate-y-0 opacity-100 visible' : 'opacity-0 invisible';

  const bottomPosition = 'top-full';
  const bottomPositionAnimation = isOpen
    ? '-translate-y-0 opacity-100 visible'
    : '-translate-y-full opacity-0 invisible';

  const dropdownHeight = menuRef.current?.getBoundingClientRect().height;

  const [overlayMenuRef, menuPositionStyles, targetPosition] = useTopBottomReposition<HTMLDivElement>({
    isOpen,
    dropdownHeight,
    positionStyles: {
      top: `${topPosition} ${topPositionAnimation}`,
      bottom: `${bottomPosition} ${bottomPositionAnimation}`,
    },
  });

  const finalPosition = position ?? targetPosition;

  return (
    <RealignOverlayMenu.Provider value={updateAlignmentStyling}>
      <DropdownMenuOpenState.Provider value={isOpen}>
        <div className="w-full" ref={overlayMenuRef}>
          <div className={`flex w-full h-full ${className}`} ref={wrapperRef} onBlur={handleBlur} {...otherProps}>
            <>
              {controlNode && (
                <button
                  type="button"
                  className="w-full h-full focus:outline-none disabled:cursor-not-allowed"
                  disabled={disabled}
                  onClick={() => {
                    !isOpen && buttonRef.current?.focus();
                    setIsOpen(!isOpen);
                  }}
                  ref={buttonRef}
                >
                  {controlNode}
                </button>
              )}
            </>
            <>
              {renderControlNode?.({
                toggleMenu: () => {
                  !isOpen && buttonRef.current?.focus();
                  setIsOpen(!isOpen);
                },
                isOpen,
              })}
            </>
            <MaskingDiv
              className={classNames(
                'absolute w-full z-10',
                finalPosition === 'top' ? topPosition : bottomPosition,
                disablePointerEventsOnOpen,
                alignmentStyling,
              )}
            >
              <div className="transition-all delay-300">
                <div
                  className={classNames(
                    'focus:outline-none w-full transition-all transform duration-300',
                    menuPositionStyles,
                    alignmentStyling,
                  )}
                  ref={menuRef}
                  tabIndex={-1}
                >
                  {children}
                </div>
              </div>
            </MaskingDiv>
          </div>
        </div>
      </DropdownMenuOpenState.Provider>
    </RealignOverlayMenu.Provider>
  );
};
