import React from 'react';
import { Checkbox } from '../Checkbox/Checkbox';

export interface IToggleCheckbox extends React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  isSelected: boolean;
  setSelected: (isSelected: boolean) => void;
  disabled?: boolean;
  isErrored?: boolean;
  errorMessage?: string;
}

export const ToggleCheckbox: React.FC<IToggleCheckbox> = ({
  label,
  isSelected,
  setSelected,
  disabled,
  isErrored,
  errorMessage,
  ...otherProps
}) => (
  <Checkbox
    {...otherProps}
    label={label}
    item={true}
    selectedItems={[isSelected]}
    setSelectedItems={(selectionState) => {
      setSelected(selectionState.includes(true));
    }}
    isErrored={isErrored}
    disabled={disabled}
    errorMessage={errorMessage}
  />
);
