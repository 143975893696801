import React from 'react';

export const TableRow: React.FC<React.HTMLAttributes<HTMLTableRowElement>> = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <tr
      className={`group sm:last:rounded-b-lg sm:last:border-b-0 sm:border-b sm:border-gray-10 ${className}`}
      {...otherProps}
    >
      {children}
    </tr>
  );
};
