import { uuid as uuidv4 } from 'utils/uuid';
import React, { useCallback, useRef } from 'react';
import { IDropdownInput } from './DropdownInput';
import { DropdownOption } from './DropdownOption';
import { MenuContainer } from './MenuContainer';
import { DropdownMenu, InnerDropdownMenuProps } from '../DropdownMenu';
import classNames from 'classnames';
import { useWithFilter } from './useWithFilter';
import { NoMatch } from './NoMatch';
import { Chevron } from './Chevron';
import { IInput } from './Input';

export const DropdownInputPrefix: React.FC<IDropdownInput & InnerDropdownMenuProps> = ({
  options,
  selectedOption,
  setSelectedOption,
  readOnly,
  placeholderText,
  noMatchText = 'No Matches Found',
}) => {
  const {
    filter,
    setFilter,
    keyToFocus,
    filteredOptions,
    handleClick,
    handleSelect,
    increaseFocusedOption,
    decreaseFocusedOption,
  } = useWithFilter(options, setSelectedOption);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleOptionClick = useCallback(
    (key: string) => {
      handleClick(key);
      inputRef.current?.select();
    },
    [handleClick],
  );

  return (
    <div className="relative text-gray-80 body-small md:body-regular">
      <Input
        inputRef={inputRef}
        placeholderText={placeholderText}
        value={options.find(({ key }) => key === selectedOption)?.displayName || ''}
        filter={filter}
        setFilter={setFilter}
        increaseFocusedOption={increaseFocusedOption}
        decreaseFocusedOption={decreaseFocusedOption}
        handleSelect={handleSelect}
        readOnly={readOnly}
        dropdownMenu={
          <MenuContainer>
            {filteredOptions.length > 0 ? (
              filteredOptions.map(({ key, displayName, disabled }) => (
                <DropdownOption
                  selected={key === selectedOption}
                  key={key}
                  optionKey={key}
                  handleClick={handleOptionClick}
                  disabled={disabled}
                  focused={keyToFocus === key}
                >
                  {displayName}
                </DropdownOption>
              ))
            ) : (
              <NoMatch>{noMatchText}</NoMatch>
            )}
          </MenuContainer>
        }
      />
    </div>
  );
};

const Input: React.FC<InnerDropdownMenuProps & IInput> = ({
  inputRef,
  value = '',
  tags,
  disabled,
  placeholderText,
  helperText,
  showError,
  errorMessage,
  dropdownMenu,
  readOnly,
  filter,
  setFilter,
  increaseFocusedOption,
  decreaseFocusedOption,
  handleSelect,
  ...dropdownProps
}) => {
  const id = useRef(uuidv4());

  return (
    <div className="flex flex-col space-y-2 w-full">
      <div className="relative w-full">
        <DropdownMenu
          {...dropdownProps}
          variant="overlay"
          disabled={readOnly || disabled}
          onMenuClose={() => setFilter('')}
          renderControlNode={({ toggleMenu, isOpen }) => (
            <div
              className={'flex items-center justify-between space-x-1 w-full body-small md:body-regular'}
              tabIndex={-1}
              onClick={() => {
                inputRef.current?.select();
                toggleMenu();
              }}
            >
              <input
                ref={inputRef}
                className={classNames(
                  'w-full disabled:placeholder-gray-40 text-gray-80 disabled:text-gray-40 h-5 bg-transparent focus:outline-none overflow-ellipsis',
                  {
                    'placeholder placeholder-gray-60': value === '' && filter === '',
                    'sub-heading placeholder-gray-80': !(value === '' && filter === ''),
                  },
                )}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                disabled={disabled}
                id={id.current}
                placeholder={value || placeholderText}
                onKeyDown={(e) => {
                  switch ((e as unknown as { code: string }).code) {
                    case 'ArrowUp': {
                      decreaseFocusedOption();
                      break;
                    }

                    case 'ArrowDown': {
                      increaseFocusedOption();
                      break;
                    }

                    case 'Space':
                    case 'Enter': {
                      e.preventDefault();
                      if (isOpen) {
                        handleSelect();
                      }
                      toggleMenu();
                      break;
                    }

                    case 'Tab': {
                      if (!isOpen) break;
                      e.preventDefault();
                      handleSelect();
                      toggleMenu();
                      break;
                    }

                    default:
                      !isOpen && toggleMenu();
                  }
                }}
              />
              <Chevron />
            </div>
          )}
        >
          {dropdownMenu}
        </DropdownMenu>
      </div>
    </div>
  );
};
