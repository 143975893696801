import React from 'react';

export interface IRouteContext {
  redirect: (to: string) => void;
}

export const RouteContext = React.createContext<IRouteContext>({
  redirect: () => {
    return;
  },
});
