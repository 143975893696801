import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { TextLink } from '../../TextLink/TextLink';

const colors = {
  informational: {
    backgroundColor: 'bg-primary-60',
    iconColor: 'text-white',
    messageColor: 'text-white',
    actionColor: 'text-white',
  },
  error: {
    backgroundColor: 'bg-error',
    iconColor: 'text-white',
    messageColor: 'text-white',
    actionColor: 'text-white',
  },
  success: {
    backgroundColor: 'bg-success',
    iconColor: 'text-white',
    messageColor: 'text-white',
    actionColor: 'text-white',
  },
  warning: {
    backgroundColor: 'bg-warning',
    iconColor: 'text-gray-100',
    messageColor: 'text-gray-70',
    actionColor: 'text-gray-100',
  },
  neutral: {
    backgroundColor: 'bg-gray-80',
    iconColor: 'text-white',
    messageColor: 'text-white',
    actionColor: 'text-primary-40',
  },
};

export type Variant = 'informational' | 'success' | 'error' | 'warning' | 'neutral';

export interface IToast {
  variant?: Variant;
  message: string | React.ReactNode;
  action?: {
    label: string | React.ReactNode;
    onClick: () => void;
  };
  icon?: React.ReactNode;
  dismiss: () => void;
}

export const Toast: React.FC<IToast> = ({ dismiss, variant = 'neutral', message, action, icon }) => {
  const { backgroundColor, messageColor, iconColor, actionColor } = colors[variant];

  return (
    <div
      data-cy="toast-message"
      className={`flex rounded-lg py-3 px-4 w-80 max-h-22 space-x-3 shadow-lg ${backgroundColor}`}
    >
      {icon && <div className={`flex-shrink-0 w-6 h-6 ${iconColor}`}>{icon}</div>}
      <div className="flex flex-col w-full space-y-1">
        <div className="flex w-full justify-between space-x-5">
          <div className={`body-small ${messageColor}`}>{message}</div>
          <button className="rounded-full focus:outline-none text-white hover:text-gray-20" onClick={dismiss}>
            <XIcon className="flex-shrink-0 w-5 h-5" />
          </button>
        </div>
        {action && (
          <div className={`w-max ${actionColor}`}>
            <TextLink
              variant="unstyled"
              onClick={() => {
                action?.onClick();
                dismiss();
              }}
            >
              {action?.label}
            </TextLink>
          </div>
        )}
      </div>
    </div>
  );
};
