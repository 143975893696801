import React from 'react';

interface IItem {
  key: string;
  title: string;
  description: string;
  image?: React.ReactNode;
  disabled?: boolean;
  bottomSection?: React.ReactNode;
}

interface ISelectableTile {
  item: IItem;
  selectedItem: string | null;
  setSelectedItem: (key: string) => void;
}

export const SelectableTile: React.FC<ISelectableTile> = ({ item, selectedItem, setSelectedItem, ...otherProps }) => {
  const { key, title, description, disabled, image, bottomSection } = item;
  const selected = selectedItem === key;

  const handleChange = () => {
    setSelectedItem(key);
  };

  return (
    <div
      className={`${selected ? 'border-primary-60' : 'border-transparent'} ${
        disabled ? '' : 'hover:border-gray-80 focus-within:border-primary-60 focus-within:ring-2 ring-primary-60'
      } focus:outline-none flex flex-col shadow-sm rounded-lg border bg-white divide-y divide-gray-10`}
    >
      <label className="flex items-center space-x-3 px-5 py-4 space-between">
        <input
          type="radio"
          disabled={disabled}
          checked={selected}
          onChange={handleChange}
          className="disabled:bg-gray-10 checked:bg-primary-60 bg-white disabled:ring-0 ring-4 ring-white ring-inset flex-shrink-0 w-5 h-5 rounded-full border border-gray-30 appearance-none cursor-pointer focus:outline-none w-5 h-5 rounded-full disabled:cursor-not-allowed"
          aria-label={title}
          {...otherProps}
        />
        <div className="flex-grow">
          <div className="sub-heading text-gray-90">{title}</div>
          <div className="label-large text-gray-60">{description}</div>
        </div>
        {image && <div>{image}</div>}
      </label>
      {bottomSection && <div className="px-5 py-4">{bottomSection}</div>}
    </div>
  );
};
