import { IAssistantActions, SET_ASSISTANT_FOR, SET_ASSISTANTS, SET_ASSISTANT } from './actions';
import { Reducer } from 'redux';
import { IAccount, IAssistant } from 'utils/constants';

export interface IReducer {
  assistants?: Record<string, IAssistant>;
  assistantFor?: Record<string, IAccount>;
  assistantProjects?: Record<string, string[]>;
  assistantForProjects?: {
    projectId: string;
    assisting: IAccount;
  }[];
}

export const initialState: IReducer = {};

export const reducer: Reducer<IReducer, IAssistantActions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_ASSISTANT_FOR: {
      const { assistantFor, assistantForProjects } = action.payload;
      return {
        ...state,
        assistantFor: assistantFor && Object.fromEntries(assistantFor.map((account) => [account.uuid, account])),
        assistantForProjects,
      };
    }

    case SET_ASSISTANTS: {
      const { assistants } = action.payload;
      return {
        ...state,
        assistants: assistants && Object.fromEntries(assistants.map((a) => [a.uuid, a])),
      };
    }

    case SET_ASSISTANT: {
      const { assistant, projects } = action.payload;
      return {
        ...state,
        assistants: {
          ...state.assistants,
          [assistant.uuid]: assistant,
        },
        ...(projects
          ? {
              assistantProjects: {
                ...state.assistantProjects,
                [assistant.uuid]: projects,
              },
            }
          : null),
      };
    }

    default:
      return state;
  }
};
