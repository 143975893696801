import React from 'react';
import { ITagMatcher } from './TagMatchers/ITagMatcher';
import { getTagMatches } from './getTagMatches';

interface IMarkDown {
  tagMatchers: ITagMatcher<unknown>[];
  message: string;
}

export const MarkDown: React.FC<IMarkDown> = ({ tagMatchers, message }) => {
  const tagMatches = tagMatchers
    .map((tagMatcher) => getTagMatches(tagMatcher, message))
    .reduce((a, c) => ({ ...c, ...a }), {});

  return <span>{parseContent(tagMatches, message)}</span>;
};

export const parseContent = <T,>(
  tagMatches: Record<
    number,
    {
      params: T;
      length: number;
      matchOffset: number;
      transformer: (options: {
        key: number;
        params: T;
        parseContent: (content: string) => React.ReactNode;
      }) => React.ReactNode;
    }
  >,
  content: string,
  offset = 0,
): React.ReactNode => {
  let pointer = 0;
  const transformedContents: React.ReactNode[] = [];

  while (pointer < content.length) {
    const nextMatches = Object.keys(tagMatches)
      .map((e) => Number(e))
      .filter((index) => index >= pointer + offset)
      .filter((index) => index <= content.length + offset)
      .sort((a, b) => a - b);

    const match = tagMatches[pointer + offset];
    const isNextMatchValid = pointer + (match?.length ?? 0) <= content.length;
    const nextValidMatchIndex = isNextMatchValid ? 0 : 1;

    if (match && isNextMatchValid) {
      const { matchOffset, length, params, transformer } = match;
      transformedContents.push(
        transformer({
          key: pointer,
          params,
          parseContent: (content: string) => parseContent(tagMatches, content, pointer + offset + matchOffset),
        }),
      );
      pointer += length;
    } else {
      const nextPointer = nextMatches[nextValidMatchIndex] ? nextMatches[nextValidMatchIndex] - offset : content.length;

      transformedContents.push(
        <React.Fragment key={pointer + offset}>{content.slice(pointer, nextPointer)}</React.Fragment>,
      );
      pointer = nextPointer;
    }
  }

  return transformedContents.map((e) => e);
};
