import React, { useContext } from 'react';
import { RouteContext } from './RouteContext';
import { ColorContext } from './ColorContext';
import { LinkProps, ExternalLinkProps, InternalLinkProps, BaseLinkProps, isExternalLink } from './shared';

const textSizes = {
  regular: 'link',
  small: 'captions',
};

const sharedStyles = 'whitespace-nowrap py-1';

export const Link: React.FC<LinkProps> = (props) =>
  isExternalLink(props) ? <ExternalLink {...props} /> : <InternalLink {...props} />;

const ExternalLink: React.FC<React.HTMLAttributes<HTMLAnchorElement> & ExternalLinkProps & BaseLinkProps> = ({
  external,
  children,
  textSize = 'regular',
  ...otherProps
}) => {
  const colorMode = useContext(ColorContext);
  const color = {
    light: 'text-gray-80',
    dark: 'text-gray-20',
  }[colorMode];

  return (
    <a
      className={`${color} ${sharedStyles} ${textSizes[textSize]} focus:outline-none focus:underline hover:underline`}
      {...otherProps}
    >
      <div className="flex items-center">{children}</div>
    </a>
  );
};

const InternalLink: React.FC<InternalLinkProps & BaseLinkProps> = ({ path, children, textSize = 'regular' }) => {
  const { redirect } = useContext(RouteContext);
  const colorMode = useContext(ColorContext);
  const color = {
    light: 'text-gray-80',
    dark: 'text-gray-20',
  }[colorMode];

  return (
    <a
      className={`${color} ${sharedStyles}`}
      onClick={(e: React.SyntheticEvent) => {
        e.preventDefault();
        redirect(path);
      }}
    >
      <div className="flex items-center">
        <button tabIndex={0} className={`${textSizes[textSize]} focus:outline-none focus:underline hover:underline`}>
          {children}
        </button>
      </div>
    </a>
  );
};
