import React, { useContext } from 'react';
import { HamburgerMenu } from '../../HamburgerMenu/HamburgerMenu';
import { RouteContext } from '../shared';

export const MenuContainer: React.FC = ({ children }) => {
  const { path } = useContext(RouteContext);

  return (
    <div className="flex justify-end h-full flex-shrink-0 text-gray-70">
      <div className="hidden sm:flex ml-10 items-center space-x-4">{children}</div>
      <HamburgerMenu closeOnChangeKey={path}>
        <div className="bg-white overflow-hidden h-screen flex flex-col sm:flex-row sm:items-center">{children}</div>
      </HamburgerMenu>
    </div>
  );
};
