import React from 'react';
import classNames from 'classnames';

import AccordionHeader from './AccordionHeader';
import AccordionPanel from './AccordionPanel';
import type { AccordionItem, AccordionProps } from './interfaces';

const AccordionComponent: React.FC<AccordionProps> = (props) => {
  const { label, items = [], defaultExpandedKeys } = props;

  const [expandedKeys, setExpandedKeys] = React.useState<React.Key[]>([]);

  React.useEffect(() => {
    if (defaultExpandedKeys === 'all') {
      // If 'all', expand all items
      setExpandedKeys(items.map((item, idx) => getKey(item, idx)));
    } else if (defaultExpandedKeys === 'first') {
      items[0] && setExpandedKeys([getKey(items[0], 0)]);
    } else if (defaultExpandedKeys === 'last') {
      const lastIdx = items.length - 1;
      lastIdx >= 0 && setExpandedKeys([getKey(items[lastIdx], lastIdx)]);
    } else if (Array.isArray(defaultExpandedKeys)) {
      // If it's an array, expand all keys in the array
      setExpandedKeys(defaultExpandedKeys);
    } else if (defaultExpandedKeys) {
      // If it's a single key, expand only that item
      setExpandedKeys([defaultExpandedKeys]);
    }
  }, [defaultExpandedKeys, items]);

  const toggleItem = (key: React.Key) => {
    setExpandedKeys((currentKeys) =>
      currentKeys.includes(key) ? currentKeys.filter((k) => k !== key) : [...currentKeys, key],
    );
  };

  // generate a key for each item
  const getKey = (item: AccordionItem, idx: number) => item.key ?? `cx-accordion-k-${idx}`;

  return (
    <div className="w-full flex flex-col " data-testid="accordion">
      {label && <div className="label-large text-gray-80 mb-4">{label}</div>}
      <div className="w-full bg-white rounded-lg shadow-[0px_0px_1px_0px_rgba(17,20,23,0.24)]">
        {items.map((item, idx) => {
          const k = getKey(item, idx);
          const isExpanded = expandedKeys.includes(k);

          return (
            <div
              key={k}
              className={classNames('last:rounded-lg', {
                'shadow-[0px_-1px_0px_0px_rgba(224,228,232,0.60)_inset]': isExpanded,
                'shadow-[0px_-1px_0px_0px_rgba(196,202,207,0.60)_inset]': !isExpanded,
              })}
            >
              <AccordionHeader label={item.label} isExpanded={isExpanded} onClick={() => toggleItem(k)} />
              <AccordionPanel isExpanded={isExpanded}>{item.content}</AccordionPanel>
            </div>
          );
        })}
      </div>
    </div>
  );
};

AccordionComponent.displayName = 'Accordion';

export const Accordion = Object.assign(AccordionComponent, {
  Header: AccordionHeader,
  Panel: AccordionPanel,
});
