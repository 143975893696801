interface IMonetaryAmount {
  amount: number;
}

interface IFormatOptions {
  currencyPrefix?: boolean;
}

export class MonetaryAmount {
  private amount: number;

  constructor({ amount }: IMonetaryAmount) {
    this.amount = amount;
  }

  getAmount(): number {
    return this.amount;
  }

  add(otherAmount: MonetaryAmount): MonetaryAmount {
    return new MonetaryAmount({ amount: this.amount + otherAmount.getAmount() });
  }

  subtract(otherAmount: MonetaryAmount): MonetaryAmount {
    return new MonetaryAmount({ amount: this.amount - otherAmount.getAmount() });
  }

  format({ currencyPrefix = true }: IFormatOptions = {}): string {
    const amountInDollars = this.amount / 100;
    return currencyPrefix
      ? new Intl.NumberFormat('en', {
          style: 'currency',
          currency: 'SGD',
        }).format(amountInDollars)
      : new Intl.NumberFormat('en', { minimumFractionDigits: 2 }).format(amountInDollars);
  }
}
