import React from 'react';

export interface ILoadingSpinner {
  className?: string;
  variant?: 'default' | 'button-white' | 'button-secondary' | 'button-tertiary';
  size?: 'small' | 'medium' | 'large';
}

const sharedStyles = 'animate-spin rounded-full';

const colors = {
  default: 'border-t-color-primary-30 border-primary-60',
  'button-white': 'border-opacity-40 border-t-color-white border-white',
  'button-secondary': 'border-opacity-40 border-t-color-primary-60 border-primary-60',
  'button-tertiary': 'border-opacity-40 border-t-color-gray-80 border-gray-80',
};

const sizes = {
  small: 'w-4 h-4 border-2',
  medium: 'w-8 h-8 border-4',
  large: 'w-10 h-10 border-4',
};

export const LoadingSpinner: React.FC<ILoadingSpinner> = ({
  className,
  variant = 'default',
  size = 'large',
  ...otherProps
}) => <div className={`${sharedStyles} ${colors[variant]} ${sizes[size]} ${className}`} {...otherProps} />;
