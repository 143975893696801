import {
  IPaymentActions,
  SET_MY_PAYMENTS,
  SET_PAYMENT,
  SET_PAYMENTS,
  SET_PAYMENT_TRANSACTION,
  SET_EMAIL_ADDRESSES,
  SET_SELECTED_PAYMENT_IDS,
  ADD_SELECTED_PAYMENT_ID,
  REMOVE_SELECTED_PAYMENT_ID,
  SET_PAYMENT_RECEIPT,
  CLEAR_PAYMENT_RECEIPTS,
  SET_GUEST_PAYMENT,
  SET_INVALID_PAYMENT,
} from './actions';
import { Reducer } from 'redux';
import { IPayment, IPaymentTransaction, IGuestPayment } from 'utils/constants';
import { isEqual } from 'lodash';

export interface IReducer {
  myPaymentIds?: string[];
  payments?: IPayment[];
  guestPayments: Record<string, IGuestPayment>;
  paymentGuestPayments: Record<string, string[]>;
  receipts: Record<string, string | null>;
  paymentTransactions: Record<string, IPaymentTransaction | null>;
  emailAddresses: Record<string, string[]>;
  selectedPaymentIds: string[];
  isInvalidPayment?: boolean;
}

export const initialState: IReducer = {
  guestPayments: {},
  paymentGuestPayments: {},
  receipts: {},
  paymentTransactions: {},
  emailAddresses: {},
  selectedPaymentIds: [],
  isInvalidPayment: false,
};

export const reducer: Reducer<IReducer, IPaymentActions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_MY_PAYMENTS: {
      const { payments } = action.payload;
      return {
        ...state,
        myPaymentIds: payments?.map(({ uuid }) => uuid) ?? [],
      };
    }

    case SET_PAYMENT: {
      const { payment } = action.payload;
      return {
        ...state,
        payments: [...(state.payments?.filter(({ uuid }) => uuid !== payment.uuid) || []), payment],
      };
    }

    case SET_PAYMENTS: {
      const { payments } = action.payload;

      if (isEqual(state.payments, payments)) return state;

      return {
        ...state,
        payments: [
          ...(state.payments?.filter(({ uuid }) => !payments.find((payment) => payment.uuid === uuid)) || []),
          ...payments,
        ],
      };
    }

    case SET_PAYMENT_TRANSACTION: {
      const { id, paymentTransaction } = action.payload;
      return {
        ...state,
        paymentTransactions: {
          ...state.paymentTransactions,
          [id]: paymentTransaction,
        },
      };
    }

    case SET_EMAIL_ADDRESSES: {
      const { emailAddresses, transactionId } = action.payload;
      return {
        ...state,
        emailAddresses: {
          ...state.emailAddresses,
          [transactionId]: emailAddresses,
        },
      };
    }

    case SET_SELECTED_PAYMENT_IDS: {
      const { selectedPaymentIds } = action.payload;
      return {
        ...state,
        selectedPaymentIds: [...selectedPaymentIds],
      };
    }

    case ADD_SELECTED_PAYMENT_ID: {
      const { paymentId } = action.payload;
      return {
        ...state,
        selectedPaymentIds: state.selectedPaymentIds.includes(paymentId)
          ? state.selectedPaymentIds
          : [...state.selectedPaymentIds, paymentId],
      };
    }

    case REMOVE_SELECTED_PAYMENT_ID: {
      const { paymentId } = action.payload;
      return {
        ...state,
        selectedPaymentIds: state.selectedPaymentIds.filter((uuid) => uuid !== paymentId),
      };
    }

    case SET_PAYMENT_RECEIPT: {
      const { paymentId, receiptUrl } = action.payload;
      return {
        ...state,
        receipts: {
          ...state.receipts,
          [paymentId]: receiptUrl,
        },
      };
    }

    case CLEAR_PAYMENT_RECEIPTS: {
      for (const url of Object.values(state.receipts)) {
        if (url) URL.revokeObjectURL(url);
      }
      return {
        ...state,
        receipts: {},
      };
    }

    case SET_GUEST_PAYMENT: {
      const { guestPayment, paymentIds } = action.payload;
      return {
        ...state,
        guestPayments: {
          ...state.guestPayments,
          [guestPayment.uuid]: guestPayment,
        },
        paymentGuestPayments: paymentIds.reduce((paymentGuestPayments, paymentId) => {
          return {
            ...paymentGuestPayments,
            [paymentId]: [
              ...(paymentGuestPayments[paymentId]?.filter((id) => id !== guestPayment.uuid) || []),
              guestPayment.uuid,
            ],
          };
        }, state.paymentGuestPayments),
      };
    }

    case SET_INVALID_PAYMENT: {
      const { isInvalidPayment } = action.payload;
      return {
        ...state,
        isInvalidPayment: isInvalidPayment,
      };
    }

    default:
      return state;
  }
};
