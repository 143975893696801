import React from 'react';
import { DropdownMenuContainer, ToggleArrow } from '../DropdownMenu';
import { ExpandableMenu } from '../DropdownMenu/ExpandableMenu';
import { IStepsIndicatorProps, PROGRESS, progress } from './shared';

interface IStepProps {
  index: number;
  total: number;
  title: string;
  progress: PROGRESS;
  selectedCallback?: (orderNumber: number) => void;
}

const progressColors: { [key in PROGRESS]: { bgColor: string; orderNumberColor: string; titleColor: string } } = {
  disabled: {
    bgColor: 'bg-white',
    orderNumberColor: 'text-gray-40',
    titleColor: 'text-gray-40',
  },
  active: {
    bgColor: 'bg-gray-10',
    orderNumberColor: 'text-primary-60',
    titleColor: 'text-gray-80',
  },
  completed: {
    bgColor: 'bg-white',
    orderNumberColor: 'text-primary-60',
    titleColor: 'text-gray-80',
  },
};

const Step: React.FC<IStepProps> = ({ index, total, title, progress, selectedCallback }) => {
  const { bgColor, orderNumberColor, titleColor } = progressColors[progress];

  return (
    <div className={`flex p-4 items-center ${bgColor}`} onClick={() => selectedCallback && selectedCallback(index + 1)}>
      <div className="flex flex-col flex-grow items-start">
        <div className={orderNumberColor}>
          STEP {index + 1} OF {total}
        </div>
        <div className={titleColor}>{title}</div>
      </div>
    </div>
  );
};

export const MobileStepsIndicator: React.FC<IStepsIndicatorProps & React.HTMLAttributes<HTMLElement>> = ({
  steps,
  currentStep,
  selectedCallback,
}) => {
  const currentStepIndex = currentStep - 1;
  return (
    <div className="sm:hidden">
      <ExpandableMenu
        controlNode={
          <div className="flex bg-white rounded-lg p-4 items-center">
            <div className="flex flex-col flex-grow items-start">
              <div className="text-primary-60">
                STEP {currentStep} OF {steps.length}
              </div>
              <div className="text-gray-80 text-left">{steps[currentStepIndex]}</div>
            </div>
            <ToggleArrow />
          </div>
        }
      >
        <DropdownMenuContainer className="divide-gray-20 divide-y">
          {steps.map((step, index) => (
            <Step
              key={index}
              index={index}
              total={steps.length}
              title={step}
              selectedCallback={selectedCallback}
              progress={progress(currentStepIndex, index)}
            />
          ))}
        </DropdownMenuContainer>
      </ExpandableMenu>
    </div>
  );
};
