import React from 'react';
import { Variant, Toast } from './Toast';
import { toast as reactToast } from 'react-hot-toast';
import { CheckCircle, ExclamationCircle } from '../../../icons';

export interface IAddToast {
  variant?: Variant;
  message: string | React.ReactNode;
  action?: {
    label: string | React.ReactNode;
    onClick: () => void;
  };
  icon?: React.ReactNode;
  duration?: number;
}

export const addToast = ({ variant, icon, message, action, duration = 8000 }: IAddToast): string =>
  reactToast(
    ({ id }) => (
      <Toast dismiss={() => reactToast.dismiss(id)} variant={variant} icon={icon} message={message} action={action} />
    ),
    {
      duration,
    },
  );

export const addSuccessToast = ({ message, icon }: { message: string; icon?: React.ReactNode }) => {
  addToast({
    variant: 'success',
    icon: icon ?? <CheckCircle />,
    message: message,
  });
};

export const addErrorToast = ({ message, icon }: { message: string; icon?: React.ReactNode }) => {
  addToast({
    variant: 'error',
    icon: icon ?? <ExclamationCircle />,
    message: message,
  });
};
