import React from 'react';
import AriaModal from 'react-aria-modal';
import { Button, Container, NonDismissableModal, DismissableModal } from 'ui';

export interface IGenericModal {
  isOpen?: boolean;
  close?: () => void;
  proceed?: () => void;
  modalHeader: string;
  modalDescription?: string;
  closeButtonLabel?: string;
  closeButtonVariant?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  proceedButtonLabel?: string;
  proceedButtonVariant?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  proceedButton?: React.ReactNode;
  dismissable?: boolean;
  textWrap?: boolean;
  dataCy?: string;
}

export const GenericModal: React.FC<IGenericModal> = ({
  isOpen,
  close,
  proceed,
  modalHeader,
  modalDescription,
  closeButtonLabel = 'Close',
  closeButtonVariant = 'tertiary',
  proceedButtonLabel = 'Proceed',
  proceedButtonVariant,
  proceedButton,
  dismissable,
  textWrap,
  dataCy,
}) => {
  return (
    <AriaModal
      mounted={isOpen}
      onExit={close}
      titleText="Generic Modal"
      initialFocus={proceed ? '#proceed' : '#close'}
      underlayColor="rgba(17,20,23,0.8)"
    >
      <div className="flex justify-center w-screen">
        <Container>
          <div
            data-cy={dataCy ? dataCy : 'generic-user-prompt-modal'}
            className="relative col-span-6 lg:col-span-8 sm:col-start-2 lg:col-start-3"
          >
            {dismissable ? (
              <DismissableModal
                header={modalHeader}
                description={modalDescription}
                buttons={
                  <>
                    {close && (
                      <Button id="close" variant={closeButtonVariant} label={closeButtonLabel} onClick={close} />
                    )}
                    {proceedButton}
                    {!proceedButton && proceed && (
                      <Button
                        id="proceed"
                        data-testid="proceed"
                        variant={proceedButtonVariant}
                        textWrap={textWrap}
                        onClick={proceed}
                        label={proceedButtonLabel}
                        data-cy="proceed-button"
                      />
                    )}
                  </>
                }
                closeButtonOnClick={close}
              />
            ) : (
              <NonDismissableModal
                header={modalHeader}
                description={modalDescription}
                buttons={
                  <>
                    {close && (
                      <Button id="close" variant={closeButtonVariant} label={closeButtonLabel} onClick={close} />
                    )}
                    {proceedButton}
                    {!proceedButton && proceed && (
                      <Button
                        id="proceed"
                        data-testid="proceed"
                        variant={proceedButtonVariant}
                        onClick={proceed}
                        label={proceedButtonLabel}
                        data-cy="proceed-button"
                        textWrap={textWrap}
                      />
                    )}
                  </>
                }
                closeButtonOnClick={close}
              />
            )}
          </div>
        </Container>
      </div>
    </AriaModal>
  );
};
