import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const DownTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
`;

const UpTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
`;

const LeftTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
`;

const RightTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
`;

const ControllerContainer = styled.div<{ padding: string }>`
  padding: ${(props) => props.padding};

  &:hover {
    & ~ .tooltip-container {
      opacity: 1;
      z-index: 10;
    }
  }
`;

enum POSITION {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export interface ITooltipProps {
  tooltip: string | React.ReactNode;
  controllerContainerPadding?: string;
}

export const Tooltip: React.FC<ITooltipProps> = ({ children, tooltip, controllerContainerPadding, ...otherProps }) => {
  const [position, setPosition] = useState(POSITION.TOP);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const updatePositionStyling = () => {
    const { left = 0, top = 0, width = 0 } = tooltipRef.current?.getBoundingClientRect() || {};

    if (top < 0) {
      setPosition(POSITION.BOTTOM);
    }

    if (left + width > window.innerWidth) {
      setPosition(POSITION.LEFT);
    }

    if (left < 0) {
      setPosition(POSITION.RIGHT);
    }
  };

  useEffect(() => {
    updatePositionStyling();
  }, []);

  const positionStylings = {
    [POSITION.TOP]: 'bottom-full left-1/2 -translate-x-1/2 flex-col',
    [POSITION.BOTTOM]: 'top-full left-1/2 -translate-x-1/2 flex-col-reverse',
    [POSITION.RIGHT]: 'left-full top-1/2 -translate-y-1/2 flex-row-reverse',
    [POSITION.LEFT]: 'right-full top-1/2 -translate-y-1/2 flex-row',
  };

  return (
    <div className="relative">
      <ControllerContainer padding={controllerContainerPadding ?? '0.25rem'} className="p-0">
        {children}
      </ControllerContainer>
      <div
        ref={tooltipRef}
        className={`tooltip-container pointer-events-none absolute opacity-0 flex items-center transform ${positionStylings[position]}`}
        {...otherProps}
      >
        <div className="bg-gray-90 p-3 rounded-lg body-small text-white">{tooltip}</div>
        {position === POSITION.TOP && <DownTriangle className="mx-auto -mt-1 border-t-color-gray-90 border-t-10" />}
        {position === POSITION.BOTTOM && <UpTriangle className="mx-auto -mb-1 border-b-color-gray-90 border-b-10" />}
        {position === POSITION.RIGHT && <LeftTriangle className="mx-auto -mr-1 border-r-color-gray-90 border-r-10" />}
        {position === POSITION.LEFT && <RightTriangle className="mx-auto -ml-1 border-l-color-gray-90 border-l-10" />}
      </div>
    </div>
  );
};
