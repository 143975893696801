import { ITagMatcher } from './TagMatchers/ITagMatcher';

type TagMatches<T> = Record<
  number,
  { params: T; length: number; matchOffset: number; transformer: ITagMatcher<T>['transformer'] }
>;

export const getTagMatches = <T,>(tagMatcher: ITagMatcher<T>, message: string): TagMatches<T> => {
  const matches = [];
  let newMatch: boolean | ReturnType<RegExp['exec']> = true;

  while (newMatch) {
    newMatch = tagMatcher.matcher.exec(message);
    newMatch && matches.push(newMatch);
  }

  return matches.reduce((a, c) => {
    if (c.index !== undefined) {
      const matchData = tagMatcher.getMatchData(c as string[] & { index: number });

      a[matchData.startIndex] = {
        params: matchData.transformationParams,
        length: matchData.length,
        matchOffset: matchData.matchOffset,
        transformer: tagMatcher.transformer,
      };
    }

    return a;
  }, {} as TagMatches<T>);
};
