import classNames from 'classnames';
import React from 'react';
import { TextLink } from '../../TextLink/TextLink';

export type Variant = 'informational' | 'error' | 'success' | 'warning' | 'neutral';
type Emphasis = 'low' | 'high';

const colorStyles = {
  low: {
    informational: {
      border: 'border border-informational-border',
      background: 'bg-informational-light',
      icon: 'text-primary-60',
      title: 'text-primary-60',
      message: 'text-gray-70',
      action: 'text-primary-60',
    },
    error: {
      border: 'border border-error-border',
      background: 'bg-error-light',
      icon: 'text-error',
      title: 'text-error',
      message: 'text-gray-70',
      action: 'text-primary-60',
    },
    success: {
      border: 'border border-success-border',
      background: 'bg-success-light',
      icon: 'text-success',
      title: 'text-success',
      message: 'text-gray-70',
      action: 'text-primary-60',
    },
    warning: {
      border: 'border border-warning-border',
      background: 'bg-warning-light',
      icon: 'text-warning',
      title: 'text-gray-90',
      message: 'text-gray-70',
      action: 'text-primary-60',
    },
    neutral: {
      border: 'border border-gray-30',
      background: 'bg-gray-20',
      icon: 'text-gray-60',
      title: 'text-gray-90',
      message: 'text-gray-70',
      action: 'text-primary-60',
    },
  },
  high: {
    informational: {
      border: 'border border-informational',
      background: 'bg-informational',
      icon: 'text-white',
      title: 'text-white',
      message: 'text-white',
      action: 'text-white',
    },
    error: {
      border: 'border border-error',
      background: 'bg-error',
      icon: 'text-white',
      title: 'text-white',
      message: 'text-white',
      action: 'text-white',
    },
    success: {
      border: 'border border-success',
      background: 'bg-success',
      icon: 'text-white',
      title: 'text-white',
      message: 'text-white',
      action: 'text-white',
    },
    warning: {
      border: 'border border-warning',
      background: 'bg-warning',
      icon: 'text-gray-100',
      title: 'text-gray-90',
      message: 'text-gray-70',
      action: 'text-gray-100',
    },
    neutral: {
      border: 'border border-gray-80',
      background: 'bg-gray-80',
      icon: 'text-white',
      title: 'text-white',
      message: 'text-white',
      action: 'text-white',
    },
  },
};

export interface IInlineNotification {
  title?: string | React.ReactNode;
  message: string | React.ReactNode;
  action?: {
    label: string | React.ReactNode;
    onClick: () => void;
  };
  icon?: React.ReactNode;
  variant?: Variant;
  emphasis?: Emphasis;
}

export const InlineNotification: React.FC<IInlineNotification> = ({
  title,
  message,
  action,
  icon,
  variant = 'informational',
  emphasis = 'low',
}) => {
  const colors = colorStyles[emphasis][variant];

  return (
    <div
      data-cy="inline-notification-info-box"
      className={classNames('flex rounded-lg px-4 py-3 min-w-64 space-x-3', colors.background, colors.border)}
    >
      {icon && <div className={classNames('flex-shrink-0 w-6 h-6', colors.icon)}>{icon}</div>}
      <div className="flex flex-col w-full space-y-2 self-center">
        <div className="flex flex-col w-full space-y-0.5">
          {title && <div className={classNames('heading-6-semibold', colors.title)}>{title}</div>}
          <div className={classNames('body-small whitespace-pre-wrap', colors.message)}>{message}</div>
        </div>
        {action && (
          <div className="w-max">
            <TextLink
              className={classNames(colors.action, { underline: emphasis === 'high' })}
              variant="unstyled"
              onClick={action?.onClick}
            >
              {action?.label}
            </TextLink>
          </div>
        )}
      </div>
    </div>
  );
};
