import React, { useState, useContext, useEffect } from 'react';
import { InnerDropdownMenuProps, DropdownMenuOpenState } from './shared';

export const ExpandableMenu: React.FC<InnerDropdownMenuProps> = ({
  controlNode,
  renderControlNode,
  closeOnChangeKey,
  children,
  disabled,
  onMenuClose,
  onMenuOpen,
  ...otherProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isParentOpen = useContext(DropdownMenuOpenState);

  useEffect(() => {
    if (!isOpen) {
      onMenuClose?.();
    } else {
      onMenuOpen?.();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !isParentOpen) {
      setIsOpen(false);
    }
  }, [isOpen, setIsOpen, isParentOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [closeOnChangeKey]);

  const hideOnOpen = isOpen ? '' : 'hidden';

  return (
    <DropdownMenuOpenState.Provider value={isOpen}>
      <div {...otherProps}>
        {controlNode && (
          <button
            type="button"
            className="w-full h-full focus:outline-none disabled:cursor-not-allowed"
            disabled={disabled}
            onClick={() => setIsOpen(!isOpen)}
          >
            {controlNode}
          </button>
        )}
        <>{renderControlNode?.({ toggleMenu: () => setIsOpen(!isOpen), isOpen })}</>
        <div className={`w-full ${hideOnOpen}`}>{children}</div>
      </div>
    </DropdownMenuOpenState.Provider>
  );
};
