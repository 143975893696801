import {
  IProjectActions,
  SET_PROJECT,
  SET_PROJECTS,
  SET_OWN_PROJECTS,
  SET_CURRENT_ACCOUNT_PROJECT_INFO,
  REMOVE_PROJECT_MEMBER,
  SET_APPOINTMENTS_INFO,
} from './actions';
import { Reducer } from 'redux';
import { IAppointmentStore, ICurrentAccountProjectInfo, IProject, ROLE } from 'utils/constants';
import { isEqual } from 'lodash';

export interface IReducer {
  ownProjects?: string[] | null;
  projects?: Record<string, IProject | null> | null;
  accountInfo?: Record<string, ICurrentAccountProjectInfo | null> | null;
  appointments?: Record<string, IAppointmentStore>;
}

export const initialState: IReducer = {};

export const reducer: Reducer<IReducer, IProjectActions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT: {
      const { id, project: newProject, isLoading } = action.payload;

      const oldProject = state.projects?.[id];

      if (isEqual(oldProject, newProject)) return state;

      const project = newProject || oldProject || null;

      return {
        ...state,
        projects: { ...state.projects, [id]: { ...project, isLoading } as IProject },
      };
    }

    case SET_CURRENT_ACCOUNT_PROJECT_INFO: {
      const { id, info: newInfo } = action.payload;

      const oldInfo = state.accountInfo?.[id];

      if (isEqual(oldInfo, newInfo)) return state;

      const info = newInfo ? { ...oldInfo, ...newInfo } : oldInfo || null;

      return {
        ...state,
        accountInfo: { ...state.accountInfo, [id]: info },
      };
    }

    case SET_PROJECTS: {
      const { projects } = action.payload;
      return {
        ...state,
        projects:
          projects === null && state.projects
            ? state.projects
            : projects && Object.fromEntries(projects.map((c) => [c.uuid, c])),
      };
    }

    case SET_OWN_PROJECTS: {
      const { ids } = action.payload;
      return {
        ...state,
        ownProjects: ids,
      };
    }

    case REMOVE_PROJECT_MEMBER: {
      const { projectId, projectMemberId } = action.payload;
      const project = state.projects?.[projectId];
      if (!project) return state;

      const projMemberToCheck = project.members?.find((member) => member.uuid === projectMemberId);
      const isProjMemberToCheckBuilder =
        projMemberToCheck &&
        projMemberToCheck.roles?.some((role) =>
          [ROLE.LICENSED_BUILDER, ROLE.LICENSED_SPECIALIST_BUILDER].includes(role.role.key),
        );
      const filteredMembers = project.members?.filter((member) => {
        if (member.uuid === projectMemberId) return false;
        if (isProjMemberToCheckBuilder) {
          if (
            member.account.company?.uen === projMemberToCheck.account.company?.uen &&
            member.roles?.some((role) =>
              [ROLE.LICENSED_BUILDER, ROLE.LICENSED_SPECIALIST_BUILDER].includes(role.role.key),
            )
          ) {
            return false;
          }
        }
        return true;
      });

      const filteredPendingMembers = project.membersPending?.filter((pendingMember) => {
        if (pendingMember.uuid === projectMemberId) return false;
        if (isProjMemberToCheckBuilder) {
          if (
            pendingMember.uen === projMemberToCheck.account.company?.uen &&
            pendingMember.roles?.some((role) =>
              [ROLE.LICENSED_BUILDER, ROLE.LICENSED_SPECIALIST_BUILDER].includes(role.role.key),
            )
          ) {
            return false;
          }
        }
        return true;
      });

      return {
        ...state,
        projects: {
          ...state.projects,
          [projectId]: {
            ...state.projects?.[projectId],
            members: filteredMembers,
            membersPending: filteredPendingMembers,
          } as IProject,
        },
      };
    }

    case SET_APPOINTMENTS_INFO: {
      const { id, appointmentRequests } = action.payload;
      return {
        ...state,
        appointments: { [id]: { appointmentRequests } },
      };
    }

    default:
      return state;
  }
};
