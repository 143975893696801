import {
  INotificationActions,
  SET_NOTIFICATIONS,
  ADD_NOTIFICATIONS,
  SET_NOTIFICATION,
  SET_PAGINATION,
  SET_HAS_UNREAD,
  SET_IS_ERRORED,
} from './actions';
import { Reducer } from 'redux';
import { INotification } from 'utils/constants';

export interface IPagination {
  isLoading: boolean;
  nextPage: number | null;
}

export interface IReducer {
  pagination: IPagination;
  notifications: Record<string, INotification>;
  hasUnread: boolean;
  isErrored: boolean;
}

export const initialState: IReducer = {
  notifications: {},
  pagination: {
    isLoading: false,
    nextPage: 0,
  },
  hasUnread: false,
  isErrored: false,
};

export const reducer: Reducer<IReducer, INotificationActions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      const { notifications } = action.payload;
      return {
        ...state,
        notifications: notifications && Object.fromEntries(notifications.map((c) => [c.uuid, c])),
      };
    }

    case ADD_NOTIFICATIONS: {
      const { notifications } = action.payload;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...(notifications ? Object.fromEntries(notifications.map((c) => [c.uuid, c])) : {}),
        },
      };
    }

    case SET_NOTIFICATION: {
      const { notification } = action.payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [notification.uuid]: notification,
        },
      };
    }

    case SET_PAGINATION: {
      const { pagination } = action.payload;
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...pagination,
        },
      };
    }

    case SET_HAS_UNREAD: {
      const { hasUnread } = action.payload;
      return {
        ...state,
        hasUnread,
      };
    }

    case SET_IS_ERRORED: {
      const { isErrored } = action.payload;

      return {
        ...state,
        isErrored,
      };
    }

    default:
      return state;
  }
};
