import { IAccount, ICompany } from 'utils/constants';
import { IStore } from '../rootReducer';

export const getIsLoggedIn = (store: IStore): boolean => store.auth.account !== null;

export const getAccountId = (store: IStore): string | undefined => store.auth.account?.uuid;

export const getAccountName = (store: IStore): string | undefined => store.auth.account?.person?.name;

export const getAccountEmail = (store: IStore): string | undefined => store.auth.account?.emailAddress;

export const getAccountMaskedId = (store: IStore): string | undefined => store.auth.account?.person?.maskedId;

export const getAccountCompany = (store: IStore): ICompany | undefined => store.auth.account?.company;

export const getAccount = (store: IStore): IAccount | null => store.auth.account;

export const getLicences = (store: IStore) => {
  const isRegisteredArchitect = store.auth.account?.person?.isRegisteredArchitect;
  const isProfessionalEngineer = store.auth.account?.person?.isProfessionalEngineer;
  const professionalLicences = store.auth.account?.person?.professionalLicences;
  const isQP = Boolean(isProfessionalEngineer || isRegisteredArchitect);
  return {
    isQP,
    isProfessionalEngineer,
    isRegisteredArchitect,
    professionalLicences,
  };
};

export const getIsLoginModalOpen = (store: IStore): boolean => store.auth.isLoginModalOpen;

export const getIsSessionEnding = (store: IStore): boolean => store.auth.isSessionEnding;

export const getIsSessionExpired = (store: IStore): boolean | null => store.auth.isSessionExpired;

export const getIsConcurrentSessionDetected = (store: IStore): boolean => store.auth.isConcurrentSessionDetected;

export const getIsBusinessAccount = (store: IStore): boolean => Boolean(store.auth.account?.isBusiness);

export const getLoginType = (store: IStore): string => (store.auth.account?.isBusiness ? 'corppass' : 'singpass');
