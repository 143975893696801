import React from 'react';
import { ITagMatcher } from './ITagMatcher';

type LinkMatchType = { label: string; url: string };

export const LinkTagMatcher: ITagMatcher<LinkMatchType> = {
  matcher: /\[(.+?)\]\((.+?)\)/g,
  getMatchData: (results) => ({
    transformationParams: { label: results[1], url: results[2] },
    startIndex: results.index,
    length: results[0].length,
    // This is the length of [ at the start of the match
    matchOffset: 1,
  }),
  transformer: ({ key, params, parseContent }) => (
    <a className="underline" key={key} href={params.url} rel="noreferrer noopener" target="_blank">
      {parseContent(params.label)}
    </a>
  ),
};
