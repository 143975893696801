import React from 'react';
import { ModalContent } from './ModalContent';
import { IModal } from './interfaces';

export const DismissableModal: React.FC<IModal> = (props) => (
  <div className="my-15 md:my-30">
    <div className="rounded-lg bg-white shadow-lg">
      <ModalContent {...props} withCloseButton={true} />
    </div>
  </div>
);
