import React from 'react';
import moment from 'moment';

interface ICalendarDay {
  disabled: boolean;
  withinCurrentMonth: boolean;
  inSelectionRange: boolean | null;
  selectionStart: boolean | null;
  selectionEnd: boolean | null;
  date: Date;
  setDate: (date: Date) => void;
}

export const CalendarDay: React.FC<ICalendarDay> = ({
  disabled,
  withinCurrentMonth,
  inSelectionRange,
  selectionStart,
  selectionEnd,
  date,
  setDate,
}) => {
  const isCurrentDate = moment(date).isSame(new Date(), 'day');

  return (
    <div
      className={`${!disabled && withinCurrentMonth ? 'text-gray-100' : 'text-gray-30'} ${
        inSelectionRange || selectionStart || selectionEnd ? 'bg-primary-10' : ''
      } ${selectionStart ? 'rounded-l-lg' : ''} ${selectionEnd ? 'rounded-r-lg' : ''}`}
    >
      <button
        className={`focus:z-10 focus:outline-none focus:border-primary-60 border-transparent border-2 ring-inset focus:ring-2 ring-0 rounded-lg ${
          disabled ? 'cursor-not-allowed' : 'hover:bg-gray-10'
        } ${inSelectionRange ? 'hover:bg-primary-50 hover:text-gray-80 text-primary-60' : ''} ${
          selectionStart || selectionEnd
            ? 'bg-primary-60 hover:bg-primary-50 ring-white text-white hover:text-gray-80'
            : 'ring-transparent'
        } label-large`}
        onClick={() => setDate(date)}
        tabIndex={disabled ? -1 : 0}
        disabled={disabled}
        type="button"
      >
        <div className="relative flex items-center justify-center py-2 px-0.5 rounded-lg">
          <div className="flex justify-center w-8">
            {date.getDate()}
            {isCurrentDate && <div className="absolute bottom-1.5 w-1 h-1 rounded-full bg-primary-60" />}
          </div>
        </div>
      </button>
    </div>
  );
};
