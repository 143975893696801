import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
export interface IPaginatorProps<T> {
  items: T[];
  itemsPerPage?: number;
  render: (filteredItems: T[], paginationController: JSX.Element) => React.ReactElement;
}

interface INavigationButtonProps {
  disabled?: boolean;
}

const NavigationButton: React.FC<INavigationButtonProps & React.HTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...otherProps
}) => (
  <button
    {...otherProps}
    className="w-9 h-9 focus:outline-none rounded-full disabled:cursor-not-allowed disabled:text-gray-40 text-gray-70 bg-white hover:shadow-sm hover:bg-gray-10 focus:bg-gray-10"
  >
    <div className={'flex justify-center items-center'}>{children}</div>
  </button>
);

export const Paginator = <T extends { key: string }>({
  items,
  itemsPerPage = 10,
  render,
}: IPaginatorProps<T>): JSX.Element => {
  const [pageIndex, setPageIndex] = useState(0);
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const navigateForwards = () => {
    const newPage = pageIndex + 1;
    setPageIndex(newPage >= totalPages ? totalPages - 1 : newPage);
  };

  const navigateBackwards = () => {
    const newPage = pageIndex - 1;
    setPageIndex(newPage < 0 ? 0 : newPage);
  };

  const startIdx = itemsPerPage * pageIndex;
  const endIdx = Math.min(itemsPerPage * (pageIndex + 1), items.length);
  const filteredItems = items.slice(startIdx, endIdx);

  const itemKeysRef = useRef(items.map(({ key }) => key));

  useEffect(() => {
    const newKeys = items.map(({ key }) => key);
    if (
      newKeys.length !== itemKeysRef.current.length ||
      newKeys.some((newKey, i) => newKey !== itemKeysRef.current[i])
    ) {
      itemKeysRef.current = newKeys;
      setPageIndex(0);
    }
  }, [items]);

  useEffect(() => {
    setPageIndex(0);
  }, [itemsPerPage]);

  return (
    <div className="w-full">
      {render(
        filteredItems,
        <div className="flex space-x-4 justify-between items-center px-5 py-3 border-t border-gray-20 opacity-[0.6]">
          {
            <>
              <div className="text-gray-70 link">
                {items.length === 0 ? 0 : startIdx + 1}-{endIdx} of {items.length} results
              </div>

              <div className="flex space-x-2">
                <NavigationButton
                  data-testid="paginate-navigate-backwards"
                  onClick={navigateBackwards}
                  disabled={pageIndex === 0}
                >
                  <ChevronLeftIcon className="flex-shrink-0 w-5 h-5" />
                </NavigationButton>

                <NavigationButton
                  data-testid="paginate-navigate-forwards"
                  onClick={navigateForwards}
                  disabled={pageIndex === totalPages - 1 || items.length === 0}
                >
                  <ChevronRightIcon className="flex-shrink-0 w-5 h-5" />
                </NavigationButton>
              </div>
            </>
          }
        </div>,
      )}
    </div>
  );
};
