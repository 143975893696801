import React from 'react';
import { ICheckboxOptions, BaseCheckbox, CHECKBOX_STATE } from '../BaseCheckbox';

interface ICheckbox<T> {
  item: T;
  selectedItems: T[];
  setSelectedItems: (newItems: T[]) => void;
}

export const Checkbox = <T,>({
  item,
  selectedItems,
  setSelectedItems,
  ...otherProps
}: ICheckbox<T> & ICheckboxOptions): JSX.Element => {
  const checkboxState = selectedItems.includes(item) ? CHECKBOX_STATE.CHECKED : CHECKBOX_STATE.UNCHECKED;

  const handleChange = () => {
    if (selectedItems.includes(item)) {
      const idxToRemove = selectedItems.indexOf(item);
      setSelectedItems([
        ...selectedItems.slice(0, idxToRemove),
        ...selectedItems.slice(idxToRemove + 1, selectedItems.length),
      ]);
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  return <BaseCheckbox type="checkbox" checkboxState={checkboxState} handleChange={handleChange} {...otherProps} />;
};
