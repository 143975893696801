/* istanbul ignore file */

import { axiosInstance } from './axiosInstance';
import { Dispatch } from 'redux';
import { addBroadcastMessages } from 'redux/broadcastMessages/actions';
import { IBroadcastMessage } from 'utils/constants';

type RawBroadcastMessage = IBroadcastMessage & {
  startTime: string;
  endTime: string;
};

const processBroadcastMessage = (rawBroadcastMessage: RawBroadcastMessage): IBroadcastMessage => ({
  ...rawBroadcastMessage,
  startTime: new Date(rawBroadcastMessage.startTime),
  endTime: new Date(rawBroadcastMessage.endTime),
});

interface IBroadcastMessagesResponse {
  broadcastMessages: RawBroadcastMessage[];
}

export const fetchBroadcastMessages = async (dispatch: Dispatch): Promise<void> => {
  return axiosInstance
    .get<IBroadcastMessagesResponse>('/broadcast-message', { params: { later_than: new Date() } })
    .then(({ data }) => {
      dispatch(addBroadcastMessages(data.broadcastMessages.map(processBroadcastMessage)));
    })
    .catch();
};
