import React, { useCallback } from 'react';

export interface IDatePickerGroup {
  closeDatePicker: () => void;
}

export const DatePickerGroup: React.FC<IDatePickerGroup> = ({ closeDatePicker, children }) => {
  const handleBlur = useCallback(
    (e: React.FocusEvent) => {
      if (!e.currentTarget.contains(e.relatedTarget as Node | null)) {
        closeDatePicker();
      }
    },
    [closeDatePicker],
  );

  return (
    <div className="focus:outline-none flex w-full" tabIndex={0} onBlur={handleBlur}>
      {children}
    </div>
  );
};
