import React from 'react';
import { CheckIcon } from '@heroicons/react/solid';

export const SelectedTick: React.FC = () => (
  <div
    className="flex items-center justify-center flex-shrink-0 w-5 h-5 bg-primary-60 rounded"
    data-testid="selected-tick"
  >
    <CheckIcon className="text-white flex-shrink-0 w-3.5 h-3.5" />
  </div>
);

export const ReadOnlySelectedTick: React.FC = () => (
  <div
    className="flex items-center justify-center flex-shrink-0 w-5 h-5 bg-gray-60 rounded"
    data-testid="readonly-selected-tick"
  >
    <CheckIcon className="text-white flex-shrink-0 w-3.5 h-3.5" />
  </div>
);

export const UnselectedTick: React.FC = () => (
  <div className="flex-shrink-0 w-5 h-5 bg-white rounded border border-gray-30" data-testid="unselected-tick" />
);
