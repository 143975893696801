import { ActionCreator } from 'redux';
import { ISectionLock, ISubmission } from 'utils/constants';

export const SET_SUBMISSION = 'SET_SUBMISSION';

interface ISetSubmission {
  type: typeof SET_SUBMISSION;
  payload: { id: string; submission: ISubmission };
}

export const setSubmission: ActionCreator<ISetSubmission> = (id: string, submission: ISubmission) => ({
  type: SET_SUBMISSION,
  payload: { id, submission },
});

export const ADD_SUBMISSIONS = 'ADD_SUBMISSIONS';

interface IAddSubmissions {
  type: typeof ADD_SUBMISSIONS;
  payload: { submissions: ISubmission[] };
}

export const addSubmissions: ActionCreator<IAddSubmissions> = (submissions: ISubmission[]) => ({
  type: ADD_SUBMISSIONS,
  payload: { submissions },
});

export const SET_SUBMISSION_SECTION_LOCKS = 'SET_CURRENT_SUBMISSION_SECTION_LOCKS';

interface ISetSubmissionSectionLocks {
  type: typeof SET_SUBMISSION_SECTION_LOCKS;
  payload: { id: string; sectionLocks: ISectionLock[] };
}

export const setSubmissionSectionLocks: ActionCreator<ISetSubmissionSectionLocks> = (
  id: string,
  sectionLocks: ISectionLock[],
) => ({
  type: SET_SUBMISSION_SECTION_LOCKS,
  payload: { id, sectionLocks },
});

export const REMOVE_SUBMISSION = 'REMOVE_SUBMISSION';
interface IRemoveSubmission {
  type: typeof REMOVE_SUBMISSION;
  payload: { submissionId: string };
}

export const removeSubmission: ActionCreator<IRemoveSubmission> = (submissionId: string) => ({
  type: REMOVE_SUBMISSION,
  payload: { submissionId },
});

export const SET_SUBMISSION_SERVER_SIDE_VALIDATION_ERRORS = 'SET_SUBMISSION_SERVER_SIDE_VALIDATION_ERRORS';

interface ISetSubmissionServerSideValidationErrors {
  type: typeof SET_SUBMISSION_SERVER_SIDE_VALIDATION_ERRORS;
  payload: { submissionId: string; errors: Record<string, string> };
}

export const setSubmissionServerSideValidationErrors: ActionCreator<ISetSubmissionServerSideValidationErrors> = (
  submissionId: string,
  errors: Record<string, string>,
) => ({
  type: SET_SUBMISSION_SERVER_SIDE_VALIDATION_ERRORS,
  payload: {
    submissionId,
    errors,
  },
});

export type ISubmissionActions =
  | ISetSubmission
  | IAddSubmissions
  | ISetSubmissionSectionLocks
  | IRemoveSubmission
  | ISetSubmissionServerSideValidationErrors;
