import React, { useContext } from 'react';
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import { DropdownMenuOpenState } from '../DropdownMenu';

interface IHamburgerMenu {
  toggleMenu: () => void;
}

export const HamburgerButton: React.FC<IHamburgerMenu> = ({ toggleMenu }) => {
  const isOpen = useContext(DropdownMenuOpenState);

  return (
    <button
      className="focus:outline-none focus-visible:bg-gray-10 rounded-full flex sm:hidden items-center p-2.5"
      onClick={toggleMenu}
    >
      {isOpen ? <XIcon className="flex-shrink-0 w-6 h-6" /> : <MenuAlt2Icon className="flex-shrink-0 w-6 h-6" />}
    </button>
  );
};
