/* istanbul ignore file */
import { configureStore, isPlain } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';
import { MonetaryAmount } from 'utils/MonetaryAmount';

const { REACT_APP_USE_REDUX_DEVTOOLS = '' } = process.env;
const devTools = REACT_APP_USE_REDUX_DEVTOOLS.toLowerCase() === 'true';

/**
 * Interim workaround for allowing non-serializable objects
 * TODO: serialize in actions and re-hydrate in selectors
 * @see {@link https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data}
 */
const isSerializable = (value: unknown) => isPlain(value) || value instanceof Date || value instanceof MonetaryAmount;

export const store = configureStore({
  reducer: rootReducer,
  devTools,
  middleware: (defaults) =>
    defaults({
      serializableCheck: {
        isSerializable,
      },
      immutableCheck: false,
    }),
});
