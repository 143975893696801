import React, { useContext } from 'react';
import { RouteContext } from '../../../shared';
import { DropdownMenuButton } from '../../../../DropdownMenu';
import { LinkProps, ExternalLinkProps, InternalLinkProps, isExternalLink } from './shared';

export const NestedLink: React.FC<LinkProps> = (props) => {
  return (
    <div className="text-gray-80 whitespace-nowrap">
      {isExternalLink(props) ? <ExternalLink {...props} /> : <InternalLink {...props} />}
    </div>
  );
};

const ExternalLink: React.FC<ExternalLinkProps> = ({ href, children }) => (
  <a href={href}>
    <DropdownMenuButton label={<div className="pl-3 label-large">{children}</div>} />
  </a>
);

const InternalLink: React.FC<InternalLinkProps> = ({ path, children }) => {
  const { redirect } = useContext(RouteContext);

  return (
    <DropdownMenuButton
      label={<div className="pl-3 label-large">{children}</div>}
      onClick={(e: React.SyntheticEvent) => {
        e.preventDefault();
        redirect(path);
      }}
    />
  );
};
