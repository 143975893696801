import React from 'react';
import { ModalContent } from './ModalContent';
import { IModal } from './interfaces';

export const NonDismissableModal: React.FC<IModal> = (props) => (
  <div className="my-30">
    <div className="sm:rounded-lg bg-white sm:shadow-lg">
      <ModalContent {...props} />
    </div>
  </div>
);
