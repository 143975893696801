import React from 'react';

export interface IGridCard {
  header: React.ReactNode;
  description: React.ReactNode;
  dataItems?: {
    icon?: React.ReactNode;
    label: React.ReactNode;
  }[];
  cardTag?: React.ReactNode;
  favorite?: {
    node: React.ReactNode;
    onClick: () => void;
  };
  action?: {
    node: React.ReactNode;
    onClick: () => void;
  };
}

export const GridCard: React.FC<IGridCard> = ({
  header,
  description,
  dataItems,
  cardTag,
  favorite,
  action,
  ...otherProps
}) => (
  <div className="h-full" {...otherProps}>
    <div
      className="flex flex-col h-full justify-between space-y-6 bg-white rounded-lg shadow-sm p-6 transition-shadow duration-300 cursor-pointer hover:shadow-md focus:outline-none border focus:border-primary-60 border-transparent"
      onClick={action?.onClick}
      tabIndex={-1}
      role={action ? 'button' : undefined}
    >
      <div className="flex flex-col space-y-6">
        <div className="flex flex-col space-y-4">
          <div className="flex space-x-2 justify-between items-center">
            <div className="text-primary-60 heading-6-semibold">{header}</div>
            {favorite && (
              <button
                className="focus:outline-none focus-visible:bg-gray-10 rounded-full"
                onClick={(e) => {
                  e.stopPropagation();
                  favorite.onClick();
                }}
              >
                <div className="flex-shrink-0 w-5 h-5 text-gray-50">{favorite.node}</div>
              </button>
            )}
          </div>
          <div className="text-gray-90 body-small max-h-16 line-clamp-3">{description}</div>
        </div>
        {dataItems && (
          <div className="flex flex-col space-y-6">
            {dataItems && (
              <div className="flex flex-col space-y-3">
                {dataItems?.map(({ icon, label }, i) => (
                  <div key={i} className="label-regular text-gray-60">
                    <div className="flex space-x-2 items-start">
                      {icon && <div className="flex-shrink-0 w-4 h-4">{icon}</div>}
                      <div className="line-clamp-2">{label}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {(cardTag || action) && (
        <div className="flex space-x-2 justify-between items-center">
          {cardTag}
          {action && (
            <button
              className="focus:outline-none focus-visible:bg-gray-10 rounded-full p-1 ml-auto"
              onClick={(e) => {
                e.stopPropagation();
                action.onClick();
              }}
            >
              <div className="flex-shrink-0 w-5 h-5 text-gray-70">{action.node}</div>
            </button>
          )}
        </div>
      )}
    </div>
  </div>
);
