import React from 'react';
import { ITableCell } from './interfaces';

export const TableHeader: React.FC<ITableCell & React.HTMLAttributes<HTMLTableHeaderCellElement>> = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <th
      className={`px-5 py-0 text-gray-60 over-line sm:border-b sm:border-gray-10 sm:p-5 align-top sm:last:text-right ${className}`}
      {...otherProps}
    >
      {children}
    </th>
  );
};
