import React from 'react';
import { DropdownMenuContainer } from '../../../../DropdownMenu';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { DropdownMenuTabProps } from './shared';

export const OverlayTab: React.FC<DropdownMenuTabProps> = ({ label, children }) => (
  <div className="flex relative h-full items-center border-b-2 border-transparent group-hover:border-primary-60">
    <div className={`button-regular px-2 py-0.5 group-hover:text-primary-60`}>
      <div className="flex items-center justify-start space-x-2">
        <span>{label}</span>
        <ChevronDownIcon className="flex-shrink-0 w-5 h-5" />
      </div>
    </div>
    <div className="absolute hidden group-focus-within:block group-hover:block top-full">
      <DropdownMenuContainer>{children}</DropdownMenuContainer>
    </div>
  </div>
);
