import {
  ADD_ATTACHMENT,
  IAttachmentActions,
  REMOVE_ATTACHMENT,
  REMOVE_MISSING_ATTACHMENTS,
  SET_APPROVED_ATTACHMENTS,
  SET_ATTACHMENTS,
  SET_REMOVED_ATTACHMENT,
  UPDATE_ATTACHMENT,
} from './actions';
import { Reducer } from 'redux';
import { isEqual } from 'lodash';
import { IFileInfo } from 'utils/interfaces/IFileInfo';

export interface IReducer {
  attachments: Record<string, IFileInfo[]>;
  approvedAttachments: Record<string, IFileInfo[]>;
  removedAttachmentMetadata: { key: string; isRemoveAttachmentModalOpen: boolean };
}

export const initialState: IReducer = {
  attachments: {},
  approvedAttachments: {},
  removedAttachmentMetadata: { key: '', isRemoveAttachmentModalOpen: false },
};

export const reducer: Reducer<IReducer, IAttachmentActions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_ATTACHMENTS: {
      const { submissionId, attachments } = action.payload;

      if (isEqual(attachments, state.attachments[submissionId])) return state;

      return {
        ...state,
        attachments: { ...state.attachments, [submissionId]: attachments },
      };
    }

    case ADD_ATTACHMENT: {
      const { submissionId, attachment } = action.payload;

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [submissionId]: [...state.attachments[submissionId].filter((att) => att.key !== attachment.key), attachment],
        },
      };
    }

    case UPDATE_ATTACHMENT: {
      const { submissionId, key, updates } = action.payload;

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [submissionId]: state.attachments[submissionId]?.map((attachment) =>
            attachment.key === key ? { ...attachment, ...updates } : attachment,
          ),
        },
      };
    }

    case REMOVE_ATTACHMENT: {
      const { submissionId, key } = action.payload;

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [submissionId]: state.attachments[submissionId].filter((attachment) => attachment.key !== key),
        },
      };
    }

    case SET_APPROVED_ATTACHMENTS: {
      const { submissionId, attachments } = action.payload;

      if (isEqual(attachments, state.approvedAttachments[submissionId])) return state;

      return {
        ...state,
        approvedAttachments: { ...state.approvedAttachments, [submissionId]: attachments },
      };
    }

    case REMOVE_MISSING_ATTACHMENTS: {
      const { submissionId, keys } = action.payload;

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [submissionId]: state.attachments[submissionId]?.filter((attachment) => keys.includes(attachment.key)),
        },
      };
    }

    case SET_REMOVED_ATTACHMENT: {
      const { isRemoveAttachmentModalOpen, key } = action.payload;

      return {
        ...state,
        attachments: {
          ...state.attachments,
        },
        removedAttachmentMetadata: { key, isRemoveAttachmentModalOpen },
      };
    }

    default:
      return state;
  }
};
