import React from 'react';
import { LogoContainer } from './LogoContainer';
import { MenuContainer } from './MenuContainer';
import { Link, NestedLink } from './NavigationComponents/Link';
import { DropdownMenuTab } from './NavigationComponents/DropdownMenuTab';
import { RouteContext, IRouteContext } from '../shared';

interface HeaderProps {
  logo: React.ReactNode;
}

export type IHeader = HeaderProps & React.HTMLAttributes<HTMLElement>;

const HeaderComponent: React.FC<IHeader & IRouteContext> = ({
  className,
  path,
  isSelected,
  redirect,
  ...otherProps
}) => (
  <RouteContext.Provider value={{ path, isSelected, redirect }}>
    <nav className={className} key="header">
      <DesktopHeader {...otherProps} />
      <MobileHeader {...otherProps} />
    </nav>
  </RouteContext.Provider>
);

const DesktopHeader: React.FC<IHeader> = ({ children, logo }) => (
  <header className="hidden sm:block w-full">
    <div className="bg-white shadow-lg">
      <div className="relative mx-auto px-8">
        <div className="flex items-center justify-between h-18">
          <LogoContainer>{logo}</LogoContainer>
          {children}
        </div>
      </div>
    </div>
  </header>
);

const MobileHeader: React.FC<IHeader> = ({ children, logo }) => (
  <header className="block sm:hidden w-full">
    <div className="bg-white shadow-lg">
      <div className="relative mx-auto pl-5 pr-2.5 py-3 h-18">
        <div className="flex items-center justify-between">
          <LogoContainer>{logo}</LogoContainer>
          {children}
        </div>
      </div>
    </div>
  </header>
);

export const Header = Object.assign(HeaderComponent, {
  MenuContainer,
  Link,
  NestedLink,
  DropdownMenuTab,
});
