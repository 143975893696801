import React from 'react';
import { DesktopStepsIndicator } from './DesktopStepsIndicator';
import { MobileStepsIndicator } from './MobileStepsIndicator';
import { IStepsIndicatorProps } from './shared';

export const StepsIndicator: React.FC<IStepsIndicatorProps> = ({ ...props }) => {
  return (
    <div data-testid="steps-indicator" className={props.className}>
      <DesktopStepsIndicator {...props} />
      <MobileStepsIndicator {...props} />
    </div>
  );
};
