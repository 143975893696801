import { IFileInfo } from 'utils/interfaces/IFileInfo';
import { ActionCreator } from 'redux';

export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';

interface ISetAttachments {
  type: typeof SET_ATTACHMENTS;
  payload: { submissionId: string; attachments: IFileInfo[] };
}

export const setAttachments: ActionCreator<ISetAttachments> = (submissionId: string, attachments: IFileInfo[]) => ({
  type: SET_ATTACHMENTS,
  payload: { submissionId, attachments },
});

export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';

interface IAddAttachment {
  type: typeof ADD_ATTACHMENT;
  payload: { submissionId: string; attachment: IFileInfo };
}

export const addAttachment: ActionCreator<IAddAttachment> = (submissionId: string, attachment: IFileInfo) => ({
  type: ADD_ATTACHMENT,
  payload: { submissionId, attachment },
});

export const UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT';

interface IUpdateAttachment {
  type: typeof UPDATE_ATTACHMENT;
  payload: { submissionId: string; key: string; updates: Partial<IFileInfo> };
}

export const updateAttachment: ActionCreator<IUpdateAttachment> = (
  submissionId: string,
  key: string,
  updates: Partial<IFileInfo>,
) => ({
  type: UPDATE_ATTACHMENT,
  payload: { submissionId, key, updates },
});

export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';

interface IRemoveAttachment {
  type: typeof REMOVE_ATTACHMENT;
  payload: { submissionId: string; key: string };
}

export const removeAttachment: ActionCreator<IRemoveAttachment> = (submissionId: string, key: string) => ({
  type: REMOVE_ATTACHMENT,
  payload: { submissionId, key },
});

export const SET_APPROVED_ATTACHMENTS = 'SET_APPROVED_ATTACHMENTS';

interface ISetApprovedAttachments {
  type: typeof SET_APPROVED_ATTACHMENTS;
  payload: { submissionId: string; attachments: IFileInfo[] };
}

export const setApprovedAttachments: ActionCreator<ISetApprovedAttachments> = (
  submissionId: string,
  attachments: IFileInfo[],
) => ({
  type: SET_APPROVED_ATTACHMENTS,
  payload: { submissionId, attachments },
});

export const REMOVE_MISSING_ATTACHMENTS = 'REMOVE_MISSING_ATTACHMENTS';

interface IRemoveMissingAttachments {
  type: typeof REMOVE_MISSING_ATTACHMENTS;
  payload: { submissionId: string; keys: string[] };
}

export const removeMissingAttachments: ActionCreator<IRemoveMissingAttachments> = (
  submissionId: string,
  keys: string[],
) => ({
  type: REMOVE_MISSING_ATTACHMENTS,
  payload: { submissionId, keys },
});

export const SET_REMOVED_ATTACHMENT = 'SET_REMOVED_ATTACHMENT';

interface ISetRemoveAttachment {
  type: typeof SET_REMOVED_ATTACHMENT;
  payload: { isRemoveAttachmentModalOpen: boolean; key: string };
}

export const setRemovedAttachment: ActionCreator<ISetRemoveAttachment> = (
  isRemoveAttachmentModalOpen: boolean,
  key: string,
) => ({
  type: SET_REMOVED_ATTACHMENT,
  payload: { isRemoveAttachmentModalOpen, key },
});

export type IAttachmentActions =
  | ISetAttachments
  | IAddAttachment
  | IUpdateAttachment
  | IRemoveAttachment
  | ISetApprovedAttachments
  | IRemoveMissingAttachments
  | ISetRemoveAttachment;
