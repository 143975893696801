/* istanbul ignore file */

import { Dispatch } from 'redux';
import qs from 'qs';

import { axiosInstance } from './axiosInstance';
import {
  removeProjectMember,
  setAppointmentsInfo,
  setCurrentAccountProjectInfo,
  setOwnProjects,
  setProject,
  setProjects,
} from 'redux/projects/actions';
import {
  BUILDING_WORKS,
  DEVELOPMENT_TYPE,
  DeclarationFormItem,
  GATEWAY,
  IAppointmentRequests,
  IDismissStatus,
  IMemberRole,
  IMemberRolesAndResponsibilities,
  IProject,
  IProjectMember,
  IProjectMemberInvitation,
  LBV_PROCESSING_STATUS,
  RECEIVED_STATUS,
  ROLE,
} from 'utils/constants';
import type { IRoleField } from 'pages/Projects/ProjectMembers/interfaces';
import {
  IDeclaredResponsibility,
  IMemberRepresentCompanyField,
  ISelectedRole,
} from 'pages/Projects/ProjectMembers/interfaces';
import { ICoordinatorDetails } from 'pages/Projects/CreateProjectPage/ProjectDetails/interfaces';
import { IAppointmentRequest } from 'utils/interfaces/IAppointmentRequest';
import type { IMemberDetails } from '../pages/Projects/ProjectMembers/AddMember/MemberForm';
import { IRemovalRequest } from 'utils/interfaces/IRemovalRequest';
import { IFileInfo } from '../utils/interfaces/IFileInfo';
import { TOP_CSC_REQUIREMENTS_TYPE, TopCscRequirements } from '../pages/Projects/TopCscRequirementsPage/Common';
import { IReplacementDetails } from 'pages/Projects/ProjectMembers/FirmDetails/ReplacePersonnel/ReplaceFirmPersonnelPagePresentational';
import { queryClient } from './client';
import * as projectKeys from 'hooks/project/keys';

const invalidateProject = (projectId?: string) => {
  if (!projectId) return;
  return queryClient.invalidateQueries({ queryKey: projectKeys.item(projectId) });
};

export const invalidateProjectMembers = (projectId: string) => {
  if (!projectId) return;
  return queryClient.invalidateQueries({ queryKey: projectKeys.members(projectId) });
};

export type RawProject = Omit<IProject, 'createdDate'> & {
  createdDate?: string;
};

interface IProjectsInfoResponse {
  projects: RawProject[];
}

export const processProject = (project: RawProject): IProject => ({
  ...project,
  createdDate: project.createdDate ? new Date(project.createdDate) : undefined,
});

export const fetchProjects = async (dispatch: Dispatch): Promise<void> => {
  return axiosInstance
    .get<IProjectsInfoResponse>(`/project/`)
    .then(({ data }) => {
      dispatch(setProjects(data.projects.map(processProject)));
    })
    .catch(() => {
      dispatch(setProjects(null));
    });
};

export const fetchOwnProjects = async (dispatch: Dispatch): Promise<void> => {
  return axiosInstance
    .get<IProjectsInfoResponse>(`/project/own`)
    .then(({ data }) => {
      dispatch(setProjects(data.projects.map(processProject)));
      dispatch(setOwnProjects(data.projects.map(({ uuid }) => uuid)));
    })
    .catch(() => {
      dispatch(setProjects(null));
      dispatch(setOwnProjects(null));
    });
};

interface IProjectCreationResponse {
  project: IProject;
}

export const createProject = async (data: {
  title: string;
  buildingWorks: BUILDING_WORKS;
  developmentType: DEVELOPMENT_TYPE;
  isGFALess5000AndSingleUnit?: boolean;
  projectCoordinatorId?: string;
  projectCoordinatorDetails?: ICoordinatorDetails;
  addresses?: RecordStringAny;
}): Promise<IProject> => {
  return axiosInstance
    .post<IProjectCreationResponse>(`/project/create`, data)
    .then(({ data }) => data.project)
    .catch();
};

export const updateProjectAddresses = async (
  data: {
    addresses?: RecordStringAny;
  },
  projectId: string,
): Promise<IProject> => {
  return axiosInstance
    .patch<IProjectCreationResponse>(`/project/${projectId}/project-address`, data)
    .then(({ data }) => {
      invalidateProject(projectId);
      return data.project;
    })
    .catch();
};

interface ICurrentAccountProjectInfoResponse {
  accountInfo: {
    uuid: string;
    projectId: string;
    bookmarked: boolean;
    recentView?: string;
    emailAddress?: string;
    dismissStatus: {
      projectGuide: boolean | null;
      projectCoordinatorNotification: boolean | null;
    };
  }[];
}

export const fetchCurrentAccountProjectInfo = async (dispatch: Dispatch): Promise<void> => {
  return axiosInstance.get<ICurrentAccountProjectInfoResponse>(`/project/account-info`).then(({ data }) => {
    /* istanbul ignore next */
    data.accountInfo.forEach(({ projectId, ...info }) => {
      dispatch(
        setCurrentAccountProjectInfo(projectId, {
          ...info,
          recentView: info.recentView ? new Date(info.recentView) : undefined,
        }),
      );
    });
  });
};

interface IProjectInfoResponse {
  projectInfo: RawProject;
}

export const fetchProject = async (
  dispatch: Dispatch,
  projectId: string,
  withDeletedMembers = false,
): Promise<void> => {
  dispatch(setProject(projectId, null, true));
  const query = qs.stringify({
    withDeletedMembers,
  });
  await axiosInstance
    .get<IProjectInfoResponse>(`/project/${projectId}?${query}`, { timeout: 10000 })
    .then(({ data }) => {
      dispatch(setProject(projectId, processProject(data.projectInfo), false));
    });
};

export const viewProject = async (dispatch: Dispatch, project: IProject): Promise<void> => {
  const viewDateTime = Date.now();
  dispatch(setCurrentAccountProjectInfo(project.uuid, { recentView: new Date(viewDateTime) }));

  await axiosInstance.patch(`/project/${project.uuid}/view`, { view: viewDateTime }).catch();
};

export const bookmarkProject = async (dispatch: Dispatch, project: IProject): Promise<void> => {
  dispatch(setCurrentAccountProjectInfo(project.uuid, { bookmarked: true }));

  await axiosInstance.patch(`/project/${project.uuid}/bookmark`, { bookmark: true }).catch(() => {
    dispatch(setCurrentAccountProjectInfo(project.uuid, { bookmarked: false }));
  });
};

export const unbookmarkProject = async (dispatch: Dispatch, project: IProject): Promise<void> => {
  dispatch(setCurrentAccountProjectInfo(project.uuid, { bookmarked: false }));

  await axiosInstance.patch(`/project/${project.uuid}/bookmark`, { bookmark: false }).catch(() => {
    dispatch(setCurrentAccountProjectInfo(project.uuid, { bookmarked: true }));
  });
};

export const dismissProjectGuide = async (
  dispatch: Dispatch,
  project: IProject,
  dismissStatus?: IDismissStatus | null,
): Promise<void> => {
  dispatch(setCurrentAccountProjectInfo(project.uuid, { dismissStatus: { ...dismissStatus, projectGuide: true } }));

  await axiosInstance.patch(`/project/${project.uuid}/dismiss`, { projectGuide: true }).catch(() => {
    dispatch(setCurrentAccountProjectInfo(project.uuid, { dismissStatus: { ...dismissStatus, projectGuide: false } }));
  });
};

export const dismissProjectCoordinatorNotification = async (
  dispatch: Dispatch,
  project: IProject,
  dismissStatus?: IDismissStatus | null,
): Promise<void> => {
  dispatch(
    setCurrentAccountProjectInfo(project.uuid, {
      dismissStatus: { ...dismissStatus, projectCoordinatorNotification: true },
    }),
  );

  await axiosInstance.patch(`/project/${project.uuid}/dismiss`, { projectCoordinatorNotification: true }).catch(() => {
    dispatch(
      setCurrentAccountProjectInfo(project.uuid, {
        dismissStatus: { ...dismissStatus, projectCoordinatorNotification: false },
      }),
    );
  });
};

export interface IToUpdateProjectInfo {
  title: string;
  details?: {
    developmentType: DEVELOPMENT_TYPE;
  };
}

export const updateProjectInfo = async (
  dispatch: Dispatch,
  project: IProject,
  toUpd: IToUpdateProjectInfo,
): Promise<void> => {
  await axiosInstance.patch(`/project/${project.uuid}`, toUpd).then(() => {
    invalidateProject(project.uuid);
    dispatch(
      setProject(project.uuid, {
        ...project,
        title: toUpd.title,
        details: { ...project.details, ...toUpd.details },
      }),
    );
  });
};

interface IProjectDetailsResponse {
  projectDetails: IProject['details'];
}

export const addProjectMukimTownSubdivision = async (
  dispatch: Dispatch,
  project: IProject,
  mktsNo: string,
  lotNo: string,
  plotNo?: string,
  partialLot?: boolean,
): Promise<void> => {
  await axiosInstance
    .post<IProjectDetailsResponse>(`/project/${project.uuid}/mukim-town-subdivision`, {
      mktsNo,
      lotNo,
      plotNo,
      partialLot,
    })
    .then(({ data: { projectDetails } }) => {
      invalidateProject(project.uuid);
      dispatch(
        setProject(project.uuid, {
          ...project,
          details: {
            ...project.details,
            ...projectDetails,
          },
        }),
      );
    });
};

export const addProjectAddress = async (
  dispatch: Dispatch,
  project: IProject,
  postalCode: string,
  buildingName: string,
  roadName: string,
  houseNumber: string,
  levelNumber?: string,
  unitNumber?: string,
  others?: string,
): Promise<void> => {
  await axiosInstance
    .post<IProjectDetailsResponse>(`/project/${project.uuid}/formatted-address`, {
      postalCode,
      buildingName,
      roadName,
      houseNumber,
      levelNumber,
      unitNumber,
      others,
    })
    .then(({ data: { projectDetails } }) => {
      invalidateProject(project.uuid);
      dispatch(
        setProject(project.uuid, {
          ...project,
          details: {
            ...project.details,
            ...projectDetails,
          },
        }),
      );
    });
};

export const updateProjectGateway = async (dispatch: Dispatch, project: IProject, gateway: GATEWAY): Promise<void> => {
  await axiosInstance
    .patch<IProjectDetailsResponse>(`/project/${project.uuid}/gateway`, {
      gateway,
    })
    .then(({ data: { projectDetails } }) => {
      invalidateProject(project.uuid);
      dispatch(
        setProject(project.uuid, {
          ...project,
          details: {
            ...project.details,
            ...projectDetails,
          },
        }),
      );
    });
};

export const updateProjectSiteDescription = async (
  dispatch: Dispatch,
  project: IProject,
  siteDescription: {
    roadCodes?: string[];
    siteDescription?: string;
  },
): Promise<void> => {
  await axiosInstance
    .patch<IProjectDetailsResponse>(`/project/${project.uuid}/site-description`, {
      roadCodes: siteDescription.roadCodes,
      siteDescription: siteDescription.siteDescription,
    })
    .then(({ data: { projectDetails } }) => {
      invalidateProject(project.uuid);
      dispatch(
        setProject(project.uuid, {
          ...project,
          details: {
            ...project.details,
            ...projectDetails,
          },
        }),
      );
    });
};

export const updateProjectAddress = async (
  dispatch: Dispatch,
  project: IProject,
  addressId: string,
  postalCode: string,
  buildingName: string,
  roadName: string,
  houseNumber: string,
  levelNumber?: string,
  unitNumber?: string,
  others?: string,
): Promise<void> => {
  await axiosInstance
    .patch<IProjectDetailsResponse>(`/project/${project.uuid}/formatted-address/${addressId}`, {
      postalCode,
      buildingName,
      roadName,
      houseNumber,
      levelNumber,
      unitNumber,
      others,
    })
    .then(({ data: { projectDetails } }) => {
      invalidateProject(project.uuid);
      dispatch(
        setProject(project.uuid, {
          ...project,
          details: {
            ...project.details,
            ...projectDetails,
          },
        }),
      );
    });
};

export const updateProjectMukimTownSubdivision = async (
  dispatch: Dispatch,
  project: IProject,
  mktsId: string,
  mktsNo: string,
  lotNo: string,
  plotNo?: string,
  partialLot?: boolean,
): Promise<void> => {
  await axiosInstance
    .patch<IProjectDetailsResponse>(`/project/${project.uuid}/mukim-town-subdivision/${mktsId}`, {
      mktsNo,
      lotNo,
      plotNo,
      partialLot,
    })
    .then(({ data: { projectDetails } }) => {
      invalidateProject(project.uuid);
      dispatch(
        setProject(project.uuid, {
          ...project,
          details: {
            ...project.details,
            ...projectDetails,
          },
        }),
      );
    });
};

export const deleteProjectAddress = async (dispatch: Dispatch, project: IProject, addressId: string): Promise<void> => {
  await axiosInstance.delete(`/project/${project.uuid}/formatted-address/${addressId}`).then(() => {
    invalidateProject(project.uuid);
    const formattedAddresses = project.details?.formattedAddresses?.filter(({ uuid }) => uuid !== addressId) ?? [];
    dispatch(setProject(project.uuid, { ...project, details: { ...project.details, formattedAddresses } }));
  });
};

export const deleteProjectMukimTownSubdivision = async (
  dispatch: Dispatch,
  project: IProject,
  mktsId: string,
): Promise<void> => {
  await axiosInstance.delete(`/project/${project.uuid}/mukim-town-subdivision/${mktsId}`).then(() => {
    invalidateProject(project.uuid);
    const mukimTownSubdivisions = project.details?.mukimTownSubdivisions?.filter(({ uuid }) => uuid !== mktsId) ?? [];
    dispatch(setProject(project.uuid, { ...project, details: { ...project.details, mukimTownSubdivisions } }));
  });
};

export const authoriseProject = async (dispatch: Dispatch, project: IProject): Promise<void> => {
  await axiosInstance
    .post<{ project: RawProject }>(`/project/${project.uuid}/authorise`)
    .then(({ data: { project: updatedProject } }) => {
      invalidateProject(project.uuid);
      dispatch(setProject({ ...project, status: updatedProject.status }));
    });
};

export const createProjectMemberInvitation = async (
  projectId: string,
  details: {
    member: IMemberDetails;
    roles: IRoleField[];
    responsibilities: IDeclaredResponsibility[];
  },
): Promise<void> =>
  axiosInstance.post(`/project/${projectId}/member-invitation`, details).then(() => {
    invalidateProjectMembers(projectId);
  });

export const createSecondaryPersonnelInvitation = async (
  projectId: string,
  details: {
    member: IMemberDetails;
    roles: IRoleField[];
    responsibilities: IDeclaredResponsibility[];
  },
): Promise<void> =>
  axiosInstance.post(`/project/${projectId}/secondary-personnel-invitation`, details).then(() => {
    invalidateProjectMembers(projectId);
  });

export const confirmAdditionToProject = async (
  invitationId: string,
  emailAddress?: string,
  representCompany?: IMemberRepresentCompanyField,
): Promise<void> => axiosInstance.patch(`/project/invite/${invitationId}`, { emailAddress, representCompany });

export const updateProjectMemberInvitation = async (
  dispatch: Dispatch,
  project: IProject,
  memberInvitation: Partial<{
    uuid: string;
    maskedId: string;
    name: string;
    emailAddress: string;
    selectedRoles: ISelectedRole[];
    declaredResponsibilities: IDeclaredResponsibility[];
  }>,
): Promise<string | undefined> => {
  let newInviteUuid = memberInvitation.uuid;
  await axiosInstance
    .patch<{
      updatedMemberInvitation: IProjectMemberInvitation;
    }>(`/project/${project.uuid}/member-invitation/${memberInvitation.uuid}`, { ...memberInvitation, uuid: undefined })
    .then(({ data: { updatedMemberInvitation } }) => {
      invalidateProjectMembers(project.uuid);
      newInviteUuid = updatedMemberInvitation.uuid;
      dispatch(
        setProject({
          ...project,
          membersPending: project.membersPending?.map((m) =>
            m.uuid !== memberInvitation.uuid ? m : updatedMemberInvitation,
          ),
        }),
      );
    });
  return newInviteUuid;
};

export const updateFirmPersonnelDetails = async (
  projectId: string,
  memberId: string,
  firmPersonnelDetails: RecordStringAny[],
  personnelsToRemove: IProjectMemberInvitation[],
): Promise<string[]> => {
  return axiosInstance
    .post(`project/${projectId}/firm-personnel-details/${memberId}`, { firmPersonnelDetails, personnelsToRemove })
    .then(({ data }) => {
      invalidateProjectMembers(projectId);
      return data.newFirmPersonnelsInvitation;
    });
};

export const replaceFirmPersonnels = async (
  projectId: string,
  personnelsToReplace: Record<string, IReplacementDetails>,
  personnelRoleToReplace?: ROLE,
) => {
  return axiosInstance
    .post(`project/${projectId}/replace-firm-personnels`, { personnelsToReplace, personnelRoleToReplace })
    .then(({ data }) => {
      invalidateProjectMembers(projectId);
      return data.replacements;
    });
};

export const createMemberAppointmentRequest = async (
  projectId: string,
  appointerId: string,
  roleRecords: { [agencyCode: string]: string[] },
): Promise<IProject> => {
  return axiosInstance
    .post<IProjectCreationResponse>(`/project/${projectId}/member-appointment-request`, {
      roleRecords,
      appointerId,
    })
    .then(({ data }) => data.project);
};

export const fetchAppointmentRequest = async (
  projectId: string,
  requestId: string,
): Promise<{
  appointmentRequest: IAppointmentRequest;
  appointedRoles: IAppointmentRequest['roles'];
  userCanAppoint?: boolean;
}> => {
  return axiosInstance
    .get<{
      appointmentRequest: IAppointmentRequest;
      appointedRoles: IAppointmentRequest['roles'];
    }>(`/project/${projectId}/member-appointment-request/${requestId}`)
    .then(({ data }) => data);
};

export const appointMembers = async (
  projectId: string,
  requestId: string,
  declarations?: DeclarationFormItem[],
): Promise<void> => {
  return axiosInstance.post(`/project/${projectId}/appoint-members/${requestId}`, { declarations });
};

export const getMemberInfo = async (projectId: string, memberIds: string[]): Promise<{ data: IProjectMember[] }> => {
  return axiosInstance.post(`/project/${projectId}/getMemberInfo/`, { memberIds });
};

export const updateMemberRolesAndResponsibilities = async (
  projectId: string,
  memberDetails: IMemberRolesAndResponsibilities,
) => {
  await axiosInstance.patch(`/project/${projectId}/project-member-role/${memberDetails.uuid}`, {
    ...memberDetails,
    uuid: undefined,
  });
  await invalidateProjectMembers(projectId);
};

export const updateMemberProjectEmail = async (
  projectId: string,
  memberDetails: { uuid: string; emailAddress?: string },
) => {
  await axiosInstance.patch(`/project/${projectId}/project-member-details/${memberDetails.uuid}`, {
    emailAddress: memberDetails.emailAddress,
  });
};

export const getProjectMembers = async (
  projectId: string,
  roles?: ROLE[],
  responsibilities?: string[],
  requiresAppointment?: boolean,
): Promise<{ data: IProjectMember[] }> => {
  const queryParams = qs.stringify({
    roles: roles,
    responsibilities: responsibilities,
    requiresAppointment: requiresAppointment,
  });
  return axiosInstance.get(`/project/${projectId}/project-members${queryParams && `?${queryParams}`}`);
};

export const removeUnacknowledgeMember = async (dispatch: Dispatch, projectId: string, memberInvitationId: string) => {
  await axiosInstance.delete(`/project/${projectId}/remove-unacknowledge-member/${memberInvitationId}`).then(() => {
    dispatch(removeProjectMember(projectId, memberInvitationId));
  });
  await invalidateProjectMembers(projectId);
};

export const removeMemberFromProject = async (
  projectId: string,
  member: IProjectMember,
  removalRequest?: IRemovalRequest,
) => {
  await axiosInstance.post(`/project/${projectId}/remove-project-member/${member.uuid}`, { removalRequest });
  await invalidateProjectMembers(projectId);
};
interface IFetchAppointmentsResponse {
  appointmentRequests: IAppointmentRequests[];
}

export const fetchAppointments = async (dispatch: Dispatch, projectId: string): Promise<IFetchAppointmentsResponse> => {
  return axiosInstance
    .get<IFetchAppointmentsResponse>(`/project/${projectId}/appointments`, { timeout: 20000 })
    .then(({ data }) => {
      const { appointmentRequests } = data;
      dispatch(setAppointmentsInfo(projectId, appointmentRequests));
      return { appointmentRequests };
    });
};

export const fetchRemovalRequest = async (
  projectId: string,
  removalRequestId: string,
): Promise<{
  removalRequest: IRemovalRequest;
  appointedRoles: IMemberRole[];
}> => {
  return axiosInstance.get(`/project/${projectId}/member-removal-request/${removalRequestId}`).then(({ data }) => data);
};

export const notifyAppointmentRemovalEmail = async (projectId: string, member: IProjectMember) => {
  await axiosInstance.post(`/project/${projectId}/notify-appointment-removal/${member.uuid}`);
};

export const fetchMemberDetails = async (projectId: string, projectMemberUuid: string): Promise<IProjectMember> => {
  const projectMemberDetails = await axiosInstance
    .get(`/project/${projectId}/getMemberDetails/${projectMemberUuid}`)
    .then(({ data }) => data.projectMemberDetails);

  return projectMemberDetails;
};

export const getApprovedSubmissionAttachmentsByProject = async ({
  projectId,
  projectGateway,
}: {
  projectId: string;
  projectGateway: string;
}): Promise<IFileInfo[]> => {
  return axiosInstance
    .get<{
      attachments: {
        fileInfo: { name: string; size: number; key: string; uploadedAt: Date; received: RECEIVED_STATUS };
        sectionKey: string;
        tags: string[];
        agencyNames: string[];
        type?: string;
        discipline?: string;
        isPlan: boolean;
        lbvModels?: {
          uuid: string;
          processingStatus: LBV_PROCESSING_STATUS;
          expiryDate: string | null;
          lbvRequest: {
            requestDate: string;
          };
        }[];
      }[];
    }>(`/project/${projectId}/attachment/approved?projectGateway=${projectGateway}`)
    .then(({ data: { attachments } }) => {
      return attachments.map((attachment) => ({
        ...attachment,
        fileInfo: undefined,
        name: attachment.fileInfo.name,
        size: attachment.fileInfo.size,
        key: attachment.fileInfo.key,
        uploadedAt: new Date(attachment.fileInfo.uploadedAt),
        receivedStatus: attachment.fileInfo.received,
        lbvModels: attachment.lbvModels?.map((model) => ({
          ...model,
          expiryDate: model.expiryDate === null ? null : new Date(model.expiryDate),
          lbvRequest: {
            requestDate: new Date(model.lbvRequest.requestDate),
          },
        })),
      }));
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchTopCscRequirements = async (
  projectId: string,
  relevantTypes: TOP_CSC_REQUIREMENTS_TYPE[],
): Promise<TopCscRequirements> =>
  await axiosInstance
    .get(`/project/${projectId}/topcsc-requirements?type=${relevantTypes.join(',')}`)
    .then(({ data }) => data.requirements);

export const fetchProjectMemberInvitation = async (
  inviteId: string,
): Promise<{
  project: IProject;
  invitation: IProjectMemberInvitation;
  member: IProjectMember;
  roles: IMemberRole[];
}> => await axiosInstance.get(`/project/invite/${inviteId}`).then(({ data }) => data);

interface IIsProjectCoordinatorResponse {
  isProjectCoordinator: boolean;
}

export const fetchIsProjectCoordinator = async (projectId: string): Promise<boolean> => {
  return axiosInstance
    .get<IIsProjectCoordinatorResponse>(`/project/${projectId}/is-project-coordinator`)
    .then(({ data }) => data.isProjectCoordinator);
};
