import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { formatLongMonthWithYear } from 'utils/date';

interface IHeader {
  date: Date;
  decreaseMonth?: () => void;
  increaseMonth?: () => void;
}

export const Header: React.FC<IHeader> = ({ date, decreaseMonth, increaseMonth }) => (
  <div className="flex w-full items-center justify-between py-4 px-5 sub-heading">
    {decreaseMonth ? (
      <button
        className="p-2 flex-shrink-0 text-gray-50 focus:outline-none focus:bg-gray-10 rounded-md"
        onClick={decreaseMonth}
        type="button"
      >
        <ChevronLeftIcon className="text-gray-50 w-5 h-5" />
      </button>
    ) : (
      <div className="w-5" />
    )}
    <div>{formatLongMonthWithYear(date)}</div>
    {increaseMonth ? (
      <button
        className="p-2 flex-shrink-0 text-gray-50 focus:outline-none focus:bg-gray-10 rounded-md"
        onClick={increaseMonth}
        type="button"
      >
        <ChevronRightIcon className="w-5 h-5" />
      </button>
    ) : (
      <div className="w-5" />
    )}
  </div>
);
