import React from 'react';
import { Row } from './Row';
import { StartAlignedContainer } from './StartAlignedContainer';
import { EndAlignedContainer } from './EndAlignedContainer';
import { Link } from './Link';
import { ProductName } from './ProductName';
import { Text } from './Text';
import { SecondaryText } from './SecondaryText';
import { Divider } from './Divider';
import { GDSSection } from './GDSSection';
import { LinksWrapper } from './LinksWrapper';
import { PrimarySection, SecondarySection } from './Sections';
import { RouteContext, IRouteContext } from './RouteContext';
import { ColorContext } from './ColorContext';

export type IFooter = { colorMode?: 'light' | 'dark' } & IRouteContext & React.HTMLAttributes<HTMLElement>;

const FooterComponent: React.FC<IFooter & IRouteContext> = ({ children, className, redirect, colorMode = 'dark' }) => {
  const color = {
    light: 'bg-gray-10',
    dark: 'bg-gray-80',
  }[colorMode];

  return (
    <ColorContext.Provider value={colorMode}>
      <RouteContext.Provider value={{ redirect }}>
        <footer className={`${color} w-full ${className}`}>{children}</footer>
      </RouteContext.Provider>
    </ColorContext.Provider>
  );
};

export const Footer = Object.assign(FooterComponent, {
  Row,
  StartAlignedContainer,
  EndAlignedContainer,
  Link,
  ProductName,
  Text,
  SecondaryText,
  Divider,
  GDSSection,
  LinksWrapper,
  PrimarySection,
  SecondarySection,
});
