import React from 'react';
import { ICheckboxOptions } from '../Checkbox/BaseCheckbox';

export enum radioType {
  DEFAULT = 'default',
  BOXED = 'boxed',
}

interface IRadio<T> extends ICheckboxOptions {
  item: T;
  selectedItem: T | null;
  setSelectedItem: (newItem: T | null) => void;
  additionalContent?: React.ReactNode;
  type?: radioType;
}

export const Radio = <T,>({
  item,
  selectedItem,
  setSelectedItem,
  additionalContent,
  type = radioType.DEFAULT,
  label,
  disabled = false,
  isErrored = false,
  errorMessage,
  className,
  ...otherProps
}: IRadio<T> & React.HTMLAttributes<HTMLInputElement>): JSX.Element => {
  return (
    <label
      className={`flex space-x-3 items-center ${disabled ? 'cursor-default' : 'cursor-pointer'} ${type === radioType.BOXED && `bg-white border border-gray-30 rounded-lg p-5 ${errorMessage !== undefined ? 'outline outline-error outline-1' : selectedItem === item ? 'outline outline-primary-60 outline-2' : ''}`}`}
      onClick={type === radioType.BOXED ? () => setSelectedItem(item) : undefined}
    >
      <div
        className={`flex items-center rounded-full ring-offset-2  ${type === radioType.BOXED ? 'focus-within:outline-none' : 'focus-within:ring-2 focus-within:ring-primary-60'}`}
      >
        <input
          type="radio"
          checked={selectedItem === item}
          disabled={disabled}
          onChange={() => setSelectedItem(item)}
          className={`appearance-none rounded-full relative leading-0 border w-5 h-5 flex-shrink-0 ${
            isErrored || errorMessage !== undefined
              ? 'bg-white border-error hover:bg-gray-10'
              : disabled
                ? 'bg-gray-10 border-gray-30 checked:bg-gray-70 checked:border-gray-50 checked:ring-4'
                : 'ring-4 bg-white border-gray-30 hover:border-gray-80 checked:bg-primary-60 checked:border-primary-60'
          } ring-white ring-inset cursor-pointer disabled:cursor-not-allowed focus:outline-none content-checked-after checked:after:absolute checked:after:transform checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:top-1/2 checked:after:left-1/2 ${className}`}
          {...otherProps}
        />
      </div>
      {label && (
        <div className={`${selectedItem !== item && disabled ? 'text-gray-30' : 'text-gray-100'} label-large`}>
          {label}
        </div>
      )}
      {additionalContent}
    </label>
  );
};
