import {
  IAuthActions,
  SET_ACCOUNT,
  SET_IS_SESSION_ENDING,
  SET_IS_SESSION_EXPIRED,
  SET_IS_LOGIN_MODAL_OPEN,
  SET_IS_CONCURRENT_SESSION_DETECTED,
} from './actions';
import { Reducer } from 'redux';
import { IAccount } from 'utils/constants';

export interface IReducer {
  account: IAccount | null;
  isSessionEnding: boolean;
  isSessionExpired: boolean | null;
  isConcurrentSessionDetected: boolean;
  isLoginModalOpen: boolean;
  loginType: string;
}

export const initialState: IReducer = {
  account: null,
  isSessionEnding: false,
  isSessionExpired: null,
  isConcurrentSessionDetected: false,
  isLoginModalOpen: false,
  loginType: '',
};

export const reducer: Reducer<IReducer, IAuthActions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT: {
      const { account } = action.payload;
      return {
        ...state,
        account,
      };
    }
    case SET_IS_LOGIN_MODAL_OPEN: {
      const { isOpen } = action.payload;
      return {
        ...state,
        isLoginModalOpen: isOpen,
      };
    }
    case SET_IS_SESSION_ENDING: {
      const { ending } = action.payload;

      if (ending === state.isSessionEnding) return state;

      return {
        ...state,
        isSessionEnding: ending,
      };
    }
    case SET_IS_SESSION_EXPIRED: {
      const { expired } = action.payload;
      return {
        ...state,
        isSessionExpired: expired,
      };
    }
    case SET_IS_CONCURRENT_SESSION_DETECTED: {
      const { concurrentSessionDetected } = action.payload;
      return {
        ...state,
        isConcurrentSessionDetected: concurrentSessionDetected,
      };
    }
    default:
      return state;
  }
};
