import { ActionCreator } from 'redux';
import { INotification } from 'utils/constants';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

interface ISetNotifications {
  type: typeof SET_NOTIFICATIONS;
  payload: { notifications: INotification[] };
}

export const setNotifications: ActionCreator<ISetNotifications> = (notifications: INotification[]) => ({
  type: SET_NOTIFICATIONS,
  payload: { notifications },
});

export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';

interface IAddNotifications {
  type: typeof ADD_NOTIFICATIONS;
  payload: { notifications: INotification[] };
}

export const addNotifications: ActionCreator<IAddNotifications> = (notifications: INotification[]) => ({
  type: ADD_NOTIFICATIONS,
  payload: { notifications },
});

export const SET_NOTIFICATION = 'SET_NOTIFICATION';

interface ISetNotification {
  type: typeof SET_NOTIFICATION;
  payload: { notification: INotification };
}

export const setNotification: ActionCreator<ISetNotification> = (notification: INotification) => ({
  type: SET_NOTIFICATION,
  payload: { notification },
});

export const SET_PAGINATION = 'SET_PAGINATION';

export interface IPagination {
  isLoading: boolean;
  nextPage: number | null;
}

interface ISetPagination {
  type: typeof SET_PAGINATION;
  payload: { pagination: Partial<IPagination> };
}

export const setPagination: ActionCreator<ISetPagination> = (pagination: IPagination) => ({
  type: SET_PAGINATION,
  payload: { pagination },
});

export const SET_HAS_UNREAD = 'SET_HAS_UNREAD';

interface ISetHasUnread {
  type: typeof SET_HAS_UNREAD;
  payload: { hasUnread: boolean };
}

export const setHasUnread: ActionCreator<ISetHasUnread> = (hasUnread: boolean) => ({
  type: SET_HAS_UNREAD,
  payload: { hasUnread },
});

export const SET_IS_ERRORED = 'SET_IS_ERRORED';

interface ISetIsErrored {
  type: typeof SET_IS_ERRORED;
  payload: { isErrored: boolean };
}

export const setIsErrored: ActionCreator<ISetIsErrored> = (isErrored: boolean) => ({
  type: SET_IS_ERRORED,
  payload: { isErrored },
});

export type INotificationActions =
  | ISetNotifications
  | ISetNotification
  | IAddNotifications
  | ISetPagination
  | ISetHasUnread
  | ISetIsErrored;
