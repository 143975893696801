import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  tbody:last-child {
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: inherit;
      }

      td:last-child {
        border-bottom-right-radius: inherit;
      }
    }
  }
`;

export const DataTable: React.FC<React.HTMLAttributes<HTMLTableElement>> = ({ children, className, ...otherProps }) => {
  return (
    <StyledTable
      className={`border-separate pt-5 [&>tbody:last-of-type>tr:last-child>td:last-child]:text-pb-5 bg-white rounded-lg w-full label-large text-left sm:border-collapse sm:pt-0 ${className}`}
      {...otherProps}
    >
      {children}
    </StyledTable>
  );
};
