import { ActionCreator } from 'redux';
import { IAccount } from 'utils/constants';

export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_IS_SESSION_ENDING = 'SET_IS_SESSION_ENDING';
export const SET_IS_SESSION_EXPIRED = 'SET_IS_SESSION_EXPIRED';
export const SET_IS_CONCURRENT_SESSION_DETECTED = 'SET_IS_CONCURRENT_SESSION_DETECTED';
export const SET_IS_LOGIN_MODAL_OPEN = 'SET_IS_LOGIN_MODAL_OPEN';

interface ISetAccount {
  type: typeof SET_ACCOUNT;
  payload: { account: IAccount | null };
}

export const setAccount: ActionCreator<ISetAccount> = (account: IAccount) => ({
  type: SET_ACCOUNT,
  payload: { account },
});
interface ISetIsLoginModalOpen {
  type: typeof SET_IS_LOGIN_MODAL_OPEN;
  payload: { isOpen: boolean };
}

export const setIsLoginModalOpen: ActionCreator<ISetIsLoginModalOpen> = (isOpen: boolean) => ({
  type: SET_IS_LOGIN_MODAL_OPEN,
  payload: { isOpen },
});

interface ISetIsSessionEnding {
  type: typeof SET_IS_SESSION_ENDING;
  payload: { ending: boolean };
}

export const setIsSessionEnding: ActionCreator<ISetIsSessionEnding> = (ending: boolean) => ({
  type: SET_IS_SESSION_ENDING,
  payload: { ending },
});

interface ISetIsSessionExpired {
  type: typeof SET_IS_SESSION_EXPIRED;
  payload: { expired: boolean };
}

export const setIsSessionExpired: ActionCreator<ISetIsSessionExpired> = (expired: boolean) => ({
  type: SET_IS_SESSION_EXPIRED,
  payload: { expired },
});

interface ISetIsConcurrentSessionDetected {
  type: typeof SET_IS_CONCURRENT_SESSION_DETECTED;
  payload: { concurrentSessionDetected: boolean };
}

export const setIsConcurrentSessionDetected: ActionCreator<ISetIsConcurrentSessionDetected> = (
  concurrentSessionDetected: boolean,
) => ({
  type: SET_IS_CONCURRENT_SESSION_DETECTED,
  payload: { concurrentSessionDetected },
});

export type IAuthActions =
  | ISetAccount
  | ISetIsLoginModalOpen
  | ISetIsSessionEnding
  | ISetIsSessionExpired
  | ISetIsConcurrentSessionDetected;
