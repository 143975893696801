import React, { useContext } from 'react';
import { DropdownMenuOpenState } from './shared';
import { ChevronUpIcon } from '@heroicons/react/outline';

export const ToggleArrow: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  const isOpen = useContext(DropdownMenuOpenState);

  return (
    <div className={`transition-all transform ${isOpen ? 'rotate-0' : '-rotate-180'} ${className}`}>
      <ChevronUpIcon className="flex-shrink-0 w-6 h-6" />
    </div>
  );
};
