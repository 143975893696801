import { useEffect, useState } from 'react';
import { IOption } from './shared';

export const useWithFilter = (options: IOption[], select: (key: string) => void) => {
  const [filter, setFilter] = useState('');
  const [focusedOption, setFocusedOption] = useState<number | null>(null);
  const filteredOptions = options.filter(
    ({ displayName }) => !filter || displayName.toLowerCase().includes(filter.toLowerCase()),
  );
  const enabledFilteredOptions = filteredOptions.filter(({ disabled }) => !disabled);
  const keyToFocus = focusedOption !== null && enabledFilteredOptions[focusedOption]?.key;

  const handleClick = (key: string) => {
    setFilter('');
    select(key);
    setFocusedOption(enabledFilteredOptions.findIndex((option) => option.key === key));
  };

  const handleSelect = () => {
    if (!keyToFocus) return;
    select(keyToFocus);
    setFilter('');
  };

  const increaseFocusedOption = () => {
    setFocusedOption((focusedOption) => Math.min((focusedOption ?? -1) + 1, enabledFilteredOptions.length - 1));
  };

  const decreaseFocusedOption = () => {
    setFocusedOption((focusedOption) => Math.max((focusedOption ?? enabledFilteredOptions.length) - 1, 0));
  };

  useEffect(() => {
    setFocusedOption(null);
  }, [filter]);

  return {
    filter,
    setFilter,
    keyToFocus,
    filteredOptions,
    handleClick,
    handleSelect,
    increaseFocusedOption,
    decreaseFocusedOption,
  };
};
