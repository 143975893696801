import React, { useState, useEffect } from 'react';

export const ToastWrapper: React.FC<{ visible: boolean }> = ({ visible, children }) => {
  const [v, setV] = useState(false);

  useEffect(() => {
    setV(visible);
  }, [visible]);

  return (
    <div
      className={`relative transition duration-300 transform ${
        v ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
      }`}
    >
      {children}
    </div>
  );
};
