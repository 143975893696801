/* istanbul ignore file */

import { Dispatch } from 'redux';
import { setAccount } from 'redux/auth/actions';
import { IAccount } from 'utils/constants';
import { axiosInstance } from './axiosInstance';

export const getMyinfoAuthorization = async () => {
  const {
    data: { url },
  } = await axiosInstance.get(`/account/url`);
  window.location.assign(url);
};

export const retrieveMyinfo = async (code: string, state: string | null): Promise<IAccount> => {
  const { data } = await axiosInstance.post<{ account: IAccount }>(`/account/myinfo`, { code, state });
  return data.account;
};

export const updateAccount = async (dispatch: Dispatch, account: IAccount): Promise<void> => {
  await axiosInstance.put(`/account/update`, { account }).then(() => {
    dispatch(setAccount(account));
  });
};
