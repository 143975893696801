import React from 'react';
import { ITagMatcher } from './ITagMatcher';

type ItalicMatchType = { contents: string };

export const ItalicUnderscoreTagMatcher: ITagMatcher<ItalicMatchType> = {
  matcher: /([^\_]|^)([\_](?!\_)(.*?[^\_])[\_])(?!\_)/g,
  getMatchData: (results) => ({
    transformationParams: { contents: results[3] },
    startIndex: results.index + results[1].length,
    length: results[2].length,
    // This is the length of _ at the start of the match
    matchOffset: 1,
  }),
  transformer: ({ key, params, parseContent }) => <i key={key}>{parseContent(params.contents)}</i>,
};
