import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

enum Position {
  Bottom = 'bottom',
  Top = 'top',
}

type PositionStyles = Record<Position, string>;

type UseTopBottomRepositionProps = {
  isOpen: boolean;
  // In pixels
  bottomPadding?: number;
  // In pixels
  dropdownHeight?: number;
  positionStyles?: PositionStyles;
};

const _bottomPadding = 32;

/**
 * Based on calendar component in Figma
 * https://www.figma.com/file/NjZAa8i6NgdUvg8ODiW0OO/Component-Library-(PDK)?type=design&node-id=9704-66313&mode=dev
 */
const _dropdownHeight = 348;

const _positionStyles: PositionStyles = {
  [Position.Bottom]: 'top-full',
  [Position.Top]: 'bottom-full',
};

const useTopBottomReposition = <RefElement extends HTMLElement>({
  isOpen,
  bottomPadding = _bottomPadding,
  dropdownHeight = _dropdownHeight,
  positionStyles = _positionStyles,
}: UseTopBottomRepositionProps): [React.RefObject<RefElement>, PositionStyles[Position], Position] => {
  const [position, setPosition] = useState<Position>(Position.Bottom);
  const ref = useRef<RefElement>(null);

  const updateElementPosition = useCallback(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();

      if (dropdownHeight + bottomPadding + rect.top >= window.innerHeight) {
        setPosition(Position.Top);
      } else {
        setPosition(Position.Bottom);
      }
    }
  }, [isOpen, bottomPadding, dropdownHeight, setPosition, ref.current?.getBoundingClientRect().top]);

  useLayoutEffect(() => {
    updateElementPosition();
  }, [updateElementPosition]);

  useLayoutEffect(() => {
    window.addEventListener('scroll', updateElementPosition);
    return () => {
      window.removeEventListener('scroll', updateElementPosition);
    };
  }, [updateElementPosition]);

  return [ref, positionStyles[position], position];
};

export default useTopBottomReposition;
