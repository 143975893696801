/* istanbul ignore file */
import { IPersonDetails } from 'pages/Projects/ProjectMembers/PersonDetailsForm';
import { axiosInstance } from './axiosInstance';
import { REGISTRAR_TYPE } from 'utils/constants';

interface ILicenceResponse {
  licence: {
    name: string;
  };
  company?: {
    name: string;
  };
}

export interface IACO {
  regNumber: string;
  name: string;
}

export const getLicence = (registrar: REGISTRAR_TYPE, regNumber: string): Promise<ILicenceResponse> => {
  return axiosInstance.get<ILicenceResponse>(`/licence`, { params: { registrar, regNumber } }).then(({ data }) => data);
};

export const isRegNumberInRecords = async (role: string, regNumber: string) => {
  const { data } = await axiosInstance.get<boolean>(`/licence/check-reg-number-in-records`, {
    params: { role, reg_number: regNumber },
  });
  return data;
};

export const doesRegNumberMatchIdentity = async (role: string, regNumber: string, maskedId: string) => {
  const { data } = await axiosInstance.get<boolean>(`/licence/match-reg-number-to-identity`, {
    params: { role, reg_number: regNumber, masked_id: maskedId },
  });
  return data;
};

export const doesIdentityMatchUENRole = async (role: string, uenNumber: string, personnelDetail: IPersonDetails) => {
  const { data } = await axiosInstance.post<boolean>(`/licence/check-identity-uen-role`, {
    personnelDetail,
    role,
    uenNumber,
  });
  return data;
};

export const getACOs = async (accountId: string) => {
  const { data } = await axiosInstance.get<{ ACOs: IACO[] }>(`/licence/accredited-checking-organisations/${accountId}`);
  return data.ACOs;
};
