import { ActionCreator } from 'redux';
import { IRole } from 'utils/constants';

export const SET_ROLES = 'SET_ROLES';

interface ISetRoles {
  type: typeof SET_ROLES;
  payload: { roles: IRole[] };
}

export const setRoles: ActionCreator<ISetRoles> = (roles: IRole[]) => ({
  type: SET_ROLES,
  payload: { roles },
});

export type IRolesResponsibilitiesActions = ISetRoles;
