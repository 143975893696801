import React from 'react';
import { MarkDown } from '../../markdownParser/MarkDown';
import { BoldAsteriskTagMatcher } from '../../markdownParser/TagMatchers/BoldAsteriskTagMatcher';
import { BoldUnderscoreTagMatcher } from '../../markdownParser/TagMatchers/BoldUnderscoreTagMatcher';
import { ItalicAsteriskTagMatcher } from '../../markdownParser/TagMatchers/ItalicAsteriskTagMatcher';
import { ItalicUnderscoreTagMatcher } from '../../markdownParser/TagMatchers/ItalicUnderscoreTagMatcher';
import { LinkTagMatcher } from '../../markdownParser/TagMatchers/LinkTagMatcher';
import { ITagMatcher } from '../../markdownParser/TagMatchers/ITagMatcher';

const tagMatchers = [
  BoldAsteriskTagMatcher,
  BoldUnderscoreTagMatcher,
  ItalicAsteriskTagMatcher,
  ItalicUnderscoreTagMatcher,
  LinkTagMatcher,
] as ITagMatcher<unknown>[];

interface IMessage {
  message: string;
}

export const Message: React.FC<IMessage> = ({ message }) => <MarkDown tagMatchers={tagMatchers} message={message} />;
