import classNames from 'classnames';
import React from 'react';
import { CheckCircleOutline, DotsCircleHorizontal } from '../../icons';

export type CompletionState = 'completed' | 'in-progress' | 'not-started' | 'not-applicable';

interface IDatum {
  heading: string;
  description?: React.ReactNode;
  additionalContent?: React.ReactNode;
  completionState: CompletionState;
}

export interface IMilestone {
  data: IDatum[];
}

export const Milestone: React.FC<IMilestone> = ({ data }) => {
  return (
    <div className="flex flex-col space-y-0.5">
      {data.map(({ heading, description, additionalContent, completionState }, i) => (
        <div
          className={classNames(
            {
              'text-gray-40': ['not-started', 'not-applicable'].includes(completionState),
            },
            'flex space-x-3',
          )}
          key={i}
        >
          <div className="flex flex-col space-y-0.5 items-center">
            {completionState === 'completed' && (
              <CheckCircleOutline className="text-primary-60 w-6 h-6 flex-shrink-0" />
            )}
            {completionState === 'in-progress' && (
              <DotsCircleHorizontal className="text-primary-60 w-6 h-6 flex-shrink-0" />
            )}
            {['not-started', 'not-applicable'].includes(completionState) && (
              <CheckCircleOutline className="w-6 h-6 flex-shrink-0" />
            )}

            {i !== data.length - 1 && <div className="w-px h-full bg-gray-30/60" />}
          </div>
          <div className="flex flex-col gap-y-5 pb-4">
            <div className="flex flex-col gap-y-1">
              <div
                className={`heading-6-semibold ${
                  completionState === 'not-applicable' ? 'text-gray-40' : 'text-gray-90'
                }`}
              >
                {heading}
              </div>
              {completionState === 'in-progress' && description && (
                <div className="body-small text-gray-70">{description}</div>
              )}
            </div>
            {additionalContent}
          </div>
        </div>
      ))}
    </div>
  );
};
