import {
  ISubmissionActions,
  SET_SUBMISSION,
  ADD_SUBMISSIONS,
  SET_SUBMISSION_SECTION_LOCKS,
  REMOVE_SUBMISSION,
  SET_SUBMISSION_SERVER_SIDE_VALIDATION_ERRORS,
} from './actions';
import { Reducer } from 'redux';
import { ISectionLock, ISubmission } from 'utils/constants';
import { isEqual } from 'lodash';

export interface IReducer {
  submissions?: Record<string, ISubmission>;
  sectionLocks?: Record<string, ISectionLock[]>;
  submissionsServerSideValidations?: RecordStringAny;
}

export const initialState: IReducer = {};

export const reducer: Reducer<IReducer, ISubmissionActions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBMISSION: {
      const { id, submission } = action.payload;

      if (isEqual(submission, state.submissions?.[id])) return state;

      return {
        ...state,
        submissions: { ...state.submissions, [id]: submission },
      };
    }
    case ADD_SUBMISSIONS: {
      const { submissions } = action.payload;
      if (
        submissions.length > 0 &&
        submissions.every((submission) => isEqual(submission, state.submissions?.[submission.uuid]))
      ) {
        return state;
      }

      //to remove submissions that have already been deleted based on the submissions returned by the api
      const currentState = { ...state.submissions };

      const submissionIdsToDelete = Object.keys(currentState).map((currentSubmissionId) => {
        if (!submissions.map((submission) => submission.uuid).includes(currentSubmissionId)) {
          return currentSubmissionId;
        }
      });

      submissionIdsToDelete.map((ids) => {
        ids && delete currentState[ids];
      });

      return {
        ...state,
        submissions: {
          ...currentState,
          ...submissions.reduce((acc, curr) => ({ ...acc, [curr.uuid]: curr }), {}),
        },
      };
    }

    case SET_SUBMISSION_SECTION_LOCKS: {
      const { id: submissionId, sectionLocks } = action.payload;
      const currentSubmissionSectionLocks = state.sectionLocks?.[submissionId];

      if (isEqual(sectionLocks, currentSubmissionSectionLocks)) return state;

      return {
        ...state,
        sectionLocks: { ...state.sectionLocks, [submissionId]: sectionLocks },
      };
    }

    case REMOVE_SUBMISSION: {
      const { submissionId } = action.payload;
      const newSubmissions = { ...state.submissions };

      if (state.submissions?.[submissionId]) {
        delete newSubmissions[submissionId];
      }

      return {
        ...state,
        submissions: newSubmissions,
      };
    }

    case SET_SUBMISSION_SERVER_SIDE_VALIDATION_ERRORS: {
      const { submissionId, errors } = action.payload;
      return {
        ...state,
        submissionsServerSideValidations: {
          ...state.submissionsServerSideValidations,
          [submissionId]: errors,
        },
      };
    }

    default:
      return state;
  }
};
