import React, { useContext } from 'react';
import { OpenStateContext } from './shared';
import { RouteContext } from '../shared';
import { Tooltip } from './Tooltip';
import styled from 'styled-components';

const MaskingDiv = styled.div`
  clip-path: inset(0 0 0 0);
`;

export interface PrimaryLinkTabProps {
  icon: React.ReactNode;
  label: string;
  path?: string;
  exact?: boolean;
  onClick?: () => void;
}

export const PrimaryLinkTab: React.FC<PrimaryLinkTabProps> = ({ icon, label, path, exact = true, onClick }) => {
  const { redirect, isSelected } = useContext(RouteContext);
  const isOpen = useContext(OpenStateContext);
  const selected = path && isSelected({ path, exact });

  return (
    <div className="group relative">
      <div className={`${isOpen ? '' : 'group-hover:block'} hidden`}>
        <Tooltip>{label}</Tooltip>
      </div>
      <a
        className={`flex w-full label-large sm:rounded-lg hover:bg-primary-70 ${
          selected ? 'text-white bg-primary-90' : 'bg-primary-80 text-primary-30'
        }`}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          onClick && onClick();
          path && redirect(path);
        }}
      >
        <button className={`focus:outline-none ${isOpen ? 'w-46' : 'sm:w-14'}`}>
          <div className={`sm:relative ${isOpen ? 'w-46' : 'sm:w-14'} flex flex-row items-center`}>
            <div className="stroke-current text-secondary-50 stroke-1 h-14 w-14 p-4">{icon}</div>
            <MaskingDiv>
              <p
                className={`text-left sm:transform ${
                  isOpen
                    ? 'w-32 translate-x-0 opacity-100 visible'
                    : 'sm:w-0 sm:h-14 sm:invisible sm:opacity-0 sm:-translate-x-full'
                } transition duration-300`}
              >
                {label}
              </p>
            </MaskingDiv>
          </div>
        </button>
      </a>
    </div>
  );
};
