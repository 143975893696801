type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

type ParamOptions = string | URLSearchParams | Record<string, unknown>;

export interface ApiOptions {
  /** HTTP method (default: `GET`) */
  method?: HttpMethods | Lowercase<HttpMethods>;

  /** request headers */
  headers?: HeadersInit;

  /** request body */
  body?: ParamOptions | FormData;

  /** request query string */
  params?: ParamOptions;

  /** signal for aborting request */
  signal?: AbortSignal;
}

const baseURL = `/services`;

const getBody = (body?: ParamOptions | FormData) => {
  if (!body) return {};
  if (typeof body === 'string' || body instanceof FormData || body instanceof URLSearchParams) {
    return { body };
  }
  return { body: JSON.stringify(body), contentType: 'application/json' };
};

export const parseOptions = (path: string, opts?: ApiOptions) => {
  const method = opts?.method?.toUpperCase();
  const { body, contentType } = getBody(opts?.body);

  /**
   * type assertion here as URLSearchParams only accepts
   * `Record<string, string>` but it will work regardless
   *
   * @see https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1568
   */
  const qs = `${new URLSearchParams(opts?.params as string)}`;

  return {
    url: `${path[0] === '/' ? `${baseURL}${path}` : path}${qs ? `?${qs}` : ''}`,
    method: method ?? (body ? 'POST' : 'GET'),
    ...(body ? { body } : null),
    ...(opts?.signal ? { signal: opts.signal } : null),
    headers: {
      ...(contentType ? { 'content-type': contentType } : null),
      ...opts?.headers,
    } as HeadersInit,
  };
};

/* istanbul ignore next */
export class FetchError extends Error {
  status = 500;
  timestamp = new Date();

  constructor(status: number, obj: Record<string, unknown>) {
    super(`${obj.message ?? 'Fetch error'}`);
    this.status = status;
    if (obj.timestamp) {
      this.timestamp = new Date(`${obj.timestamp}`);
    }
  }
}

/* istanbul ignore next */
export const api = async <T = unknown>(path: string, options?: ApiOptions) => {
  const { url, ...opts } = parseOptions(path, options);
  // nosemgrep: nodejs_scan.javascript-ssrf-rule-node_ssrf
  const resp = await fetch(url, opts);

  if (!resp.ok) {
    const message = await resp.text();
    let errObj = null;
    try {
      errObj = JSON.parse(message);
    } catch (err) {
      // fallback to message
    }
    if (errObj) throw new FetchError(errObj.status || resp.status, errObj);
    throw new Error(message || resp.statusText);
  }

  return (await resp.json()) as T;
};

export const shouldRetry = (error: unknown) => {
  const status = (error as FetchError)?.status;
  return status < 400 || status >= 500;
};
