import { useQuery } from '@tanstack/react-query';
import { api } from '../apis/fetch';
import { defaultFeatureFlags } from './FeatureFlags';
import type { FeatureFlags } from './FeatureFlags';

const cacheTime = 6 * 60 * 1000;

/* istanbul ignore next */
export const useFeatureFlags = () => {
  const { data } = useQuery({
    cacheTime,
    queryKey: ['flags'],
    queryFn: async ({ signal }) => {
      const flags = await api<FeatureFlags>('/feature-flags', { signal });
      return { ...flags, hasLoaded: true };
    },
    placeholderData: defaultFeatureFlags,
  });

  return data ?? defaultFeatureFlags;
};
