import React from 'react';
import { OverlayTab } from './OverlayTab';
import { ExpandableTab } from './ExpandableTab';

export interface DropdownMenuTabProps {
  label: React.ReactNode;
}

export const DropdownMenuTab: React.FC<DropdownMenuTabProps> = ({ label, children, ...otherProps }) => (
  <div className="group" {...otherProps}>
    <div tabIndex={0} className="hidden sm:flex items-center h-full focus:outline-none focus:ring-2 ring-primary-60">
      <OverlayTab label={label}>{children}</OverlayTab>
    </div>
    <div className="sm:hidden">
      <ExpandableTab label={label}>{children}</ExpandableTab>
    </div>
  </div>
);
