import { ActionCreator } from 'redux';

export const CLEAR_STORE = 'CLEAR_STORE';

interface IClearStore {
  type: typeof CLEAR_STORE;
  payload: Record<string, never>;
}

export const clearStore: ActionCreator<IClearStore> = () => ({
  type: CLEAR_STORE,
  payload: {},
});

export type ICrossSliceActions = IClearStore;
