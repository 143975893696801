/* istanbul ignore file */

import { axiosInstance } from './axiosInstance';
import { Dispatch } from 'redux';
import {
  setNotification,
  addNotifications,
  setPagination,
  setHasUnread,
  setIsErrored,
} from 'redux/notifications/actions';
import { INotification } from 'utils/constants';
import qs from 'qs';

type RawNotification = INotification & {
  metadata?: string;
};

const processNotification = (rawNotification: RawNotification): INotification => ({
  ...rawNotification,
  createdDate: new Date(rawNotification.createdDate),
});

interface INotificationsResponse {
  notifications: RawNotification[];
}

export const fetchNotifications = async (
  dispatch: Dispatch,
  options: { page?: number; perPage?: number; earlierThan?: Date } = {},
): Promise<void> => {
  dispatch(setPagination({ isLoading: true }));

  const { page = 0, perPage = 10, earlierThan } = options;

  const query = qs.stringify({
    page: page.toString(),
    per_page: perPage.toString(),
    earlier_than: earlierThan?.toISOString(),
  });

  return axiosInstance
    .get<INotificationsResponse>(`/notification?${query}`)
    .then(({ data }) => {
      const nextPage = data.notifications.length === 0 ? null : page + 1;
      dispatch(setPagination({ nextPage }));
      dispatch(addNotifications(data.notifications.map(processNotification)));
    })
    .catch(() => {
      dispatch(setIsErrored(true));
    })
    .finally(() => {
      dispatch(setPagination({ isLoading: false }));
    });
};

export const fetchNewNotifications = async (
  dispatch: Dispatch,
  latestNotification?: INotification,
  options: { skipRefresh?: boolean } = {},
): Promise<void> => {
  const { uuid, createdDate } = latestNotification || {};
  const { skipRefresh } = options;
  const headers = skipRefresh ? { 'cx-skip-refresh': 'true' } : undefined;

  const query = qs.stringify({
    latest_notification_uuid: uuid,
    latest_notification_created_date: createdDate,
  });

  return axiosInstance.get<INotificationsResponse>(`/notification/new?${query}`, { headers }).then(({ data }) => {
    dispatch(addNotifications(data.notifications.map(processNotification)));
  });
};

interface IHasUnreadResponse {
  hasUnread: boolean;
}

export const fetchHasUnread = async (dispatch: Dispatch): Promise<void> => {
  return axiosInstance
    .get<IHasUnreadResponse>('/notification/has-unread')
    .then(({ data }) => {
      dispatch(setHasUnread(data.hasUnread));
    })
    .catch();
};

interface INotificationResponse {
  notification: RawNotification;
}

export const markNotificationAsRead = async (dispatch: Dispatch, notificationId: string): Promise<void> => {
  return axiosInstance
    .patch<INotificationResponse>(`/notification/read/${notificationId}`)
    .then(({ data }) => {
      dispatch(setNotification(processNotification(data.notification)));
    })
    .catch();
};

export const markAllNotificationsAsRead = async (dispatch: Dispatch): Promise<void> => {
  return axiosInstance
    .patch<INotificationsResponse>('/notification/read')
    .then(({ data }) => {
      data.notifications.forEach((notification) => {
        dispatch(setNotification(processNotification(notification)));
      });
    })
    .catch();
};
