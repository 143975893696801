import React from 'react';

export const DropdownMenuOpenState = React.createContext(true);

export interface InnerDropdownMenuProps {
  controlNode?: React.ReactElement;
  renderControlNode?: (renderProps: { toggleMenu: () => void; isOpen?: boolean }) => React.ReactElement;
  className?: string;
  disabled?: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;

  /* Menu will close on key change */
  closeOnChangeKey?: string;
}
