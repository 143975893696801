import { IAgencyResponseActions, SET_RESPONSES } from './actions';
import { Reducer } from 'redux';
import { isEqual } from 'lodash';
import { IAgencyCorrespondence } from 'utils/constants';

export interface IReducer {
  responses: Record<string, IAgencyCorrespondence[]>;
}

export const initialState: IReducer = {
  responses: {},
};

export const reducer: Reducer<IReducer, IAgencyResponseActions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESPONSES: {
      const { submissionId, responses } = action.payload;

      if (isEqual(responses, state.responses[submissionId])) return state;

      return {
        ...state,
        responses: { ...state.responses, [submissionId]: responses },
      };
    }

    default:
      return state;
  }
};
