export function isExternalLink(props: ExternalLinkProps | InternalLinkProps): props is ExternalLinkProps {
  return (props as ExternalLinkProps).external;
}

export interface ExternalLinkProps {
  external: true;
  href: string;
  rel?: string;
  target?: string;
}

export interface InternalLinkProps {
  external?: false;
  path: string;
}

export interface BaseLinkProps {
  textSize?: 'small' | 'regular';
}

export type LinkProps = ((ExternalLinkProps & React.HTMLAttributes<HTMLAnchorElement>) | InternalLinkProps) &
  BaseLinkProps;
