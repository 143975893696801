import { ActionCreator } from 'redux';

export const SET_CODE_TABLE = 'SET_CODE_TABLE';

interface ISetCodeTable {
  type: typeof SET_CODE_TABLE;
  payload: { tableName: string; data: { code: string; description: string }[] };
}

export const setCodeTable: ActionCreator<ISetCodeTable> = (
  tableName: string,
  data: { code: string; description: string }[],
) => ({
  type: SET_CODE_TABLE,
  payload: { tableName, data },
});

export type ICodeTableActions = ISetCodeTable;
