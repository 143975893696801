import React from 'react';
import classNames from 'classnames';

export type Variant = 'informational' | 'error' | 'success' | 'warning' | 'neutral';
type Emphasis = 'low' | 'high';

export interface ITag {
  icon?: React.ReactNode;
  text: string;
  variant?: Variant;
  emphasis?: Emphasis;
  textCapitalisation?: string;
}

export type TagProps = ITag & React.HTMLAttributes<HTMLDivElement>;

const colorStyles = {
  low: {
    informational: 'bg-primary-10 text-informational',
    error: 'bg-error-light text-error',
    success: 'bg-success-light text-success',
    warning: 'bg-warning-light text-gray-80',
    neutral: 'bg-gray-20 text-gray-80',
  },
  high: {
    informational: 'bg-informational text-white',
    error: 'bg-error text-white',
    success: 'bg-success text-white',
    warning: 'bg-warning text-gray-80',
    neutral: 'bg-gray-90 text-white',
  },
};

export const Tag: React.FC<TagProps> = ({
  icon,
  text,
  variant = 'informational',
  emphasis = 'low',
  textCapitalisation,
  ...otherProps
}) => {
  const colors = colorStyles[emphasis][variant];
  return (
    <div
      className={classNames('flex items-center px-3 py-1 rounded-xl captions w-max', colors, textCapitalisation)}
      {...otherProps}
    >
      {icon && <div className="flex-shrink-0 w-4 h-4 mr-1">{icon}</div>}
      {text}
    </div>
  );
};
