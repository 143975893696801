import React from 'react';

interface IDropdownMenuButton {
  label: React.ReactNode;
}

export const DropdownMenuButton: React.FC<IDropdownMenuButton & React.HTMLAttributes<HTMLButtonElement>> = ({
  label,
  ...otherProps
}) => (
  <button
    type="button"
    className="focus:outline-none w-full text-left px-5 py-3 hover:bg-gray-10 active:bg-primary-10 active:text-primary-70 focus:ring-2 focus:ring-primary-60 ring-inset label-large text-gray-80"
    {...otherProps}
  >
    {label}
  </button>
);
