import { ICodeTableActions, SET_CODE_TABLE } from './actions';
import { Reducer } from 'redux';

export interface IReducer {
  codeTables: Record<string, { code: string; description: string }[]>;
}

export const initialState: IReducer = {
  codeTables: {},
};

export const reducer: Reducer<IReducer, ICodeTableActions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_CODE_TABLE: {
      const { tableName, data } = action.payload;
      return {
        ...state,
        codeTables: {
          ...state.codeTables,
          [tableName]: data,
        },
      };
    }

    default:
      return state;
  }
};
