import React, { useContext } from 'react';
import { SecondaryText } from './SecondaryText';
import GDSLogoWhite from '../../../assets/gds-logo-white.svg';
import GDSLogoBlack from '../../../assets/gds-logo-black.svg';
import { ColorContext } from './ColorContext';

export const GDSSection: React.FC = () => {
  const colorMode = useContext(ColorContext);
  const imageSrc = {
    light: GDSLogoBlack,
    dark: GDSLogoWhite,
  }[colorMode];

  return (
    <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6">
      <img className="w-24" src={imageSrc} alt="GDS-Logo" />
      <SecondaryText>
        Developed by Government Digital Services, a division of Government Technology Agency Singapore
      </SecondaryText>
    </div>
  );
};
