import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Exclamation } from 'ui';
import { useTranslation } from 'react-i18next';

interface INotFoundPage {
  isLoggedIn: boolean;
}

export const NotFoundPage: React.FC<INotFoundPage> = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex flew-grow justify-center items-center w-full" data-cy="page-not-found-window">
      <div className="flex flex-col items-center space-y-3">
        <div className="flex flex-col items-center space-y-3">
          <span className="text-warning">
            <Exclamation className="w-24 h-24" />
          </span>
          <div className="flex flex-col items-center space-y-1">
            <span className="text-gray-80 text-center heading-4-bold">{t('errors:notFoundPage.header')}</span>
            <span className="text-center text-gray-60 body-regular">{t('errors:notFoundPage.subtext')}</span>
          </div>
        </div>
        <Button
          label={isLoggedIn ? t('errors:notFoundPage.button.loggedIn') : t('errors:notFoundPage.button.notLoggedIn')}
          variant="primary"
          onClick={() => navigate('/')}
        />
      </div>
    </div>
  );
};

export default NotFoundPage;
