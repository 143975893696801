import React, { useContext } from 'react';
import { DropdownMenuOpenState } from '../DropdownMenu';

export const InnerContent: React.FC = ({ children, ...otherProps }) => {
  const isOpen = useContext(DropdownMenuOpenState);

  const shadowStyles = isOpen ? 'shadow-md' : 'shadow-none';

  return (
    <div className={`sm:hidden left-0 top-full w-full transition-all duration-300 ${shadowStyles}`} {...otherProps}>
      {children}
    </div>
  );
};
