/* istanbul ignore file */
import Axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { AxiosCacheInstance, buildWebStorage, setupCache } from 'axios-cache-interceptor';

class MemoryStorage implements Storage {
  private storage: Map<string, string>;

  constructor() {
    this.storage = new Map();
  }

  get length(): number {
    return this.storage.size;
  }

  setItem(key: string, value: string): void {
    this.storage.set(String(key), String(value));
  }

  getItem(key: string): string | null {
    return this.storage.get(String(key)) || null;
  }

  removeItem(key: string): void {
    if (key.includes('*')) {
      this.removeWithWildcardPattern(key);
    } else {
      this.storage.delete(key);
    }
  }

  private removeWithWildcardPattern(key: string): void {
    // nosemgrep: eslint.detect-non-literal-regexp
    const regexPattern = new RegExp(`^${key.replace(/\*/g, '.*')}$`);
    for (const existingKey of this.storage.keys()) {
      if (regexPattern.test(existingKey)) {
        this.storage.delete(existingKey);
      }
    }
  }

  clear(): void {
    this.storage.clear();
  }

  key(index: number): string | null {
    const keys = Array.from(this.storage.keys());
    return keys[index] || null;
  }
}

class AxiosInstanceBuilder {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private axiosInstance: any;

  constructor(url: string) {
    this.axiosInstance = Axios.create({
      baseURL: url,
    });
  }

  setTimeout(timeout: number) {
    this.axiosInstance.defaults.timeout = timeout;
    return this;
  }

  setRetries(retries: number) {
    axiosRetry(this.axiosInstance, {
      retries: retries,
    });
    return this;
  }

  setCache(ttl: number, storage?: Storage) {
    this.axiosInstance = setupCache(this.axiosInstance, {
      storage: storage && buildWebStorage(storage),
      ttl: ttl,
    });
    return this;
  }

  build() {
    return this.axiosInstance;
  }
}

const BASE_URL = '/services';
const TIMEOUT_IN_MILLISECONDS = 1000 * 10;
const RETRIES = 3;
const CACHE_TTL_IN_MILLISECONDS = 1000 * 30;
const memoryStorage = new MemoryStorage();

const axiosInstance: AxiosInstance = new AxiosInstanceBuilder(BASE_URL)
  .setTimeout(TIMEOUT_IN_MILLISECONDS)
  .setRetries(RETRIES)
  .build();

const axiosInstanceCache: AxiosCacheInstance = new AxiosInstanceBuilder(BASE_URL)
  .setTimeout(TIMEOUT_IN_MILLISECONDS)
  .setRetries(RETRIES)
  .setCache(CACHE_TTL_IN_MILLISECONDS, memoryStorage)
  .build();

export { axiosInstance, axiosInstanceCache };
