import React, { useContext } from 'react';
import { ColorContext } from './ColorContext';

export const ProductName: React.FC = ({ children }) => {
  const colorMode = useContext(ColorContext);
  const color = {
    light: 'text-gray-100',
    dark: 'text-white',
  }[colorMode];

  return <div className={`${color} heading-3-bold`}>{children}</div>;
};
