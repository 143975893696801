import { Reducer, combineReducers } from 'redux';

import { ICrossSliceActions, CLEAR_STORE } from './crossSliceActions';
import { IAuthActions } from './auth/actions';
import { INotificationActions } from './notifications/actions';
import { IPaymentActions } from './payments/actions';
import { IProjectActions } from './projects/actions';
import { ISubmissionActions } from './submissions/actions';
import { IBroadcastMessagesActions } from './broadcastMessages/actions';
import { ICodeTableActions } from './codeTables/actions';
import { IAssistantActions } from './assistants/actions';
import { IRolesResponsibilitiesActions } from './rolesResponsibilities/actions';
import { IAttachmentActions } from './attachments/actions';
import { IAgencyResponseActions } from './responses/actions';
import { IStripeActions } from './stripe/actions';

import { reducer as authReducer, IReducer as IAuthReducer, initialState as authInitialState } from './auth/reducer';
import {
  reducer as projectsReducer,
  IReducer as IProjectsReducer,
  initialState as projectsInitialState,
} from './projects/reducer';
import {
  reducer as submissionsReducer,
  IReducer as ISubmissionsReducer,
  initialState as submissionsInitialState,
} from './submissions/reducer';
import {
  reducer as paymentsReducer,
  IReducer as IPaymentsReducer,
  initialState as paymentsInitialState,
} from './payments/reducer';
import {
  reducer as notificationsReducer,
  IReducer as INotificationsReducer,
  initialState as notificationsInitialState,
} from './notifications/reducer';
import {
  reducer as broadcastMessagesReducer,
  IReducer as IBroadcastMessagesReducer,
  initialState as broadcastMessagesInitialState,
} from './broadcastMessages/reducer';
import {
  reducer as codeTablesReducer,
  IReducer as ICodeTablesReducer,
  initialState as codeTablesInitialState,
} from './codeTables/reducer';
import {
  reducer as assistantsReducer,
  IReducer as IAssistantsReducer,
  initialState as assistantsInitialState,
} from './assistants/reducer';
import {
  reducer as rolesReducer,
  IReducer as IRolesReducer,
  initialState as rolesInitialState,
} from './rolesResponsibilities/reducer';
import {
  reducer as attachmentsReducer,
  IReducer as IAttachmentsReducer,
  initialState as attachmentsInitialState,
} from './attachments/reducer';
import {
  reducer as responsesReducer,
  IReducer as IResponsesReducer,
  initialState as responsesInitialState,
} from './responses/reducer';
import {
  reducer as stripeReducer,
  IReducer as IStripeReducer,
  initialState as stripeInitialState,
} from './stripe/reducer';
import {
  reducer as loadingOverlayReducer,
  IReducer as ILoadingOverlayReducer,
  initialState as loadingOverlayInitialState,
} from './loadingOverlay/reducer';

export const rootReducerInitialState = {
  auth: authInitialState,
  projects: projectsInitialState,
  submissions: submissionsInitialState,
  payments: paymentsInitialState,
  notifications: notificationsInitialState,
  broadcastMessages: broadcastMessagesInitialState,
  codeTables: codeTablesInitialState,
  assistants: assistantsInitialState,
  roles: rolesInitialState,
  attachments: attachmentsInitialState,
  responses: responsesInitialState,
  stripe: stripeInitialState,
  loadingOverlay: loadingOverlayInitialState,
};

export interface IStore {
  auth: IAuthReducer;
  projects: IProjectsReducer;
  submissions: ISubmissionsReducer;
  payments: IPaymentsReducer;
  notifications: INotificationsReducer;
  broadcastMessages: IBroadcastMessagesReducer;
  codeTables: ICodeTablesReducer;
  assistants: IAssistantsReducer;
  roles: IRolesReducer;
  attachments: IAttachmentsReducer;
  responses: IResponsesReducer;
  stripe: IStripeReducer;
  loadingOverlay: ILoadingOverlayReducer;
}

export const reducers = {
  auth: authReducer,
  projects: projectsReducer,
  submissions: submissionsReducer,
  payments: paymentsReducer,
  notifications: notificationsReducer,
  broadcastMessages: broadcastMessagesReducer,
  codeTables: codeTablesReducer,
  assistants: assistantsReducer,
  roles: rolesReducer,
  attachments: attachmentsReducer,
  responses: responsesReducer,
  stripe: stripeReducer,
  loadingOverlay: loadingOverlayReducer,
};

export const appReducer = combineReducers(reducers);

type IAllActions =
  | ICrossSliceActions
  | IAuthActions
  | INotificationActions
  | IPaymentActions
  | IProjectActions
  | ISubmissionActions
  | IBroadcastMessagesActions
  | ICodeTableActions
  | IAssistantActions
  | IRolesResponsibilitiesActions
  | IAttachmentActions
  | IAgencyResponseActions
  | IStripeActions;

export const rootReducer: Reducer<IStore, IAllActions> = (state, action) => {
  switch (action.type) {
    case CLEAR_STORE:
      return {
        ...rootReducerInitialState,
        auth: {
          ...rootReducerInitialState.auth,
          account: null,
        },
      };

    default:
      return appReducer(state, action);
  }
};
