import classNames from 'classnames';
import React, { useContext } from 'react';
import { LinkProps } from 'react-router-dom';
import { InternalLinkContext } from './InternalLinkContext';
import { ExternalLinkOutline } from '../../icons';

type Variant = 'standalone' | 'inline' | 'danger' | 'unstyled';
type Size = 'large' | 'regular' | 'small';
type Position = 'start' | 'end';

interface ITextLink {
  variant?: Variant;
  size?: Size;
  icon?: React.ReactNode;
  iconPosition?: Position;
  disabled?: boolean;
}

type IButtonLink = ITextLink & React.ButtonHTMLAttributes<HTMLButtonElement>;
type IInternalLink = ITextLink & LinkProps;

// Any links that navigate user to an external url should open the window in a
// new tab and be indicated as such with the ExternalLinkOutline icon. In such
// cases, any other icons should not be and are unlikely to be used together
// with the ExternalLinkOutline icon.
type IExternalLink = Omit<ITextLink, 'icon'> & React.AnchorHTMLAttributes<HTMLAnchorElement> & { icon?: never };

export type TextLinkProps = IInternalLink | IExternalLink | IButtonLink;

const sizeStyles = {
  text: {
    large: 'link-large',
    regular: 'link-regular',
    small: 'link-small',
  },
  icon: {
    large: 'w-6 h-6',
    regular: 'w-5 h-5',
    small: 'w-4 h-4',
  },
};

export const TextLink: React.FC<TextLinkProps> = (componentProps) => {
  const {
    variant = 'standalone',
    size = 'regular',
    iconPosition = 'end',
    disabled = false,
    children,
    className,
  } = componentProps;
  const { Link } = useContext(InternalLinkContext);
  const iconSize = sizeStyles['icon'][size];
  const { icon } = componentProps;

  if (isExternalLink(componentProps)) {
    return (
      <a
        tabIndex={disabled ? -1 : 0}
        {...componentProps}
        className={getStyles(variant, size, className, disabled, iconPosition)}
        rel="noreferrer noopener"
        target="_blank"
      >
        <div>{children}</div>
        <div
          className={classNames(
            'flex-shrink-0',
            { 'mr-1': iconPosition === 'start' },
            { 'ml-1': iconPosition === 'end' },
            iconSize,
          )}
        >
          {<ExternalLinkOutline />}
        </div>
      </a>
    );
  }

  if (isInternalLink(componentProps)) {
    return (
      <Link
        tabIndex={disabled ? -1 : 0}
        {...componentProps}
        className={getStyles(variant, size, className, disabled, iconPosition)}
      >
        <div>{children}</div>
        {icon && (
          <div
            className={classNames(
              'flex-shrink-0',
              { 'mr-1': iconPosition === 'start' },
              { 'ml-1': iconPosition === 'end' },
              iconSize,
            )}
          >
            {icon}
          </div>
        )}
      </Link>
    );
  }

  return (
    <button
      {...componentProps}
      type="button"
      disabled={disabled}
      className={getStyles(variant, size, className, disabled, iconPosition)}
    >
      <div>{children}</div>
      {icon && (
        <div
          className={classNames(
            'flex-shrink-0',
            { 'mr-1': iconPosition === 'start' },
            { 'ml-1': iconPosition === 'end' },
            iconSize,
          )}
        >
          {icon}
        </div>
      )}
    </button>
  );
};

function isInternalLink(componentProps: IButtonLink | IInternalLink | IExternalLink): componentProps is IInternalLink {
  return (componentProps as IInternalLink).to !== undefined;
}

function isExternalLink(componentProps: IButtonLink | IInternalLink | IExternalLink): componentProps is IExternalLink {
  return (componentProps as IExternalLink).href !== undefined;
}

const getStyles = (variant: Variant, size: Size, className?: string, disabled?: boolean, iconPosition?: string) => {
  const textSize = sizeStyles['text'][size];

  return classNames(
    'flex w-full text-center focus:outline-none ring-inset focus:ring-2 focus:ring-primary-60 items-center',
    textSize,
    { 'flex-row-reverse': iconPosition === 'start' },
    {
      'pointer-events-none cursor-not-allowed text-gray-30': disabled,
      'hover:text-primary-50 active:text-primary-70 text-primary-60 py-1': variant === 'standalone',
      'underline hover:text-primary-50 active:text-primary-70 text-primary-60': variant === 'inline',
      'text-error hover:text-error-dark active:text-error-dark': variant === 'danger',
      [className ?? '']: className,
    },
  );
};
