import { IStripeActions, SET_PAYABLE_AGENCY_ABBREVIATION } from './actions';
import { Reducer } from 'redux';

export interface IReducer {
  payableAgencyAbbreviation: string;
}

export const initialState: IReducer = {
  payableAgencyAbbreviation: '',
};

export const reducer: Reducer<IReducer, IStripeActions> = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYABLE_AGENCY_ABBREVIATION: {
      return {
        ...state,
        payableAgencyAbbreviation: action.payload.payableAgencyAbbreviation,
      };
    }

    default:
      return state;
  }
};
