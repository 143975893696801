import React from 'react';
import { useTranslation } from 'react-i18next';
import { GenericModal } from './GenericModal';
import { ERROR_CODE, getNumericalErrorCode } from '../../pages/ErrorBoundary';
import { supportLink } from 'utils/externalLinks';

interface IGenericModalWithErrorCode {
  isOpen: boolean;
  modalHeader: string;
  modalDescription?: string;
  toggleOpenOrClose: (isOpen: boolean) => void;
  errorCode: keyof typeof ERROR_CODE;
}

export const GenericModalWithErrorCode: React.FC<IGenericModalWithErrorCode> = ({
  isOpen,
  modalHeader,
  modalDescription,
  toggleOpenOrClose,
  errorCode,
}) => {
  const { t } = useTranslation();
  const openExternalLink = () => window.open(supportLink, '_blank', 'noreferrer');
  return (
    <GenericModal
      isOpen={isOpen}
      close={() => toggleOpenOrClose(false)}
      proceed={openExternalLink}
      modalHeader={modalHeader}
      modalDescription={`${modalDescription}\n(Error code: ${getNumericalErrorCode(errorCode)})`}
      closeButtonLabel="Back"
      closeButtonVariant="tertiary"
      proceedButtonVariant="primary"
      proceedButtonLabel={t('genericModalWithErrorCode:default.buttonLabel')}
      textWrap={false}
      dismissable={false}
    />
  );
};
