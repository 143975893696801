import React from 'react';
import classNames from 'classnames';

import type { AccordionPanelProps } from './interfaces';

const AccordionPanel: React.FC<AccordionPanelProps> = (props) => {
  const { isExpanded, children } = props;
  return (
    <div
      data-testid="accordion-panel"
      className={classNames(
        // Flexbox and Layout
        'flex',
        'flex-col',
        'items-start',
        'w-full',

        // Padding
        'p-0',
        'px-5',
        'pb-5',

        // Conditional
        {
          block: isExpanded,
          hidden: !isExpanded,
        },
      )}
    >
      {children}
    </div>
  );
};

AccordionPanel.displayName = 'AccordionPanel';

export default AccordionPanel;
