import React, { useState, useEffect, useCallback } from 'react';
import { CalendarIcon } from '@heroicons/react/outline';
import { IDateRange } from './types';
import moment from 'moment';
import { Input } from '../Input/Input';
import classNames from 'classnames';

interface IDatePickerInput {
  value?: string;
  openCalendar: () => void;
  toggleCalendar: () => void;
  placeholderText?: string;
  setDate: (date: Date | null) => void;
  limit: IDateRange;
  validDates?: Date[];
  label?: string;
  helperText?: string;
  errorMessage?: string;
  valid?: boolean;
  forceErrorShown?: boolean;
}

export const DatePickerInput: React.FC<IDatePickerInput> = ({
  value,
  openCalendar,
  toggleCalendar,
  placeholderText,
  setDate,
  limit,
  validDates,
  label,
  helperText,
  errorMessage,
  valid,
  forceErrorShown,
}) => {
  const [candidateValue, setCandidateValue] = useState(value);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    value ? setCandidateValue(value) : setCandidateValue('');
  }, [value]);

  const tryUpdateValue = useCallback(() => {
    if (candidateValue === '') {
      setDate(null);
    }

    const candidateMoment = moment(candidateValue, 'DD/MM/YYYY', true);

    if (candidateMoment.isSame(moment(value, 'DD/MM/YYYY'))) {
      return;
    }

    if (
      candidateMoment.isValid() &&
      candidateMoment.isAfter(moment('1000-1-1', 'yyyy-mm-dd')) &&
      candidateMoment.isBefore(moment('9999-1-1', 'yyyy-mm-dd')) &&
      (!limit.startDate || candidateMoment.isAfter(moment(limit.startDate))) &&
      (!limit.endDate || candidateMoment.isBefore(moment(limit.endDate))) &&
      (!validDates ||
        validDates.some(
          (validDate) =>
            candidateMoment.dayOfYear() === moment(validDate).dayOfYear() &&
            candidateMoment.year() === moment(validDate).year(),
        ))
    ) {
      setDate(candidateMoment.toDate());
    } else {
      setCandidateValue(value);
    }
  }, [value, candidateValue, setDate, limit]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        tryUpdateValue();
      }
    },
    [tryUpdateValue],
  );

  const handleBlur = useCallback(() => {
    setIsDirty(true);
    tryUpdateValue();
  }, [setIsDirty, tryUpdateValue]);

  const showError = (forceErrorShown || isDirty) && !valid && valid !== undefined;

  return (
    <Input
      className={classNames(
        'cursor-pointer w-full flex items-center space-x-2 rounded-lg bg-white border ring-0 group-focus-within:ring-1 ring-primary-60 group-focus-within:border-primary-60 py-3 pl-4 pr-3',
        {
          'border-error': showError,
          'border-gray-30 hover:border-gray-80': !showError,
        },
      )}
      label={label}
      helperText={helperText}
      errorMessage={errorMessage}
      valid={valid}
      showValidityIcon={false}
      forceErrorShown={forceErrorShown}
      value={candidateValue}
      onInputChange={(value: string) => {
        setCandidateValue(value);
      }}
      onFocus={() => {
        openCalendar();
      }}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      placeholder={placeholderText}
      unpaddedSuffix={
        <button tabIndex={-1} onClick={toggleCalendar} className="focus:outline-none" type="button">
          <CalendarIcon
            onClick={toggleCalendar}
            className="flex-shrink-0 w-5 h-5 text-gray-50 group-focus-within:text-primary-60"
          />
        </button>
      }
    />
  );
};
